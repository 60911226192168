import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Helpers from '../../Helpers'
import axios from 'axios';
import $ from 'jquery';
import InputRange from 'react-input-range';
import DealHelper from '../helpers/DealHelper';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TextResourceHelper from '../helpers/TextResourceHelper';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import parse from 'html-react-parser'
import SessionConfig from '../session/SessionConfig';
import SessionHelper from '../helpers/SessionHelper';
import LineItemStatusHelper from '../helpers/LineItemStatusHelper';

export class LineItemAdsDirect extends Component {
    static displayName = LineItemAdsDirect.name;

    constructor(props) {
        super(props);

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.setEditView = this.setEditView.bind(this);
        this.setReadOnlyView = this.setReadOnlyView.bind(this);
        this.saveLineItem = this.saveLineItem.bind(this);
        this.validateLineItem = this.validateLineItem.bind(this);
        this.validateLineItemAdops = this.validateLineItemAdops.bind(this);
        this.setLineItemImplemented = this.setLineItemImplemented.bind(this);
        this.extendLineItem = this.extendLineItem.bind(this);
        this.billLineItemTotal = this.billLineItemTotal.bind(this);
        this.billLineItemDelivered = this.billLineItemDelivered.bind(this);
        this.duplicateLineItem = this.duplicateLineItem.bind(this);
        this.onChangeLineItemLabel = this.onChangeLineItemLabel.bind(this);
        this.onChangeLineItemModeloDeCompra = this.onChangeLineItemModeloDeCompra.bind(this);
        this.onChangeLineItemSellRate = this.onChangeLineItemSellRate.bind(this);
        this.onChangeLineItemBonificacion = this.onChangeLineItemBonificacion.bind(this);
        this.onChangeLineItemInversion = this.onChangeLineItemInversion.bind(this);
        this.onChangeLineItemFacturacionMensual = this.onChangeLineItemFacturacionMensual.bind(this);
        this.onChangeLineItemFechaInicio = this.onChangeLineItemFechaInicio.bind(this);
        this.onChangeLineItemFechaFin = this.onChangeLineItemFechaFin.bind(this);
        this.onChangeLineItemCountry = this.onChangeLineItemCountry.bind(this);
        this.onChangeLineItemDevices = this.onChangeLineItemDevices.bind(this);
        this.onChangeLineItemGenderBoys = this.onChangeLineItemGenderBoys.bind(this);
        this.onChangeLineItemGenderGirls = this.onChangeLineItemGenderGirls.bind(this);
        this.onChangeLineItemAudiences = this.onChangeLineItemAudiences.bind(this);
        this.onChangeLineItemCategories = this.onChangeLineItemCategories.bind(this);
        this.onChangeLineItemFrecuency = this.onChangeLineItemFrecuency.bind(this);
        this.onChangeLineItemBlUrl = this.onChangeLineItemBlUrl.bind(this);
        this.onChangeLineItemWlUrl = this.onChangeLineItemWlUrl.bind(this);
        this.onChangeLineItemFormat = this.onChangeLineItemFormat.bind(this);
        this.onChangeLineItemCreativeProvider = this.onChangeLineItemCreativeProvider.bind(this);
        this.onChangeLineItemAuditoriaMoat = this.onChangeLineItemAuditoriaMoat.bind(this);
        this.onChangeLineItemLinkAMateriales = this.onChangeLineItemLinkAMateriales.bind(this);
        this.onChangeLineItemUrlDestino = this.onChangeLineItemUrlDestino.bind(this);
        this.onChangeLineItemTracking = this.onChangeLineItemTracking.bind(this);
        this.onChangeExtendLineItemDate = this.onChangeExtendLineItemDate.bind(this);
        this.copyMediaPlanName = this.copyMediaPlanName.bind(this);
        this.copyLineItemName = this.copyLineItemName.bind(this);
        this.copyNombreCreatividad = this.copyNombreCreatividad.bind(this);
        this.copyLineItem2 = this.copyLineItem2.bind(this);
        this.calculateGoal = this.calculateGoal.bind(this);
        this.deleteLineItem = this.deleteLineItem.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.confirmLineItem = this.confirmLineItem.bind(this);
        this.reactivateLineItem = this.reactivateLineItem.bind(this);
        this.implementLineItem = this.implementLineItem.bind(this);
        this.sendToValidation = this.sendToValidation.bind(this);
        this.onChangeInconsistencyCheck = this.onChangeInconsistencyCheck.bind(this);
        this.onChangeFinishedDeliveryType = this.onChangeFinishedDeliveryType.bind(this);
        this.onChangeReportUrl = this.onChangeReportUrl.bind(this);
        this.confirmBillAdops = this.confirmBillAdops.bind(this);
        this.onChangeImpViewsInFavor = this.onChangeImpViewsInFavor.bind(this);
        this.calculateAmountInFavor = this.calculateAmountInFavor.bind(this);
        this.confirmBillFinance = this.confirmBillFinance.bind(this);
        this.onClickChangeStatusAccept = this.onClickChangeStatusAccept.bind(this);
        this.activateLineItem = this.activateLineItem.bind(this);

        this.state = {
            readOnly: true,
            lineItem: this.props.lineItem,
            reviewCollapsed: true,
            collapsed: true,
            lineItemLabel: this.props.lineItem.label,
            lineItemModeloDeCompra: this.props.lineItem.modelo_de_compra,
            lineItemModeloDeCompraValid: true,
            lineItemModeloDeCompraTooltip: '',
            lineItemSellRate: this.props.lineItem.sell_rate,
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemNetRate: this.props.lineItem.net_rate,
            lineItemGoal: this.props.lineItem.goal,
            lineItemTotalGoal: this.props.lineItem.total_goal,
            lineItemBonificacion: this.props.lineItem.bonificacion,
            lineItemInversion: this.props.lineItem.investment,
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemFacturacionMensual: this.props.lineItem.facturacion_mensual,
            lineItemFechaInicio: this.props.lineItem.fecha_inicio,
            lineItemFechaInicioValid: true,
            lineItemFechaInicioTooltip: true,
            lineItemFechaFin: this.props.lineItem.fecha_finalizacion,
            lineItemFechaFinValid: true,
            lineItemFechaFinTooltip: null,
            lineItemCountry: this.props.lineItem.pais,
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemCountryOptions: Helpers.getLineItemCountries(this.props.lineItem.pais),
            lineItemDevices: this.props.lineItem.devices,
            lineItemDevicesValid: true,
            lineItemDevicesOptions: Helpers.getLineItemDevices(this.props.lineItem.devices),
            lineItemGenderBoys: this.props.lineItem.gender === 0 || this.props.lineItem.gender === 1,
            lineItemGenderGirls: this.props.lineItem.gender === 0 || this.props.lineItem.gender === 2,
            lineItemGenderValid: true,
            lineItemGenderTooltip: '',
            lineItemAudiences: { min: this.props.lineItem.min_age, max: this.props.lineItem.max_age },
            lineItemCategories: this.props.lineItem.categoria_de_contenidos,
            lineItemCategoriesValid: true,
            lineItemCategoriesTooltip: '',
            lineItemCategoriesOptions: Helpers.getLineItemCategories(this.props.lineItem.categoria_de_contenidos),
            lineItemFrecuency: this.props.lineItem.frecuency,
            lineItemFrecuencyValid: true,
            lineItemFrecuencyTooltip: '',
            lineItemBlUrl: this.props.lineItem.bl_url,
            lineItemWlUrl: this.props.lineItem.wl_url,
            lineItemFormat: this.props.lineItem.formato,
            lineItemFormatValid: true,
            lineItemFormatTooltip: '',
            lineItemFormatOptions: [],
            lineItemCreativeProvider: this.props.lineItem.creative_provider,
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
            lineItemAuditoriaMoat: this.props.lineItem.auditoria_moat,
            lineItemAuditoriaMoatValid: true,
            lineItemAuditoriaMoatTooltip: '',
            lineItemLinkAMateriales: this.props.lineItem.link_a_materiales,
            lineItemLinkAMaterialesValid: true,
            lineItemLinkAMaterialesTooltip: '',
            lineItemUrlDestino: this.props.lineItem.url_destino,
            lineItemUrlDestinoValid: true,
            lineItemUrlDestinoTooltip: '',
            lineItemReportUrl: this.props.lineItem.report_url,
            lineItemTracking: this.props.lineItem.tracking,
            lineItemTrackingValid: true,
            lineItemTrackingTooltip: '',
            lineItemType: this.props.lineItem.line_item_type,
            lineItemNombreCreatividad: this.props.lineItem.nombre_creatividad,
            selected: this.props.selectAll,
            selectable: this.props.selectable === true,
            kickBack: this.props.dealKickBack,
            validateAdopsEmail: '',
            validateAdopsFullName: '',
            adopsOwners: [],
            inconsistentFields: this.props.lineItem.inconsistent_fields,
            finishedDeliveryType: '',
            reportUrl: '',
            impViewsInFavor: null,
            amountInFavor: null,
            mediaPlanOk: this.props.mediaPlanOk,
            extendLineItemDate: null,
            //deal para cambiar estado
            changeStatusLineItemId: 0,
            changeStatusStatus: 0,
            isUserOwner: this.props.isUserOwner,
        };
    }

    componentDidMount() {
        let userData = SessionHelper.getUserData();

        Helpers.setClientValidation();

        if (this.state.lineItem.modelo_de_compra !== null && this.state.lineItem.modelo_de_compra !== '') {
            this.setState({
                lineItemFormatOptions: Helpers.getModelosDeCompra(this.state.lineItem.modelo_de_compra)
            });
        }

        // get adops owners
        if (userData.group === SessionConfig.group_adops || userData.allWrite) {
            this.getAdopsOwners();
        }
    }

    componentDidUpdate() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ lineItem: nextProps.lineItem });
    }

    toggleCollapse(e) {
        let _collapsed = this.state.collapsed;
        this.setState({
            collapsed: !_collapsed
        });
    }

    copyMediaPlanName(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.media_plan_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyLineItemName(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.line_item_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyNombreCreatividad(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.nombre_creatividad);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyLineItem2(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.line_item_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    onChangeLineItemLinkAMateriales(e) {
        this.setState({
            lineItemLinkAMateriales: e.target.value
        });
    }
    onChangeLineItemUrlDestino(e) {
        this.setState({
            lineItemUrlDestino: e.target.value
        });
    }
    onChangeLineItemTracking(e) {
        this.setState({
            lineItemTracking: e.target.value
        });
    }
    onChangeLineItemLabel(e) {
        this.setState({
            lineItemLabel: e.target.value
        });
    }
    onChangeLineItemModeloDeCompra(e) {
        let modeloDeCompra = e.target.value;

        if (modeloDeCompra === "") {
            this.setState({
                lineItemModeloDeCompra: e.target.value,
                lineItemFormatOptions: [],
                lineItemFormat: null
            }, () => {
                this.calculateGoal();
            });
        } else {
            this.setState({
                lineItemModeloDeCompra: modeloDeCompra,
                lineItemFormat: null,
                lineItemFormatOptions: Helpers.getModelosDeCompra(modeloDeCompra)
            }, () => {
                this.calculateGoal();
            });
        }
    }
    onChangeLineItemSellRate(e) {
        this.setState({
            lineItemSellRate: e.target.value === "" ? '' : Helpers.roundNumber(parseFloat(e.target.value), 4)
        });
    }
    onChangeLineItemBonificacion(e) {
        this.setState({
            lineItemBonificacion: e.target.value === "" ? '' : parseInt(e.target.value)
        });
    }
    onChangeLineItemInversion(e) {
        this.setState({
            lineItemInversion: e.target.value === "" ? '' : Helpers.roundNumber(parseFloat(e.target.value), 2)
        });
    }
    onChangeLineItemFacturacionMensual(e) {
        this.setState({
            lineItemFacturacionMensual: e.target.checked
        });
    }
    onChangeLineItemFechaInicio(e) {
        this.setState({
            lineItemFechaInicio: e.target.value
        }, () => {
            this.validateLineItem();
        });
    }
    onChangeLineItemFechaFin(e) {
        this.setState({
            lineItemFechaFin: e.target.value
        }, () => {
            this.validateLineItem();
        });
    }
    onChangeLineItemCountry(selectedOptions) {
        let country = '';
        if (selectedOptions.selectedValue && typeof (selectedOptions.selectedValue[0]) !== "undefined")
            country = selectedOptions.selectedValue[0];

        this.setState({
            lineItemCountry: country
        });
    }
    onChangeLineItemDevices(selectedOptions) {
        let devices = selectedOptions.selectedValue.join(';');

        this.setState({
            lineItemDevices: devices
        });
    }
    onChangeLineItemGenderBoys(e) {
        let isSet = this.state.lineItemGenderBoys;
        this.setState({
            lineItemGenderBoys: !isSet
        });
    }
    onChangeLineItemGenderGirls(e) {
        let isSet = this.state.lineItemGenderGirls;
        this.setState({
            lineItemGenderGirls: !isSet
        });
    }
    onChangeLineItemAudiences(e) {
        this.setState({
            lineItemAudiences: { min: e.min, max: e.max }
        });
    }
    onChangeLineItemCategories(selectedOptions) {
        let categories = selectedOptions.selectedValue.join(';');

        this.setState({
            lineItemCategories: categories
        });
    }
    onChangeLineItemFrecuency(e) {
        this.setState({
            lineItemFrecuency: e.target.value
        });
    }
    onChangeLineItemBlUrl(e) {
        this.setState({
            lineItemBlUrl: e.target.value
        });
    }
    onChangeLineItemWlUrl(e) {
        this.setState({
            lineItemWlUrl: e.target.value
        });
    }
    onChangeLineItemFormat(e) {
        this.setState({
            lineItemFormat: e.target.value
        });
    }
    onChangeLineItemCreativeProvider(e) {
        this.setState({
            lineItemCreativeProvider: e.target.value
        });
    }
    onChangeLineItemAuditoriaMoat(e) {
        this.setState({
            lineItemAuditoriaMoat: e.target.value
        });
    }
    onChangeExtendLineItemDate(e) {
        this.setState({
            extendLineItemDate: e.target.value
        });
    }

    calculateGoal() {
        let modeloDeCompra = this.state.lineItemModeloDeCompra;
        let investment = this.state.lineItemInversion === "" ? 0 : this.state.lineItemInversion;
        let sellRate = this.state.lineItemSellRate === "" ? 0 : this.state.lineItemSellRate;
        let bonificacion = this.state.lineItemBonificacion === "" ? 0 : this.state.lineItemBonificacion;
        let goal = 0;
        let totalGoal = 0;

        // goal
        if (modeloDeCompra !== "" && sellRate > 0) {
            if (modeloDeCompra === "CPM")
                goal = (investment / sellRate) * 1000;
            else if (modeloDeCompra === "CPV" || modeloDeCompra === "CPCV")
                goal = (investment / sellRate);
        }

        // total goal
        totalGoal = goal + bonificacion;

        // net rate
        let netRate = 0;
        if (this.state.kickBack !== null && this.state.kickBack !== '' && goal > 0) {
            let kickBack = parseInt(this.state.kickBack.replace('%', '').trim());
            netRate = (sellRate * (1 - (kickBack / 100))) - (sellRate * (bonificacion / goal));
        }

        this.setState({
            lineItemGoal: Helpers.roundNumber(goal, 3),
            lineItemTotalGoal: Helpers.roundNumber(totalGoal, 3),
            lineItemNetRate: Helpers.roundNumber(netRate, 4)
        }, () => {
            this.validateLineItem();
        });
    }

    onChangeInconsistencyCheck(e) {
        let _inconsistentFields = this.state.inconsistentFields;

        if (e.target.checked) {
            if (!_inconsistentFields.some(x => x === e.target.value))
                _inconsistentFields.push(e.target.value);
        } else {
            if (_inconsistentFields.some(x => x === e.target.value))
                _inconsistentFields.splice(_inconsistentFields.indexOf(e.target.value), 1)
        }

        this.setState({
            inconsistentFields: _inconsistentFields
        });
    }

    onChangeReportUrl(e) {
        this.setState({
            reportUrl: e.target.value
        });
    }

    onChangeFinishedDeliveryType(e) {
        this.setState({
            finishedDeliveryType: e.target.value
        });
    }

    onChangeImpViewsInFavor(e) {
        this.setState({
            impViewsInFavor: e.target.value
        });
    }

    calculateAmountInFavor(e) {
        let _amountInFavor = 0;
        let modeloDeCompra = this.state.lineItemModeloDeCompra;
        let impViewsInFavor = this.state.impViewsInFavor === "" ? 0 : this.state.impViewsInFavor;
        let sellRate = this.state.lineItemSellRate === "" || this.state.lineItemSellRate === null ? 0 : this.state.lineItemSellRate;

        // amount in favor
        if (modeloDeCompra !== null && modeloDeCompra !== "" && sellRate > 0) {
            if (modeloDeCompra === "CPM")
                _amountInFavor = (sellRate * impViewsInFavor) / 1000;
            else if (modeloDeCompra === "CPV" || modeloDeCompra === "CPCV")
                _amountInFavor = (sellRate * impViewsInFavor);
        }

        this.setState({
            amountInFavor: Helpers.roundNumber(_amountInFavor, 3)
        });
    }

    setEditView(e) {
        this.setState({
            readOnly: false
        }, () => {
            Helpers.setFormClientValidation('lineItemEditForm-' + this.state.lineItem.id);
        });

        $('.modal').modal('hide'); // closes all active pop ups.
    }

    setReadOnlyView(e) {
        this.setState({
            readOnly: true
        });
    }

    saveLineItem(e) {
        e.preventDefault();

        let gender = null;
        if (this.state.lineItemGenderBoys === true && this.state.lineItemGenderGirls === true)
            gender = 0;
        else if (this.state.lineItemGenderBoys === true && this.state.lineItemGenderGirls === false)
            gender = 1;
        else if (this.state.lineItemGenderBoys === false && this.state.lineItemGenderGirls === true)
            gender = 2;

        const data = {
            id: this.state.lineItem.id,
            deal_id: this.state.lineItem.deal_id,
            label: this.state.lineItemLabel,
            modelo_de_compra: this.state.lineItemModeloDeCompra,
            sell_rate: this.state.lineItemSellRate,
            net_rate: this.state.lineItemNetRate,
            goal: this.state.lineItemGoal,
            bonificacion: this.state.lineItemBonificacion,
            total_goal: this.state.lineItemTotalGoal,
            investment: this.state.lineItemInversion,
            facturacion_mensual: this.state.lineItemFacturacionMensual,
            fecha_inicio: this.state.lineItemFechaInicio,
            fecha_finalizacion: this.state.lineItemFechaFin,
            pais: this.state.lineItemCountry,
            devices: this.state.lineItemDevices,
            //audiences: this.state.lineItemAudiences,
            gender: gender,
            min_age: this.state.lineItemAudiences.min,
            max_age: this.state.lineItemAudiences.max,
            categoria_de_contenidos: this.state.lineItemCategories,
            frecuency: this.state.lineItemFrecuency,
            bl_url: this.state.lineItemBlUrl,
            wl_url: this.state.lineItemWlUrl,
            formato: this.state.lineItemFormat,
            creative_provider: this.state.lineItemCreativeProvider,
            auditoria_moat: this.state.lineItemAuditoriaMoat,
            link_a_materiales: this.state.lineItemLinkAMateriales,
            tracking: this.state.lineItemTracking,
            url_destino: this.state.lineItemUrlDestino,
            goal: this.state.lineItemGoal,
            total_goal: this.state.lineItemTotalGoal,
            line_item_type: this.state.lineItemType
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/save', data)
            .then((response) => {
                let form = document.getElementById('lineItemEditForm-' + this.state.lineItem.id);
                form.classList.remove("was-validated");

                this.props.refreshDeal();

                this.setState({
                    readOnly: true,
                });

                let msg = TextResourceHelper.get('successmessage_data_saved');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    validateLineItem(e) {
        let userData = SessionHelper.getUserData();
        let today = new Date().toISOString().split('T')[0];
        let isValid = true;
        this.setState({
            lineItemModeloDeCompraValid: true,
            lineItemModeloDeCompraTooltip: '',
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemFechaInicioValid: true,
            lineItemFechaInicioTooltip: '',
            lineItemFechaFinValid: true,
            lineItemFechaFinTooltip: '',
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemDevicesValid: true,
            lineItemDevicesTooltip: '',
            lineItemGenderValid: true,
            lineItemGenderTooltip: '',
            lineItemCategoryValid: true,
            lineItemCategoryTooltip: '',
            lineItemFrecuencyValid: true,
            lineItemFrecuencyTooltip: '',
            lineItemFormatValid: true,
            lineItemFormatTooltip: '',
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
            lineItemAuditoriaMoatValid: true,
            lineItemAuditoriaMoatTooltip: '',
            lineItemLinkAMaterialesValid: true,
            lineItemLinkAMaterialesTooltip: '',
            lineItemTrackingValid: true,
            lineItemTrackingTooltip: '',
            lineItemUrlDestinoValid: true,
            lineItemUrlDestinoTooltip: '',
            //line item para cambiar estado
            changeStatusLineItemId: 0,
            changeStatusStatus: 0,
        });

        if (document.getElementById("fechainicio-" + this.state.lineItem.id))
            document.getElementById("fechainicio-" + this.state.lineItem.id).setCustomValidity("");
        if (document.getElementById("fechafin-" + this.state.lineItem.id))
            document.getElementById("fechafin-" + this.state.lineItem.id).setCustomValidity("");
        if (document.getElementById("investment-" + this.state.lineItem.id))
            document.getElementById("investment-" + this.state.lineItem.id).setCustomValidity("");
        $(".selectcountry, .selectdevices, .selectcategories, .btnGender").removeClass("invalidfield");

        // modelo de compra
        if (this.state.lineItemModeloDeCompra === null || this.state.lineItemModeloDeCompra === "") {
            isValid = false;
            this.setState({ lineItemModeloDeCompraValid: false, lineItemModeloDeCompraTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // sell rate
        if (this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
            isValid = false;
            this.setState({ lineItemSellRateValid: false, lineItemSellRateTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // bonificacion
        if (this.state.lineItemBonificacion === null || this.state.lineItemBonificacion === "") {
            this.setState({ lineItemBonificacion: 0 });
        }

        // inversion
        if (this.state.lineItemInversion === null || this.state.lineItemInversion === "") {
            isValid = false;
            this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        } else {
            if (this.state.lineItemModeloDeCompra === null || this.state.lineItemModeloDeCompra === "" || this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
                isValid = false;
                this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_investment_invalid') });
            } else {
                // Si �modelo de compra� es �CPM� entonces �Inversi�n� debe ser igual a((�Goal� * �sell rate�) / 1000).
                if (this.state.lineItemModeloDeCompra === "CPM" && this.state.lineItemInversion !== ((this.state.lineItemGoal * this.state.lineItemSellRate) / 1000)) {
                    isValid = false;
                    this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_investment_invalid') });
                }
                // Si �modelo de compra� es �CPV� o �CPCV� entonces �Inversi�n� debe ser igual a (�Goal� * �sell rate�)
                if ((this.state.lineItemModeloDeCompra === "CPV" || this.state.lineItemModeloDeCompra === "CPCV") && this.state.lineItemInversion !== (this.state.lineItemGoal * this.state.lineItemSellRate)) {
                    isValid = false;
                    this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_investment_invalid') });
                }
            }
        }

        // fecha inicio
        if (this.state.lineItemFechaInicio === null || this.state.lineItemFechaInicio === "") {
            isValid = false;
            this.setState({
                lineItemFechaInicioValid: false,
                lineItemFechaInicioTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty')
            });
        } else if (this.state.lineItemFechaInicio < today) {
            //isValid = false;
            //this.setState({
            //    lineItemFechaInicioValid: false,
            //    lineItemFechaInicioTooltip: TextResourceHelper.get('errormessage_fechainicio_cannot_be_lower_now')
            //});
            //document.getElementById("fechainicio").setCustomValidity(TextResourceHelper.get('errormessage_fechainicio_cannot_be_lower_now'));
        }

        // fecha finalizacion
        if (this.state.lineItemFechaFin === null || this.state.lineItemFechaFin === "") {
            isValid = false;
            this.setState({
                lineItemFechaFinValid: false,
                lineItemFechaFinTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty')
            });
        } else if (this.state.lineItemFechaInicioValid && this.state.lineItemFechaFin < this.state.lineItemFechaInicio) {
            isValid = false;
            this.setState({
                lineItemFechaFinValid: false,
                lineItemFechaFinTooltip: TextResourceHelper.get('errormessage_fechafin_cannot_be_lower_fechainicio')
            });
            document.getElementById("fechafin-" + this.state.lineItem.id).setCustomValidity(TextResourceHelper.get('errormessage_fechafin_cannot_be_lower_fechainicio'));
        }

        // country
        if (this.state.lineItemCountry === null || this.state.lineItemCountry === "") {
            isValid = false;
            $(".selectcountry").addClass("invalidfield");
            this.setState({ lineItemCountryValid: false, lineItemCountryTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // devices
        if (this.state.lineItemDevices === null || this.state.lineItemDevices === "") {
            isValid = false;
            $(".selectdevices").addClass("invalidfield");
            this.setState({ lineItemDevicesValid: false, lineItemDevicesTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // gender
        if (this.state.lineItemGenderBoys === false && this.state.lineItemGenderGirls === false) {
            isValid = false;
            $(".btnGender").addClass("invalidfield");
            this.setState({ lineItemGenderValid: false, lineItemGenderTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // category
        if (this.state.lineItemCategories === null || this.state.lineItemCategories === "") {
            isValid = false;
            $(".selectcategories").addClass("invalidfield");
            this.setState({ lineItemCategoriesValid: false, lineItemCategoriesTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // frecuency
        if (this.state.lineItemFrecuency === null || this.state.lineItemFrecuency === "") {
            isValid = false;
            this.setState({ lineItemFrecuencyValid: false, lineItemFrecuencyTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // format
        if (this.state.lineItemFormat === null || this.state.lineItemFormat === "") {
            isValid = false;
            this.setState({ lineItemFormatValid: false, lineItemFormatTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        // creative provider
        if (this.state.lineItemCreativeProvider === null || this.state.lineItemCreativeProvider === "") {
            isValid = false;
            this.setState({ lineItemCreativeProviderValid: false, lineItemCreativeProviderTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        // auditoria moat
        if (this.state.lineItemAuditoriaMoat === null || this.state.lineItemAuditoriaMoat === "") {
            isValid = false;
            this.setState({ lineItemAuditoriaMoatValid: false, lineItemAuditoriaMoatTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        if (userData.group === SessionHelper.group_ms) {
            // link to materials
            if (this.state.lineItemLinkAMateriales === null || this.state.lineItemLinkAMateriales === "") {
                isValid = false;
                this.setState({ lineItemLinkAMaterialesValid: false, lineItemLinkAMaterialesTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
            }

            // tracking
            if (this.state.lineItemTracking === null || this.state.lineItemTracking === "") {
                isValid = false;
                this.setState({ lineItemTrackingValid: false, lineItemTrackingTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
            }

            // url destino
            if (this.state.lineItemUrlDestino === null || this.state.lineItemUrlDestino === "") {
                isValid = false;
                this.setState({ lineItemUrlDestinoValid: false, lineItemUrlDestinoTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
            }
        }

        var form = document.getElementById('lineItemEditForm-' + this.state.lineItem.id);
        if (form) {
            form.checkValidity();
            form.classList.add('was-validated');
        }
    }

    isValidLineItem() {
        let userData = SessionHelper.getUserData();
        let today = new Date().toISOString().split('T')[0];
        let isValid = true;

        // modelo de compra
        if (this.state.lineItemModeloDeCompra === null || this.state.lineItemModeloDeCompra === "") {
            isValid = false;
        }

        // sell rate
        if (this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
            isValid = false;
        }

        // bonificacion
        if (this.state.lineItemBonificacion === null || this.state.lineItemBonificacion === "") {
        }

        // inversion
        if (this.state.lineItemInversion === null || this.state.lineItemInversion === "") {
            isValid = false;
        } else {
            if (this.state.lineItemModeloDeCompra === null || this.state.lineItemModeloDeCompra === "" || this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
                isValid = false;
            } else {
                // Si �modelo de compra� es �CPM� entonces la diferencia entre �Inversi�n� y ((�Goal� * �sell rate�) / 1000) debe ser menor o igual 3
                if (this.state.lineItemModeloDeCompra === "CPM" && Math.abs(this.state.lineItemInversion - (this.state.lineItemGoal * this.state.lineItemSellRate) / 1000) > 3) {
                    isValid = false;
                }
                // Si �modelo de compra� es �CPV� o �CPCV� entonces la diferencia entre �Inversi�n� y (�Goal� * �sell rate�) debe ser menor o igual 3
                if ((this.state.lineItemModeloDeCompra === "CPV" || this.state.lineItemModeloDeCompra === "CPCV") && Math.abs(this.state.lineItemInversion - (this.state.lineItemGoal * this.state.lineItemSellRate)) > 3) {
                    isValid = false;
                }
            }
        }

        // fecha inicio
        if (this.state.lineItemFechaInicio === null || this.state.lineItemFechaInicio === "") {
            isValid = false;
        } else if (this.state.lineItemFechaInicio < today) {
        }

        // fecha finalizacion
        if (this.state.lineItemFechaFin === null || this.state.lineItemFechaFin === "") {
            isValid = false;
        } else if (this.state.lineItemFechaInicioValid && this.state.lineItemFechaFin < this.state.lineItemFechaInicio) {
            isValid = false;
        }

        // country
        if (this.state.lineItemCountry === null || this.state.lineItemCountry === "") {
            isValid = false;
        }

        // devices
        if (this.state.lineItemDevices === null || this.state.lineItemDevices === "") {
            isValid = false;
        }

        // gender
        if (this.state.lineItemGenderBoys === false && this.state.lineItemGenderGirls === false) {
            isValid = false;
        }

        // category
        if (this.state.lineItemCategories === null || this.state.lineItemCategories === "") {
            isValid = false;
        }

        // frecuency
        if (this.state.lineItemFrecuency === null || this.state.lineItemFrecuency === "") {
            isValid = false;
        }

        // format
        if (this.state.lineItemFormat === null || this.state.lineItemFormat === "") {
            isValid = false;
        }

        // creative provider
        if (this.state.lineItemCreativeProvider === null || this.state.lineItemCreativeProvider === "") {
            isValid = false;
        }

        // auditoria moat
        if (this.state.lineItemAuditoriaMoat === null || this.state.lineItemAuditoriaMoat === "") {
            isValid = false;
        }

        if (userData.group === SessionHelper.group_ms) {
            // link to materials
            if (this.state.lineItemLinkAMateriales === null || this.state.lineItemLinkAMateriales === "") {
                isValid = false;
            }

            // tracking
            if (this.state.lineItemTracking === null || this.state.lineItemTracking === "") {
                isValid = false;
            }

            // url destino
            if (this.state.lineItemUrlDestino === null || this.state.lineItemUrlDestino === "") {
                isValid = false;
            }
        }

        return isValid;
    }

    deleteLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/delete/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                $('.close').click();
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_deleted'));
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    confirmLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/confirm/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_confirmed'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    reactivateLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/reactivate/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_reactivated'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    getAdopsOwners() {
        var owners = localStorage.getItem("OwnersAdops");
        var result = JSON.parse(owners);
        this.setState({
            adopsOwners: result
        });

    }

    implementLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/implement/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_implemented'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    sendToValidation(e) {
        e.preventDefault();

        if (!this.state.validateAdopsEmail || this.state.validateAdopsEmail === '')
            return;

        const data = {
            adops_user_email: this.state.validateAdopsEmail
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/sendtovalidation/' + this.state.lineItem.id, data)
            .then((response) => {
                $('#validateLiModal-' + this.state.lineItem.id).modal("hide");
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_sent_validation'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    confirmBillAdops(e) {
        e.preventDefault();

        if (this.state.finishedDeliveryType === '' || this.state.reportUrl === '')
            return;

        const data = {
            finished_delivery_type: this.state.finishedDeliveryType,
            report_url: this.state.reportUrl,
            imp_views_in_favor: this.state.impViewsInFavor,
            amount_in_favor: this.state.amountInFavor
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/adops/bill/' + this.state.lineItem.id, data)
            .then((response) => {
                $('#billLiModal-' + this.state.lineItem.id).modal("hide");
                this.props.refreshDeal();
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_billed'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    confirmBillFinance(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/finance/bill/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_billed'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    validateLineItemAdops(e) {
        e.preventDefault();

        const data = {
            inconsistent_fields: this.state.inconsistentFields
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/validate/' + this.state.lineItem.id, data)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_validated'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    setLineItemImplemented(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/setimplemented/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_set_implemented'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    extendLineItem(e) {
        e.preventDefault();

        if (this.state.extendLineItemDate === '' || this.state.extendLineItemDate === null)
            return;

        const data = {
            new_end_date: this.state.extendLineItemDate
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/extend/' + this.state.lineItem.id, data)
            .then((response) => {
                $('#extendLiModal-' + this.state.lineItem.id).modal("hide");
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_extended'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    billLineItemTotal(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/billtotal/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_bill_total'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    billLineItemDelivered(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/billdelivered/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_bill_delivered'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }
    activateLineItem(e) {

        //El 1 es el id del estado ACTIVO
        axios.post(window.config.REACT_APP_API_URL + '/deal/ChangeStatusLineItem/' + this.state.lineItem.id, 1)
            .then(() => {

                this.setState({
                    changeStatusLineItemId: 0,
                    changeStatusStatus: 0
                });

                this.props.refreshDeal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    duplicateLineItem(e) {
        e.preventDefault();

        let lineItemIds = [];
        lineItemIds.push(this.state.lineItem.id);

        const data = {
            line_item_ids: lineItemIds
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/' + this.state.lineItem.deal_id + '/lineitems/duplicate', data)
            .then((response) => {
                this.props.refreshDeal();

                let msg = TextResourceHelper.get('infomessage_lineitem_duplicated');
                Helpers.notifyInfo(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    toggleSelected(e) {
        let _selected = e.target.checked;
        this.setState({
            selected: _selected
        });

        this.props.setSelected(this.state.lineItem.id, _selected);
    }

    setValidateAdopsEmail = (email, fullName) => () => {
        this.setState({
            validateAdopsEmail: email,
            validateAdopsFullName: fullName
        })
    }

    havePermission(namePermission) {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission) {
                result = true;
            }
        });

        return result;
    }
    isOwner() {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == "allRead" || value == "allWrite")
                result = true;
        });

        return result;
    }
    changeStatusLineItem = (lineItemId, status) => {
        this.setState({
            changeStatusLineItemId: lineItemId,
            changeStatusStatus: status
        });
    }
    getClassNameLineItemStatus(statusId) {

        let statusCssClass = '';

        if (statusId >= 3 && statusId <= 5)
            statusCssClass = 'btn-status-kickoff';
        else if (statusId === 15)
            statusCssClass = 'btn-status-preparation';
        else if (statusId >= 6 && statusId <= 9 || statusId === 16)
            statusCssClass = 'btn-status-implementation';
        else if (statusId === 1)
            statusCssClass = 'btn-status-active';
        else
            statusCssClass = 'btn-status-paused-finished';

        return statusCssClass;

    }
    onClickChangeStatusAccept(e) {

        axios.post(window.config.REACT_APP_API_URL + '/deal/ChangeStatusLineItem/' + this.state.changeStatusLineItemId, this.state.changeStatusStatus)
            .then(() => {

                $('#changeStatusLineItemModal-' + this.state.changeStatusLineItemId).modal('hide');

                this.setState({
                    changeStatusLineItemId: 0,
                    changeStatusStatus: 0
                });

                this.props.refreshDeal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {

        let userData = SessionHelper.getUserData();

        return (
            <div class="card">
                <div class="card-header lineItemName" id="headingOne">
                    <h5 class="mb-0">
                        {this.state.selectable && <input class="" type="checkbox" value="" onChange={this.toggleSelected} checked={this.props.selectAll === true || this.state.selected === true} disabled={this.props.selectAll === true} />}
                        <div onClick={this.toggleCollapse} class="btn-link" style={{ fontSize: '0.9rem', display: 'inline-block', cursor: 'pointer' }} data-toggle="collapse" data-target={'#lineItemCollapse-' + this.state.lineItem.id} aria-expanded="true" aria-controls={'lineItemCollapse-' + this.state.lineItem.id}>
                            <i class={'fa mr-2 ' + (this.state.collapsed ? 'fa-chevron-right' : 'fa-chevron-down')}></i>{this.state.lineItem.line_item_name}
                        </div><div onClick={this.copyLineItem2} class="btn btn-default" style={{}}><i class="far fa-clone"></i></div>
                        {
                            <button type="button" className={"btn btn-default float-right " + this.getClassNameLineItemStatus(this.state.lineItem.status)}>{this.state.lineItem.status_description}</button>
                        }
                    </h5>
                </div>

                {
                    (this.havePermission("verLineItems") || userData.allRead) &&
                    <div id={'lineItemCollapse-' + this.state.lineItem.id} class="collapse" aria-labelledby="headingOne" data-parent={'#lineItemsAccordion-' + this.state.lineItem.deal_id}>
                        <div class="card-body">
                            {this.state.readOnly && this.readOnlyView()}
                            {!this.state.readOnly && this.editView()}
                        </div>
                    </div>
                }
            </div>
        );
    }

    readOnlyView() {
        let today = new Date().toISOString().substring(0, 10) + 'T00:00:00';
        let userData = SessionHelper.getUserData();

        let showValidateImplementationButton = (this.havePermission("marcarValidacion") || userData.allWrite)
            && (
                this.state.lineItem.status === LineItemStatusHelper.to_implement
                || (this.state.inconsistentFields.length > 0 && this.state.lineItem.status === LineItemStatusHelper.require_validation)
            )
            && (this.state.lineItem.adops_validator_user_email === null || this.state.lineItem.adops_validator_user_email !== userData.email);

        let canValidateLineItem = //(this.havePermission("validarCamposLineItem") || userData.allWrite ) && 
            this.state.lineItem.status === LineItemStatusHelper.require_validation
            && this.state.lineItem.adops_validator_user_email !== null
            && (userData.allWrite || userData.email === this.state.lineItem.adops_validator_user_email);

        let showInconsistentFields = userData.group === SessionConfig.group_adops
            && this.state.lineItem.status === LineItemStatusHelper.require_validation
            && this.state.inconsistentFields !== null
            && this.state.inconsistentFields.length > 0;

        let showSetImplementedButton = (this.havePermission("marcarImplementado") || userData.allWrite)
            && this.state.lineItem.status === LineItemStatusHelper.validated
            && this.state.lineItem.adops_implementer_user_email === userData.email;

        let showBillButtonAdops = (this.havePermission("enviarLineItemAFacturar") || userData.allWrite)
            && (this.state.lineItem.status === LineItemStatusHelper.active || this.state.lineItem.status === LineItemStatusHelper.finished)
            && this.state.lineItem.fecha_finalizacion <= today;

        let showBillButtonFinance = (this.havePermission("facturarLineItem") || userData.allWrite)
            && (this.state.lineItem.status === LineItemStatusHelper.to_bill || this.state.lineItem.status === LineItemStatusHelper.underdelivery);

        let showConfirmButton = this.isValidLineItem() === true
            && (this.havePermission("confirmarLineItem") || userData.allWrite || this.state.isUserOwner)
            /*&& (this.state.lineItem.deal_status === DealHelper.status_paused || this.state.lineItem.deal_status === DealHelper.status_kick_off || this.state.lineItem.deal_status === DealHelper.status_implementation)*/
            && (this.state.lineItem.status === LineItemStatusHelper.saved || this.state.lineItem.status === LineItemStatusHelper.pause);

        let showEditButton = userData.group === SessionConfig.group_sales
            || ((userData.group === SessionConfig.group_ms === LineItemStatusHelper.preparation || this.state.lineItem.status === LineItemStatusHelper.implemented || this.state.lineItem.status === LineItemStatusHelper.pause));

        let showExtendButton = this.state.lineItem.status === LineItemStatusHelper.underdelivery
            && (this.havePermission("extenderFechaUnderdelivery") || userData.allWrite);

        let showBillTotalButton = this.state.lineItem.status === LineItemStatusHelper.underdelivery
            && (this.havePermission("enviarLineItemFacturarTotal") || userData.allWrite);

        let showBillDeliveredButton = this.state.lineItem.status === LineItemStatusHelper.underdelivery
            && (this.havePermission("enviarLineItemFacturarEntregado") || userData.allWrite);

        let showActivateButton = this.state.lineItem.status === LineItemStatusHelper.implemented
            && (this.havePermission("activateLineItem") || userData.allWrite);

        let lineItemUrlDestinoHttp = this.state.lineItemUrlDestino;
        if (lineItemUrlDestinoHttp !== null) {
            if (lineItemUrlDestinoHttp.substr(0, 4).toLowerCase() !== "http") {
                lineItemUrlDestinoHttp = "http://" + lineItemUrlDestinoHttp;
            }
        }

        return (

            <div id={'lineItemReadOnlyView-' + this.state.lineItem.id} className="">
                <div class="modal-body p-2">
                    <div class="container p-0">
                        <div class="row">
                            <div class="col-sm">
                                <div className="text-center">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_investment')}</label>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'modelo_de_compra') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_purchase_model')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="modelo_de_compra" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'modelo_de_compra')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemModeloDeCompra} />
                                </div>

                                <div class="form-row mb-2">
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'sell_rate') ? 'w-100 inconsistentfield' : 'w-100'}>
                                                {TextResourceHelper.get('label_sell_rate')}
                                                {
                                                    canValidateLineItem &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                        <input class="float-right" type="checkbox" value="sell_rate" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'sell_rate')} />
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <input type="number" step=".001" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemSellRate} />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'net_rate') ? 'w-100 inconsistentfield' : 'w-100'}>
                                                {TextResourceHelper.get('label_net_rate')}
                                                {
                                                    canValidateLineItem &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                        <input class="float-right" type="checkbox" value="net_rate" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'net_rate')} />
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <input type="number" step=".001" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemNetRate} />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'goal') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_goal_imp_views')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="goal" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'goal')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input step=".001" type="number" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemGoal} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'bonificacion') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_bonificacion_imp_views')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="bonificacion" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'bonificacion')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input step="1" type="number" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemBonificacion} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'total_goal') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_total_goal')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="total_goal" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'total_goal')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input step=".001" type="number" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemTotalGoal} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'investment') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_investment')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="investment" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'investment')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input step=".01" type="number" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemInversion} />
                                </div>

                                <div class="form-group mb-2">
                                    <div class="form-check">
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_facturacion_mensual')}</Tooltip>}>
                                            <input readonly="" disabled="disabled" class="form-check-input" type="checkbox" value={this.state.lineItemFacturacionMensual} checked={this.state.lineItemFacturacionMensual === true} />
                                        </OverlayTrigger>
                                        <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'facturacion_mensual') ? 'form-check-label font-italic inconsistentfield' : 'form-check-label font-italic'} for="gridCheck">
                                            {TextResourceHelper.get('label_facturacion_mensual')}
                                        </label>
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="facturacion_mensual" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'facturacion_mensual')} />
                                            </OverlayTrigger>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm">
                                <div className="text-center">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_targeting')}</label>
                                </div>

                                <div class="form-row mb-2">
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'start_date') ? 'w-100 inconsistentfield' : 'w-100'}>
                                                {TextResourceHelper.get('label_start_date')}
                                                {
                                                    canValidateLineItem &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                        <input class="float-right" type="checkbox" value="start_date" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'start_date')} />
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <input type="date" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" placeholder="" defaultValue={Helpers.formatDate(this.state.lineItemFechaInicio)} />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'end_date') ? 'w-100 inconsistentfield' : 'w-100'}>
                                                {TextResourceHelper.get('label_end_date')}
                                                {
                                                    canValidateLineItem &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                        <input class="float-right" type="checkbox" value="end_date" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'end_date')} />
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <input type="date" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" placeholder="" defaultValue={Helpers.formatDate(this.state.lineItemFechaFin)} />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'country') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_country')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="country" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'country')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemCountry} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'devices') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_devices')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="devices" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'devices')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemDevices} />
                                </div>

                                <div class="form-row mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'gender') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_gender')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="gender" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'gender')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <div class="col-sm-6">
                                        <button type="button" className={'btn btn-sm w-100 disabled ' + (this.state.lineItemGenderBoys ? 'btn-primary' : 'btn-outline-primary')}>{TextResourceHelper.get('label_gender_boys')}</button>
                                    </div>
                                    <div class="col-sm-6">
                                        <button type="button" className={'btn btn-sm w-100 disabled ' + (this.state.lineItemGenderGirls ? 'btn-primary' : 'btn-outline-primary')}>{TextResourceHelper.get('label_gender_girls')}</button>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'audiences') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_age')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="audiences" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'audiences')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <InputRange
                                        disabled={true}
                                        minValue={0}
                                        maxValue={17}
                                        formatLabel={value => `${value} ` + TextResourceHelper.get('label_years').toLowerCase()}
                                        value={this.state.lineItemAudiences} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'categories') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_categories')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="categories" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'categories')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemCategories} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'frequency') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_frequency')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="frequency" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'frequency')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemFrecuency} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'bl_url') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_bl_url')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="bl_url" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'bl_url')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    {this.state.lineItemBlUrl !== null && this.state.lineItemBlUrl !== '' && <a target="_blank" href={this.state.lineItemBlUrl}>{this.state.lineItemBlUrl}</a>}
                                    {(this.state.lineItemBlUrl === null || this.state.lineItemBlUrl === '') && <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value="---" />}
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'wl_url') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_wl_url')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="wl_url" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'wl_url')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    {this.state.lineItemWlUrl !== null && this.state.lineItemWlUrl !== '' && <a target="_blank" href={this.state.lineItemWlUrl}>{this.state.lineItemWlUrl}</a>}
                                    {(this.state.lineItemWlUrl === null || this.state.lineItemWlUrl === '') && <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value="---" />}
                                </div>

                            </div>

                            <div class="col-sm">
                                <div className="text-center">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_creativities')}</label>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'nombre_creatividad') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_nombre_creatividad')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="nombre_creatividad" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'nombre_creatividad')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemNombreCreatividad} />
                                    <button onClick={this.copyNombreCreatividad} class="btn btn-default" style={{ position: 'absolute', right: '0', top: '50px' }}><i class="far fa-clone"></i></button>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'format') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_format')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="format" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'format')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemFormat} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'creative_provider') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_provider')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="creative_provider" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'creative_provider')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemCreativeProvider} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'auditoria_moat') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_moat')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="auditoria_moat" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'auditoria_moat')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemAuditoriaMoat} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'link_to_materials') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_link_to_materials')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="link_to_materials" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'link_to_materials')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    {this.state.lineItemLinkAMateriales !== null && this.state.lineItemLinkAMateriales !== '' && <a target="_blank" href={this.state.lineItemLinkAMateriales}>{this.state.lineItemLinkAMateriales}</a>}
                                    {this.state.lineItemLinkAMateriales === null && <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value="---" />}
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'tracking') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_tracking')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="tracking" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'tracking')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemTracking} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'destination_url') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_destination_url')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="destination_url" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'destination_url')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    {this.state.lineItemUrlDestino !== null && this.state.lineItemUrlDestino !== '' && <a target="_blank" href={lineItemUrlDestinoHttp}>{this.state.lineItemUrlDestino}</a>}
                                    {this.state.lineItemUrlDestino === null && <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value="---" />}
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'report_url') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_report_url')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="report_url" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'report_url')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    {this.state.lineItemReportUrl !== null && this.state.lineItemReportUrl !== '' && <a target="_blank" href={this.state.lineItemReportUrl}>{this.state.lineItemReportUrl}</a>}
                                    {this.state.lineItemReportUrl === null && <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value="---" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !this.state.selectable &&
                    <div class="modal-footer">
                        {
                            (userData.role == 1) &&
                            <div className="dropdown">
                                <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id={'dropdownMenuButton-' + this.state.lineItem.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {TextResourceHelper.get('change_status')}
                                </button>
                                <div class="dropdown-menu" aria-labelledby={'dropdownMenuButton-' + this.state.lineItem.id}>
                                    <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 3)}>{TextResourceHelper.get('lineitemstatus_saved')}</a>
                                    <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 4)}>{TextResourceHelper.get('lineitemstatus_to_confirm')}</a>
                                    <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 5)}>{TextResourceHelper.get('lineitemstatus_confirmed')}</a>

                                    <a className="dropdown-item status-preparation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 15)}>{TextResourceHelper.get('lineitemstatus_preparation')}</a>

                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 7)}>{TextResourceHelper.get('lineitemstatus_to_implement')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 6)}>{TextResourceHelper.get('lineitemstatus_to_validate')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 8)}>{TextResourceHelper.get('lineitemstatus_require_validation')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 16)}>{TextResourceHelper.get('lineitemstatus_validated')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 9)}>{TextResourceHelper.get('lineitemstatus_implemented')}</a>

                                    <a className="dropdown-item status-active" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 1)}>{TextResourceHelper.get('lineitemstatus_active')}</a>

                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 10)}>{TextResourceHelper.get('lineitemstatus_finished')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 12)}>{TextResourceHelper.get('lineitemstatus_ok')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 11)}>{TextResourceHelper.get('lineitemstatus_overdelivery')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 17)}>{TextResourceHelper.get('lineitemstatus_underdelivery')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 13)}>{TextResourceHelper.get('lineitemstatus_to_bill')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 14)}>{TextResourceHelper.get('lineitemstatus_billed')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 2)}>{TextResourceHelper.get('lineitemstatus_pause')}</a>

                                </div>
                            </div>
                        }
                        {(this.havePermission("eliminarLineItem") || userData.allWrite) && <button type="button" class="btn btn-default" data-toggle="modal" data-target={'#deleteConfirmModal-' + this.state.lineItem.id}><i class="fa fa-2x fa-trash"></i></button>}
                        {(this.havePermission("duplicarLineItem") || userData.allWrite) && <button type="button" onClick={this.duplicateLineItem} class="btn btn-default"><i class="far fa-2x fa-clone"></i></button>}
                        {(this.havePermission("editarLineItem") || userData.allWrite) && <button type="button" class="btn btn-default" data-toggle="modal" data-target={'#editConfirmModal-' + this.state.lineItem.id}><i class="fa fa-2x fa-edit"></i></button>}
                        {this.state.lineItem.status === LineItemStatusHelper.preparation && (userData.group === SessionConfig.group_ms || userData.allWrite) && <button onClick={this.implementLineItem} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_implement')}</button>}
                        {showValidateImplementationButton && <button type="button" class="btn btn-primary" data-toggle="modal" data-target={'#validateLiModal-' + this.state.lineItem.id}>{TextResourceHelper.get('buttonlabel_validate_implementation')}</button>}
                        {canValidateLineItem && <button onClick={this.validateLineItemAdops} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_validate_lineitem')}</button>}
                        {showConfirmButton && <button type="button" onClick={this.confirmLineItem} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_confirm')}</button>}
                        {this.state.lineItem.status === LineItemStatusHelper.confirmed && <button type="button" class="btn btn-default" disabled>{this.state.lineItem.status_description}</button>}
                        {showBillButtonAdops && <button type="button" class="btn btn-primary" data-toggle="modal" data-target={'#billLiModal-' + this.state.lineItem.id}>{TextResourceHelper.get('buttonlabel_bill')}</button>}
                        {showBillButtonFinance && <button type="button" onClick={this.confirmBillFinance} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_bill_finance')}</button>}
                        {this.state.lineItem.status === LineItemStatusHelper.pause && (userData.group === SessionConfig.group_ms || userData.allWrite) && <button onClick={this.reactivateLineItem} type="button" class="btn btn-success">{TextResourceHelper.get('buttonlabel_reactivate')}</button>}
                        {showSetImplementedButton && <button onClick={this.setLineItemImplemented} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_set_lineitem_implemented')}</button>}
                        {showExtendButton && <button type="button" class="btn btn-success" data-toggle="modal" data-target={'#extendLiModal-' + this.state.lineItem.id}>{TextResourceHelper.get('buttonlabel_extend')}</button>}
                        {showBillTotalButton && <button onClick={this.billLineItemTotal} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_bill_total')}</button>}
                        {showBillDeliveredButton && <button onClick={this.billLineItemDelivered} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_bill_delivered')}</button>}
                        {showActivateButton && <button onClick={this.activateLineItem} type="button" class="btn btn-primary">{TextResourceHelper.get('activate_line_item')}</button>}
                    </div>
                }

                {/*DELETE CONFIRM MODAL*/}
                {
                    !this.state.selectable &&
                    <div class="modal fade" id={'deleteConfirmModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{TextResourceHelper.get('pagetitle_delete_lineitem')}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {TextResourceHelper.get('label_confirm_delete_lineitem')}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button onClick={this.deleteLineItem} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_delete')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*EDIT CONFIRM MODAL*/}
                {
                    !this.state.selectable &&
                    <div class="modal fade" id={'editConfirmModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    {TextResourceHelper.get('label_confirm_edit_lineitem')}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button onClick={this.setEditView} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_edit')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/****************************************************************************************************/}
                {/*VALIDATE LINE ITEM IMPLEMENTATION MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'validateLiModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label className="w-100 text-center">{TextResourceHelper.get('label_select_adops_validate_implementation')}</label>
                                    <div class="dropdown text-center mt-3">
                                        <button class="btn btn-default dropdown-toggle" type="button" id={'selectAdopsUser-' + this.state.lineItem.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.state.validateAdopsFullName === '' ? TextResourceHelper.get('label_select') : this.state.validateAdopsFullName}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby={'selectAdopsUser-' + this.state.lineItem.id}>
                                            {this.state.adopsOwners.map(adopsOwner =>
                                                userData.email !== adopsOwner.email && <a key={adopsOwner.id} class="dropdown-item cursor-pointer" onClick={this.setValidateAdopsEmail(adopsOwner.email, adopsOwner.first_name + ' ' + adopsOwner.last_name)}>{adopsOwner.first_name + ' ' + adopsOwner.last_name}</a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer text-center">
                                <button onClick={this.sendToValidation} type="button" className={this.state.validateAdopsEmail === '' ? 'btn btn-primary disabled' : 'btn btn-primary'}>{TextResourceHelper.get('buttonlabel_select_notify')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*MODAL CHANGE STATUS DEAL - Cosa de locos*/}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'changeStatusLineItemModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                �Confirma el cambio de estado al line item {this.state.lineItem.id}?
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={this.closeFormModals} class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                <button type="button" onClick={this.onClickChangeStatusAccept} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*BILL LINE ITEM MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'billLiModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label className="w-100">{TextResourceHelper.get('label_select_lineitem_finished_reason')}</label>
                                    <select className="form-control form-control-sm border-radius" value={this.state.finishedDeliveryType} onChange={this.onChangeFinishedDeliveryType} required>
                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                        <option value="overdelivery">Overdelivery</option>
                                        <option value="ok">OK</option>
                                        {(this.havePermission("notificarUnderdelivery") || userData.allWrite) && < option value="underdelivery">Underdelivery</option>}
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label className="w-100">{TextResourceHelper.get('label_add_report_url')}</label>
                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.reportUrl} onChange={this.onChangeReportUrl} required />
                                </div>
                                {
                                    this.state.finishedDeliveryType === 'underdelivery' &&
                                    <div class="form-row mb-2">
                                        <div class="col-sm-6">
                                            <label className="w-100">{TextResourceHelper.get('label_add_imp_views_in_favor')}</label>
                                            <input type="number" class="form-control form-control-sm border-radius" value={this.state.impViewsInFavor} onChange={this.onChangeImpViewsInFavor} onBlur={this.calculateAmountInFavor} />
                                        </div>
                                        <div class="col-sm-6">
                                            <label className="w-100">{TextResourceHelper.get('label_amount_in_favor')}</label>
                                            <input readonly="" type="number" class="form-control form-control-sm border-radius" value={this.state.amountInFavor} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="modal-footer text-center">
                                <button onClick={this.confirmBillAdops} type="button" className={this.state.finishedDeliveryType === '' || this.state.reportUrl === '' ? 'btn btn-primary disabled' : 'btn btn-primary'}>{TextResourceHelper.get('buttonlabel_confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*EXTEND LINE ITEM MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'extendLiModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label className="w-100">{TextResourceHelper.get('label_select_new_end_date')}</label>
                                    <input type="date" className="form-control form-control-sm border-radius" placeholder="" defaultValue={Helpers.formatDate(this.state.extendLineItemDate)} onChange={this.onChangeExtendLineItemDate} required />
                                </div>
                            </div>
                            <div class="modal-footer text-center">
                                <button onClick={this.extendLineItem} type="button" className={this.state.extendLineItemDate === '' || this.state.extendLineItemDate === null ? 'btn btn-primary disabled' : 'btn btn-primary'}>{TextResourceHelper.get('buttonlabel_confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    editView() {
        let userData = SessionHelper.getUserData();

        return (
            <form id={'lineItemEditForm-' + this.state.lineItem.id} className="needs-validation" onSubmit={this.saveLineItem} noValidate>
                <div class="modal-body p-2">
                    <div class="container p-0">
                        <div class="form-group row mb-0">
                            <label class="col-sm-3 col-form-label">
                                {TextResourceHelper.get('label_mediaplan_name')}
                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_mediaplan_name')}</Tooltip>}>
                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </label>
                            <div class="col-sm-9 p-1">
                                <input id={'mediaplanname-' + this.state.lineItem.id} type="text" readonly="" class="form-control-plaintext form-control-sm d-inline-block" value={this.state.lineItem.media_plan_name} style={{ width: '90%' }} />
                                <a onClick={this.copyMediaPlanName} className="cursor-pointer ml-2"><i className="far fa-clone"></i></a>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-3 col-form-label">
                                &nbsp;&nbsp;&nbsp;&nbsp;{TextResourceHelper.get('label_lineitem_name')}
                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_lineitem_name')}</Tooltip>}>
                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </label>
                            <div class="col-sm-9 p-1">
                                <input id={'lineitemname-' + this.state.lineItem.id} type="text" readonly="" class="form-control-plaintext form-control-sm d-inline-block" value={this.state.lineItem.line_item_name} style={{ width: '90%' }} />
                                <a onClick={this.copyLineItemName} className="cursor-pointer ml-2"><i className="far fa-clone"></i></a>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                                &nbsp;&nbsp;&nbsp;&nbsp;{TextResourceHelper.get('label_lineitem_label')}
                                <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </label>
                            <div class="col-sm-9 p-1">
                                <input type="text" class="form-control form-control-sm border-radius d-inline-block" value={this.state.lineItemLabel} onChange={this.onChangeLineItemLabel} style={{ width: '90%' }} onBlur={this.validateLineItem} required />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <div className="text-center">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_investment')}</label>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_purchase_model')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemModeloDeCompraTooltip !== '' ? this.state.lineItemModeloDeCompraTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" defaultValue={this.state.lineItemModeloDeCompra} onChange={this.onChangeLineItemModeloDeCompra} onBlur={this.validateLineItem} required>
                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                        <option value="CPM" selected={this.state.lineItemModeloDeCompra == "CPM"}>CPM</option>
                                        <option value="CPV" selected={this.state.lineItemModeloDeCompra == "CPV"}>CPV</option>
                                        <option value="CPCV" selected={this.state.lineItemModeloDeCompra == "CPCV"}>CPCV</option>
                                    </select>
                                </div>

                                <div class="form-row mb-2">
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className="w-100">
                                                {TextResourceHelper.get('label_sell_rate')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemSellRateTooltip !== '' ? this.state.lineItemSellRateTooltip : ''}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <input type="number" step=".0001" class="form-control form-control-sm border-radius" value={this.state.lineItemSellRate} onChange={this.onChangeLineItemSellRate} onBlur={this.calculateGoal} required />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className="w-100">
                                                {TextResourceHelper.get('label_net_rate')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_net_rate')}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <input readonly="" type="number" step=".0001" class="form-control form-control-sm border-radius" value={this.state.lineItemNetRate} />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_goal_imp_views')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input readonly="" step=".001" type="number" class="form-control form-control-sm border-radius" value={this.state.lineItemGoal} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_bonificacion_imp_views')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input type="number" step="1" class="form-control form-control-sm border-radius" value={this.state.lineItemBonificacion} onChange={this.onChangeLineItemBonificacion} onBlur={this.calculateGoal} required />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_total_goal')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input readonly="" step=".001" type="number" class="form-control form-control-sm border-radius" value={this.state.lineItemTotalGoal} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_investment')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemInversionTooltip !== '' ? this.state.lineItemInversionTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input id={"investment-" + this.state.lineItem.id} type="number" step=".001" class="form-control form-control-sm border-radius" value={this.state.lineItemInversion} onChange={this.onChangeLineItemInversion} onBlur={this.calculateGoal} required />
                                </div>

                                <div class="form-group mb-2">
                                    <div class="form-check">
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_facturacion_mensual')}</Tooltip>}>
                                            <input class="form-check-input" type="checkbox" value={this.state.lineItemFacturacionMensual} onChange={this.onChangeLineItemFacturacionMensual} checked={this.state.lineItemFacturacionMensual === true} />
                                        </OverlayTrigger>
                                        <label class="form-check-label" for="gridCheck">
                                            {TextResourceHelper.get('label_facturacion_mensual')}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm">
                                <div className="text-center">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_targeting')}</label>
                                </div>

                                <div class="form-row mb-2">
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className="w-100">
                                                {TextResourceHelper.get('label_start_date')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFechaInicioTooltip !== '' ? this.state.lineItemFechaInicioTooltip : ''}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <input id={"fechainicio-" + this.state.lineItem.id} type="date" className="form-control form-control-sm border-radius" placeholder="" defaultValue={Helpers.formatDate(this.state.lineItemFechaInicio)} onChange={this.onChangeLineItemFechaInicio} onBlur={this.validateLineItem} required />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className="w-100">
                                                {TextResourceHelper.get('label_end_date')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFechaFinTooltip !== '' ? this.state.lineItemFechaFinTooltip : ''}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <input id={"fechafin-" + this.state.lineItem.id} type="date" className="form-control form-control-sm border-radius" placeholder="" defaultValue={Helpers.formatDate(this.state.lineItemFechaFin)} onChange={this.onChangeLineItemFechaFin} onBlur={this.validateLineItem} required />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_country')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCountryTooltip !== '' ? this.state.lineItemCountryTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <BootstrapSelect
                                        showSearch={true}
                                        showTick={true}
                                        showCountOn={1}
                                        placeholder={TextResourceHelper.get('label_select')}
                                        className="form-control form-control-sm border-radius border-1"
                                        selectStyle="btn-default selectcountry"
                                        options={this.state.lineItemCountryOptions}
                                        onChange={this.onChangeLineItemCountry} required />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_devices')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemDevicesTooltip !== '' ? this.state.lineItemDevicesTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <BootstrapSelect
                                        isMultiSelect={true}
                                        showTick={true}
                                        showCountOn={1}
                                        placeholder={TextResourceHelper.get('label_select')}
                                        className="form-control form-control-sm border-radius border-1"
                                        selectStyle="btn-default selectdevices"
                                        options={this.state.lineItemDevicesOptions}
                                        onChange={this.onChangeLineItemDevices} required />
                                </div>

                                <div class="form-row mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_gender')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemGenderTooltip !== '' ? this.state.lineItemGenderTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <div class="col-sm-6">
                                        <button type="button" className={'btn btn-sm w-100 btnGender ' + (this.state.lineItemGenderBoys ? 'btn-primary' : 'btn-outline-primary')} onClick={this.onChangeLineItemGenderBoys} onBlur={this.validateLineItem}>{TextResourceHelper.get('label_gender_boys')}</button>
                                    </div>
                                    <div class="col-sm-6">
                                        <button type="button" className={'btn btn-sm w-100 btnGender ' + (this.state.lineItemGenderGirls ? 'btn-primary' : 'btn-outline-primary')} onClick={this.onChangeLineItemGenderGirls} onBlur={this.validateLineItem}>{TextResourceHelper.get('label_gender_girls')}</button>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_age')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <InputRange
                                        minValue={0}
                                        maxValue={17}
                                        formatLabel={value => `${value} ` + TextResourceHelper.get('label_years').toLowerCase()}
                                        value={this.state.lineItemAudiences}
                                        onChange={this.onChangeLineItemAudiences} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_categories')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCategoriesTooltip !== '' ? this.state.lineItemCategoriesTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <BootstrapSelect
                                        isMultiSelect={true}
                                        showTick={true}
                                        showCountOn={1}
                                        placeholder={TextResourceHelper.get('label_select')}
                                        className="form-control form-control-sm border-radius border-1"
                                        selectStyle="btn-default selectcategories"
                                        options={this.state.lineItemCategoriesOptions}
                                        onChange={this.onChangeLineItemCategories} required />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_frequency')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFrecuencyTooltip !== '' ? this.state.lineItemFrecuencyTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" defaultValue={this.state.lineItemFrecuency} onChange={this.onChangeLineItemFrecuency} onBlur={this.validateLineItem} required>
                                        <option value="" selected={this.state.lineItemFrecuency == ""}>{TextResourceHelper.get('label_select')}</option>
                                        <option value="1" selected={this.state.lineItemFrecuency == "1"}>1</option>
                                        <option value="2" selected={this.state.lineItemFrecuency == "2"}>2</option>
                                        <option value="3" selected={this.state.lineItemFrecuency == "3"}>3</option>
                                        <option value="4" selected={this.state.lineItemFrecuency == "4"}>4</option>
                                        <option value="5" selected={this.state.lineItemFrecuency == "5"}>5</option>
                                        <option value="6" selected={this.state.lineItemFrecuency == "6"}>6</option>
                                        <option value="7" selected={this.state.lineItemFrecuency == "7"}>7</option>
                                        <option value="8" selected={this.state.lineItemFrecuency == "8"}>8</option>
                                        <option value="9" selected={this.state.lineItemFrecuency == "9"}>9</option>
                                        <option value="10" selected={this.state.lineItemFrecuency == "10"}>10</option>
                                    </select>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_bl_url')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemBlUrl} onChange={this.onChangeLineItemBlUrl} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_wl_url')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItem.lineItemWlUrl} onChange={this.onChangeLineItemWlUrl} />
                                </div>

                            </div>

                            <div class="col-sm">
                                <div className="text-center">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_creativities')}</label>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_format')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFormatTooltip !== '' ? this.state.lineItemFormatTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" defaultValue={this.state.lineItemFormat} onChange={this.onChangeLineItemFormat} required>
                                        <option value="" selected={this.state.lineItemFormat == ''}>{TextResourceHelper.get('label_select')}</option>
                                        {this.state.lineItemFormatOptions.map(format =>
                                            <option key={format.formato} value={format.formato} selected={this.state.lineItemFormat == format.formato}>{format.formato}</option>
                                        )}
                                    </select>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_provider')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCreativeProviderTooltip !== '' ? this.state.lineItemCreativeProviderTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" defaultValue={this.state.lineItemCreativeProvider} onChange={this.onChangeLineItemCreativeProvider} required>
                                        <option value="" selected={this.state.lineItemCreativeProvider == ""}>{TextResourceHelper.get('label_select')}</option>
                                        <option value="Kidscorp" selected={this.state.lineItemCreativeProvider == "Kidscorp"}>Kidscorp</option>
                                        <option value="Anunciante" selected={this.state.lineItemCreativeProvider == "Anunciante"}>Anunciante</option>
                                    </select>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_moat')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemAuditoriaMoatTooltip !== '' ? this.state.lineItemAuditoriaMoatTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" defaultValue={this.state.lineItemAuditoriaMoat} onChange={this.onChangeLineItemAuditoriaMoat} required>
                                        <option value="" selected={this.state.lineItemAuditoriaMoat == ""}>{TextResourceHelper.get('label_select')}</option>
                                        <option value="10% (Standard)" selected={this.state.lineItemAuditoriaMoat == "10% (Standard)"}>10% (Standard)</option>
                                        <option value="100%" selected={this.state.lineItemAuditoriaMoat == "100%"}>100%</option>
                                        <option value="No aplica" selected={this.state.lineItemAuditoriaMoat == "No aplica"}>No aplica</option>
                                    </select>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_link_to_materials')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemLinkAMaterialesTooltip !== '' ? this.state.lineItemLinkAMaterialesTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemLinkAMateriales} onChange={this.onChangeLineItemLinkAMateriales} onBlur={this.validateLineItem} required={userData.group === SessionConfig.group_ms} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_tracking')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemTrackingTooltip !== '' ? this.state.lineItemTrackingTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" defaultValue={this.state.lineItemTracking} onChange={this.onChangeLineItemTracking} onBlur={this.validateLineItem} required={userData.group === SessionConfig.group_ms}>
                                        <option value="" selected={this.state.lineItemTracking == ""}>{TextResourceHelper.get('label_select')}</option>
                                        <option value="No posee" selected={this.state.lineItemTracking == "No posee"}>No posee</option>
                                        <option value="Via UTM" selected={this.state.lineItemTracking == "Via UTM"}>Via UTM</option>
                                        <option value="Via Tags" selected={this.state.lineItemTracking == "Via Tags"}>Via Tags</option>
                                        <option value="Via Trackers" selected={this.state.lineItemTracking == "Via Trackers"}>Via Trackers</option>
                                    </select>
                                </div>

                                <div class="form-group mb-2">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_destination_url')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemUrlDestinoTooltip !== '' ? this.state.lineItemUrlDestinoTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemUrlDestino} onChange={this.onChangeLineItemUrlDestino} onBlur={this.validateLineItem} required={userData.group === SessionConfig.group_ms} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" onClick={this.setReadOnlyView} class="btn btn-default">{TextResourceHelper.get('buttonlabel_cancel')}</button>
                    <button type="submit" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                </div>
            </form>
        );
    }
}

export default LineItemAdsDirect;