import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Helpers from '../../Helpers'
import axios from 'axios';
import $ from 'jquery';
import InputRange from 'react-input-range';
import DealHelper from '../helpers/DealHelper';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TextResourceHelper from '../helpers/TextResourceHelper';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import parse from 'html-react-parser'
import SessionConfig from '../session/SessionConfig';
import SessionHelper from '../helpers/SessionHelper';
import LineItemStatusHelper from '../helpers/LineItemStatusHelper';

export class LineItemAdsDeal extends Component {
    static displayName = LineItemAdsDeal.name;

    constructor(props) {
        super(props);

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.setEditView = this.setEditView.bind(this);
        this.setReadOnlyView = this.setReadOnlyView.bind(this);
        this.saveLineItem = this.saveLineItem.bind(this);
        this.validateLineItem = this.validateLineItem.bind(this);
        this.validateLineItemAdops = this.validateLineItemAdops.bind(this);
        this.setLineItemImplemented = this.setLineItemImplemented.bind(this);
        this.extendLineItem = this.extendLineItem.bind(this);
        this.billLineItemTotal = this.billLineItemTotal.bind(this);
        this.billLineItemDelivered = this.billLineItemDelivered.bind(this);
        this.duplicateLineItem = this.duplicateLineItem.bind(this);
        this.onChangeLineItemLabel = this.onChangeLineItemLabel.bind(this);
        this.onChangeLineItemSellRate = this.onChangeLineItemSellRate.bind(this);
        this.onChangeLineItemInversion = this.onChangeLineItemInversion.bind(this);
        this.onChangeLineItemCountry = this.onChangeLineItemCountry.bind(this);
        this.onChangeLineItemCreativeProvider = this.onChangeLineItemCreativeProvider.bind(this);
        this.onChangeExtendLineItemDate = this.onChangeExtendLineItemDate.bind(this);
        this.copyMediaPlanName = this.copyMediaPlanName.bind(this);
        this.copyLineItemName = this.copyLineItemName.bind(this);
        this.copyNombreCreatividad = this.copyNombreCreatividad.bind(this);
        this.copyLineItem2 = this.copyLineItem2.bind(this);
        this.deleteLineItem = this.deleteLineItem.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.confirmLineItem = this.confirmLineItem.bind(this);
        this.reactivateLineItem = this.reactivateLineItem.bind(this);
        this.implementLineItem = this.implementLineItem.bind(this);
        this.onChangeInconsistencyCheck = this.onChangeInconsistencyCheck.bind(this);
        this.onChangeFinishedDeliveryType = this.onChangeFinishedDeliveryType.bind(this);
        this.onChangeReportUrl = this.onChangeReportUrl.bind(this);
        this.confirmBillAdops = this.confirmBillAdops.bind(this);
        this.onChangeImpViewsInFavor = this.onChangeImpViewsInFavor.bind(this);
        this.calculateAmountInFavor = this.calculateAmountInFavor.bind(this);
        this.confirmBillFinance = this.confirmBillFinance.bind(this);
        this.sendToValidation = this.sendToValidation.bind(this);
        this.state = {
            readOnly: true,
            lineItem: this.props.lineItem,
            reviewCollapsed: true,
            collapsed: true,
            lineItemLabel: this.props.lineItem.label,
            lineItemSellRate: this.props.lineItem.sell_rate,
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemInversion: this.props.lineItem.investment,
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemCountry: this.props.lineItem.pais,
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemCountryOptions: Helpers.getLineItemCountries(this.props.lineItem.pais),
            lineItemCreativeProvider: this.props.lineItem.creative_provider,
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
            lineItemType: this.props.lineItem.line_item_type,
            lineItemNombreCreatividad: this.props.lineItem.nombre_creatividad,
            selected: this.props.selectAll,
            selectable: this.props.selectable === true,
            validateAdopsEmail: '',
            validateAdopsFullName: '',
            adopsOwners: [],
            inconsistentFields: this.props.lineItem.inconsistent_fields,
            finishedDeliveryType: '',
            reportUrl: '',
            impViewsInFavor: null,
            amountInFavor: null,
            mediaPlanOk: this.props.mediaPlanOk,
            extendLineItemDate: null,
            //deal para cambiar estado
            changeStatusLineItemId: 0,
            changeStatusStatus: 0,
            isUserOwner: this.props.isUserOwner,
        };
    }

    componentDidMount() {
        let userData = SessionHelper.getUserData();

        Helpers.setClientValidation();

        // get adops owners
        if (userData.group === SessionConfig.group_adops || userData.allWrite) {
            this.getAdopsOwners();
        }
    }

    componentDidUpdate() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ lineItem: nextProps.lineItem });
    }

    toggleCollapse(e) {
        let _collapsed = this.state.collapsed;
        this.setState({
            collapsed: !_collapsed
        });
    }

    copyMediaPlanName(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.media_plan_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyLineItemName(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.line_item_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyNombreCreatividad(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.nombre_creatividad);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyLineItem2(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.lineItem.line_item_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    onChangeLineItemLabel(e) {
        this.setState({
            lineItemLabel: e.target.value
        });
    }
   
    onChangeLineItemSellRate(e) {
        let netRate = 0;

        this.setState({
            lineItemSellRate: e.target.value === "" ? '' : Helpers.roundNumber(parseFloat(e.target.value), 4),
            lineItemNetRate: Helpers.roundNumber(netRate, 4)
        });
    }
    
    onChangeLineItemInversion(e) {
        this.setState({
            lineItemInversion: e.target.value === "" ? '' : Helpers.roundNumber(parseFloat(e.target.value), 2)
        });
    }
    onChangeLineItemCountry(selectedOptions) {
        let country = '';
        if (selectedOptions.selectedValue && typeof (selectedOptions.selectedValue[0]) !== "undefined")
            country = selectedOptions.selectedValue[0];

        this.setState({
            lineItemCountry: country
        });
    }
    onChangeLineItemCreativeProvider(e) {
        this.setState({
            lineItemCreativeProvider: e.target.value
        });
    }

    onChangeExtendLineItemDate(e) {
        this.setState({
            extendLineItemDate: e.target.value
        });
    }

    onChangeInconsistencyCheck(e) {
        let _inconsistentFields = this.state.inconsistentFields;

        if (e.target.checked) {
            if (!_inconsistentFields.some(x => x === e.target.value))
                _inconsistentFields.push(e.target.value);
        } else {
            if (_inconsistentFields.some(x => x === e.target.value))
                _inconsistentFields.splice(_inconsistentFields.indexOf(e.target.value), 1)
        }

        this.setState({
            inconsistentFields: _inconsistentFields
        });
    }

    onChangeReportUrl(e) {
        this.setState({
            reportUrl: e.target.value
        });
    }

    onChangeFinishedDeliveryType(e) {
        this.setState({
            finishedDeliveryType: e.target.value
        });
    }

    onChangeImpViewsInFavor(e) {
        this.setState({
            impViewsInFavor: e.target.value
        });
    }

    calculateAmountInFavor(e) {
        let _amountInFavor = 0;
        let modeloDeCompra = this.state.lineItemModeloDeCompra;
        let impViewsInFavor = this.state.impViewsInFavor === "" ? 0 : this.state.impViewsInFavor;
        let sellRate = this.state.lineItemSellRate === "" || this.state.lineItemSellRate === null ? 0 : this.state.lineItemSellRate;

        // amount in favor
        if (modeloDeCompra !== null && modeloDeCompra !== "" && sellRate > 0) {
            if (modeloDeCompra === "CPM")
                _amountInFavor = (sellRate * impViewsInFavor) / 1000;
            else if (modeloDeCompra === "CPV" || modeloDeCompra === "CPCV")
                _amountInFavor = (sellRate * impViewsInFavor);
        }

        this.setState({
            amountInFavor: Helpers.roundNumber(_amountInFavor, 3)
        });
    }

    setEditView(e) {
        this.setState({
            readOnly: false
        }, () => {
            Helpers.setFormClientValidation('lineItemEditForm-' + this.state.lineItem.id);
        });

        $('.modal').modal('hide'); // closes all active pop ups.
    }

    setReadOnlyView(e) {
        this.setState({
            readOnly: true
        });
    }

    saveLineItem(e) {
        e.preventDefault();

        const data = {
            id: this.state.lineItem.id,
            deal_id: this.state.lineItem.deal_id,
            label: this.state.lineItemLabel,
            sell_rate: this.state.lineItemSellRate,
            investment: this.state.lineItemInversion,
            pais: this.state.lineItemCountry,
            creative_provider: this.state.lineItemCreativeProvider,
            line_item_type: this.state.lineItemType
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/save', data)
            .then((response) => {
                let form = document.getElementById('lineItemEditForm-' + this.state.lineItem.id);
                form.classList.remove("was-validated");

                this.props.refreshDeal();

                this.setState({
                    readOnly: true,
                });

                let msg = TextResourceHelper.get('successmessage_data_saved');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    validateLineItem(e) {
        let isValid = true;
        this.setState({
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
        });

        $(".selectcountry").removeClass("invalidfield");

        // sell rate
        if (this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
            isValid = false;
            this.setState({ lineItemSellRateValid: false, lineItemSellRateTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // inversion
        if (this.state.lineItemInversion === null || this.state.lineItemInversion === "") {
            isValid = false;
            this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // country
        if (this.state.lineItemCountry === null || this.state.lineItemCountry === "") {
            isValid = false;
            $(".selectcountry").addClass("invalidfield");
            this.setState({ lineItemCountryValid: false, lineItemCountryTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // creative provider
        if (this.state.lineItemCreativeProvider === null || this.state.lineItemCreativeProvider === "") {
            isValid = false;
            this.setState({ lineItemCreativeProviderValid: false, lineItemCreativeProviderTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        var form = document.getElementById('lineItemEditForm-' + this.state.lineItem.id);
        form.checkValidity();
        form.classList.add('was-validated');
    }

    isValidLineItem() {
        let isValid = true;

        // sell rate
        if (this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
            isValid = false;
        }

        // inversion
        if (this.state.lineItemInversion === null || this.state.lineItemInversion === "") {
            isValid = false;
        }

        // country
        if (this.state.lineItemCountry === null || this.state.lineItemCountry === "") {
            isValid = false;
        }

        // creative provider
        if (this.state.lineItemCreativeProvider === null || this.state.lineItemCreativeProvider === "") {
            isValid = false;
        }

        return isValid;
    }

    deleteLineItem(e) {
        e.preventDefault();


        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/delete/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                $('.close').click();
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_deleted'));
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    confirmLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/confirm/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_confirmed'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    reactivateLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/reactivate/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_reactivated'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    getAdopsOwners() {
        var owners = localStorage.getItem("OwnersAdops");
        var result = JSON.parse(owners);
        this.setState({
            adopsOwners: result
        });

    }

    implementLineItem(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/implement/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_implemented'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    sendToValidation(e) {
        e.preventDefault();

        if (!this.state.validateAdopsEmail || this.state.validateAdopsEmail === '')
            return;

        const data = {
            adops_user_email: this.state.validateAdopsEmail
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/sendtovalidation/' + this.state.lineItem.id, data)
            .then((response) => {
                $('#validateLiModal-' + this.state.lineItem.id).modal("hide");
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_sent_validation'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    confirmBillAdops(e) {
        e.preventDefault();

        if (this.state.finishedDeliveryType === '' || this.state.reportUrl === '')
            return;

        const data = {
            finished_delivery_type: this.state.finishedDeliveryType,
            report_url: this.state.reportUrl,
            imp_views_in_favor: this.state.impViewsInFavor,
            amount_in_favor: this.state.amountInFavor
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/adops/bill/' + this.state.lineItem.id, data)
            .then((response) => {
                $('#billLiModal-' + this.state.lineItem.id).modal("hide");
                this.props.refreshDeal();
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_billed'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    confirmBillFinance(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/finance/bill/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_billed'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    validateLineItemAdops(e) {
        e.preventDefault();

        const data = {
            inconsistent_fields: this.state.inconsistentFields
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/validate/' + this.state.lineItem.id, data)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_validated'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    setLineItemImplemented(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/setimplemented/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_set_implemented'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    extendLineItem(e) {
        e.preventDefault();

        if (this.state.extendLineItemDate === '' || this.state.extendLineItemDate === null)
            return;

        const data = {
            new_end_date: this.state.extendLineItemDate
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/extend/' + this.state.lineItem.id, data)
            .then((response) => {
                $('#extendLiModal-' + this.state.lineItem.id).modal("hide");
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_extended'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    billLineItemTotal(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/billtotal/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_bill_total'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    billLineItemDelivered(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/billdelivered/' + this.state.lineItem.id)
            .then((response) => {
                this.props.refreshDeal();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_lineitem_bill_delivered'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    duplicateLineItem(e) {
        e.preventDefault();

        let lineItemIds = [];
        lineItemIds.push(this.state.lineItem.id);

        const data = {
            line_item_ids: lineItemIds
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/' + this.state.lineItem.deal_id + '/lineitems/duplicate', data)
            .then((response) => {
                this.props.refreshDeal();

                let msg = TextResourceHelper.get('infomessage_lineitem_duplicated');
                Helpers.notifyInfo(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    toggleSelected(e) {
        let _selected = e.target.checked;
        this.setState({
            selected: _selected
        });

        this.props.setSelected(this.state.lineItem.id, _selected);
    }

    setValidateAdopsEmail = (email, fullName) => () => {
        this.setState({
            validateAdopsEmail: email,
            validateAdopsFullName: fullName
        })
    }
    getClassNameLineItemStatus(statusId) {

        let statusCssClass = '';

        if (statusId >= 3 && statusId <= 5)
            statusCssClass = 'btn-status-kickoff';
        else if (statusId === 15)
            statusCssClass = 'btn-status-preparation';
        else if (statusId >= 6 && statusId <= 9 || statusId === 16)
            statusCssClass = 'btn-status-implementation';
        else if (statusId === 1)
            statusCssClass = 'btn-status-active';
        else
            statusCssClass = 'btn-status-paused-finished';

        return statusCssClass;

    }
    changeStatusLineItem = (lineItemId, status) => {
        this.setState({
            changeStatusLineItemId: lineItemId,
            changeStatusStatus: status
        });
    }
    onClickChangeStatusAccept(e) {

        axios.post(window.config.REACT_APP_API_URL + '/deal/ChangeStatusLineItem/' + this.state.changeStatusLineItemId, this.state.changeStatusStatus)
            .then(() => {

                $('#changeStatusLineItemModal-' + this.state.changeStatusLineItemId).modal('hide');

                this.setState({
                    changeStatusLineItemId: 0,
                    changeStatusStatus: 0
                });

                this.props.refreshDeal();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    havePermission(namePermission) {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission) {
                result = true;
            }
        });

        return result;
    }

    render() {
        let userData = SessionHelper.getUserData();

        return (
            <div class="card">
                <div class="card-header lineItemName" id="headingOne">
                    <h5 class="mb-0">
                        {this.state.selectable && <input class="" type="checkbox" value="" onChange={this.toggleSelected} checked={this.props.selectAll === true || this.state.selected === true} disabled={this.props.selectAll === true} />}
                        <div onClick={this.toggleCollapse} class="btn-link" style={{ fontSize: '0.9rem', display: 'inline-block', cursor: 'pointer' }} data-toggle="collapse" data-target={'#lineItemCollapse-' + this.state.lineItem.id} aria-expanded="true" aria-controls={'lineItemCollapse-' + this.state.lineItem.id}>
                            <i class={'fa mr-2 ' + (this.state.collapsed ? 'fa-chevron-right' : 'fa-chevron-down')}></i>{this.state.lineItem.line_item_name}
                        </div><div onClick={this.copyLineItem2} class="btn btn-default" style={{}}><i class="far fa-clone"></i></div>
                        {
                            <button type="button" className={"btn btn-default float-right " + this.getClassNameLineItemStatus(this.state.lineItem.status)}>{this.state.lineItem.status_description}</button>
                        }
                    </h5>
                </div>

                {
                    (this.havePermission("verLineItems") || userData.allRead) &&
                    <div id={'lineItemCollapse-' + this.state.lineItem.id} class="collapse" aria-labelledby="headingOne" data-parent={'#lineItemsAccordion-' + this.state.lineItem.deal_id}>
                        <div class="card-body">
                            {this.state.readOnly && this.readOnlyView()}
                            {!this.state.readOnly && this.editView()}
                        </div>
                    </div>
                }
            </div>
            );
    }

    readOnlyView() {
        let today = new Date().toISOString().substring(0, 10) + 'T00:00:00';
        let userData = SessionHelper.getUserData();

        let showValidateImplementationButton = this.havePermission("marcarValidacion")
            && (
                this.state.lineItem.status === LineItemStatusHelper.to_implement
            || (this.state.inconsistentFields.length > 0 && this.state.lineItem.status === LineItemStatusHelper.require_validation)
            )
            && (this.state.lineItem.adops_validator_user_email === null || this.state.lineItem.adops_validator_user_email !== userData.email);

        let canValidateLineItem = (this.havePermission("validarCamposLineItem") || userData.allWrite)
            && this.state.lineItem.status === LineItemStatusHelper.require_validation
            && this.state.lineItem.adops_validator_user_email !== null
            && userData.email === this.state.lineItem.adops_validator_user_email;

        let showInconsistentFields = userData.group === SessionConfig.group_adops
            && this.state.lineItem.status === LineItemStatusHelper.require_validation
            && this.state.inconsistentFields !== null
            && this.state.inconsistentFields.length > 0;

        let showSetImplementedButton = (this.havePermission("marcarImplementado") || userData.allWrite)
            && this.state.lineItem.status === LineItemStatusHelper.validated
            && this.state.lineItem.adops_implementer_user_email === userData.email;

        let showBillButtonAdops = (this.havePermission("enviarLineItemAFacturar") || userData.allWrite)
            && (this.state.lineItem.status === LineItemStatusHelper.active || this.state.lineItem.status === LineItemStatusHelper.finished)
            && this.state.lineItem.fecha_finalizacion <= today;

        let showBillButtonFinance = (this.havePermission("facturarLineItem") || userData.allWrite)
            && (this.state.lineItem.status === LineItemStatusHelper.to_bill || this.state.lineItem.status === LineItemStatusHelper.underdelivery);

        let showConfirmButton = this.isValidLineItem() === true
            && (this.havePermission("confirmarLineItem") || userData.allRead)
            /*&& (this.state.lineItem.deal_status === DealHelper.status_paused || this.state.lineItem.deal_status === DealHelper.status_kick_off || this.state.lineItem.deal_status === DealHelper.status_implementation)*/
            && (this.state.lineItem.status === LineItemStatusHelper.saved || this.state.lineItem.status === LineItemStatusHelper.pause);

        let showEditButton = userData.group === SessionConfig.group_sales
            || (userData.group === SessionConfig.group_ms && (this.state.lineItem.status === LineItemStatusHelper.preparation || this.state.lineItem.status === LineItemStatusHelper.implemented || this.state.lineItem.status === LineItemStatusHelper.pause));

        let showExtendButton = this.state.lineItem.status === LineItemStatusHelper.underdelivery
            && (this.havePermission("extenderFechaUnderdelivery") || userData.allWrite) ;

        let showBillTotalButton = this.state.lineItem.status === LineItemStatusHelper.underdelivery
            && (this.havePermission("enviarLineItemFacturarTotal") || userData.allWrite) ;

        let showBillDeliveredButton = this.state.lineItem.status === LineItemStatusHelper.underdelivery
            && (this.havePermission("enviarLineItemFacturarEntregado") || userData.allWrite) ;

        let showActivateButton = this.state.lineItem.status === LineItemStatusHelper.implemented
            && (this.havePermission("activateLineItem") || userData.allWrite);

        return (
            <div id={'lineItemReadOnlyView-' + this.state.lineItem.id} className="">
                <div class="modal-body p-2">
                    <div class="container p-0">                       
                        <div class="row">
                            <div class="col-sm">
                                <div className="text-center mb-2">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_programatic_deal')}</label>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'nombre_creatividad') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_nombre_creatividad')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="nombre_creatividad" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'nombre_creatividad')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemNombreCreatividad} />
                                    <button onClick={this.copyNombreCreatividad} class="btn btn-default" style={{ position: 'absolute', right: '0', top: '50px' }}><i class="far fa-clone"></i></button>
                                </div>

                                <div class="form-row mb-2">
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'sell_rate') ? 'w-100 inconsistentfield' : 'w-100'}>
                                                {TextResourceHelper.get('label_sell_rate')}
                                                {
                                                    canValidateLineItem &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                        <input class="float-right" type="checkbox" value="sell_rate" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'sell_rate')} />
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <input type="number" step=".001" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemSellRate} />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'investment') ? 'w-100 inconsistentfield' : 'w-100'}>
                                                {TextResourceHelper.get('label_investment')}
                                                {
                                                    canValidateLineItem &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                        <input class="float-right" type="checkbox" value="investment" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'investment')} />
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <input step=".01" type="number" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemInversion} />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'creative_provider') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_provider')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="creative_provider" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'creative_provider')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemCreativeProvider} />
                                </div>

                                <div class="form-group mb-2">
                                    <label className={showInconsistentFields && this.state.inconsistentFields.some(x => x === 'country') ? 'w-100 inconsistentfield' : 'w-100'}>
                                        {TextResourceHelper.get('label_country')}
                                        {
                                            canValidateLineItem &&
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_select_if_inconsistency')}</Tooltip>}>
                                                <input class="float-right" type="checkbox" value="country" onChange={this.onChangeInconsistencyCheck} checked={this.state.inconsistentFields.some(x => x === 'country')} />
                                            </OverlayTrigger>
                                        }
                                    </label>
                                    <input type="text" readonly="" class="form-control-plaintext form-control-sm border-radius font-italic" value={this.state.lineItemCountry} />
                                </div>

                            </div>

                            <div class="col-sm">
                            </div>

                            <div class="col-sm">
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !this.state.selectable &&
                    <div class="modal-footer">
                        {
                            (userData.role == 1) &&
                            <div className="dropdown">
                                <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id={'dropdownMenuButton-' + this.state.lineItem.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {TextResourceHelper.get('change_status')}
                                </button>
                                <div class="dropdown-menu" aria-labelledby={'dropdownMenuButton-' + this.state.lineItem.id}>
                                    <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 3)}>{TextResourceHelper.get('lineitemstatus_saved')}</a>
                                    <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 4)}>{TextResourceHelper.get('lineitemstatus_to_confirm')}</a>
                                    <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 5)}>{TextResourceHelper.get('lineitemstatus_confirmed')}</a>

                                    <a className="dropdown-item status-preparation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 15)}>{TextResourceHelper.get('lineitemstatus_preparation')}</a>

                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 7)}>{TextResourceHelper.get('lineitemstatus_to_implement')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 6)}>{TextResourceHelper.get('lineitemstatus_to_validate')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 8)}>{TextResourceHelper.get('lineitemstatus_require_validation')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 16)}>{TextResourceHelper.get('lineitemstatus_validated')}</a>
                                    <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 9)}>{TextResourceHelper.get('lineitemstatus_implemented')}</a>

                                    <a className="dropdown-item status-active" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 1)}>{TextResourceHelper.get('lineitemstatus_active')}</a>

                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 10)}>{TextResourceHelper.get('lineitemstatus_finished')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 12)}>{TextResourceHelper.get('lineitemstatus_ok')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 11)}>{TextResourceHelper.get('lineitemstatus_overdelivery')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 17)}>{TextResourceHelper.get('lineitemstatus_underdelivery')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 13)}>{TextResourceHelper.get('lineitemstatus_to_bill')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 14)}>{TextResourceHelper.get('lineitemstatus_billed')}</a>
                                    <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusLineItemModal-' + this.state.lineItem.id} onClick={() => this.changeStatusLineItem(this.state.lineItem.id, 2)}>{TextResourceHelper.get('lineitemstatus_pause')}</a>

                                </div>
                            </div>
                        }
                        {(this.havePermission("eliminarLineItem") || userData.allWrite) && <button type="button" class="btn btn-default" data-toggle="modal" data-target={'#deleteConfirmModal-' + this.state.lineItem.id}><i class="fa fa-2x fa-trash"></i></button>}
                        {(this.havePermission("duplicarLineItem") || userData.allWrite) && <button type="button" onClick={this.duplicateLineItem} class="btn btn-default"><i class="far fa-2x fa-clone"></i></button>}
                        {(this.havePermission("editarLineItem") || userData.allWrite) &&  <button type="button" class="btn btn-default" data-toggle="modal" data-target={'#editConfirmModal-' + this.state.lineItem.id}><i class="fa fa-2x fa-edit"></i></button>}
                        {this.state.lineItem.status === LineItemStatusHelper.preparation && (userData.group === SessionConfig.group_ms || userData.allWrite) && <button onClick={this.implementLineItem} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_implement')}</button>}
                        {showValidateImplementationButton && <button type="button" class="btn btn-primary" data-toggle="modal" data-target={'#validateLiModal-' + this.state.lineItem.id}>{TextResourceHelper.get('buttonlabel_validate_implementation')}</button>}
                        {canValidateLineItem && <button onClick={this.validateLineItemAdops} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_validate_lineitem')}</button>}
                        {showConfirmButton && <button type="button" onClick={this.confirmLineItem} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_confirm')}</button>}
                        {this.state.lineItem.status === LineItemStatusHelper.confirmed && <button type="button" class="btn btn-default" disabled>{this.state.lineItem.status_description}</button>}
                        {showBillButtonAdops && <button type="button" class="btn btn-primary" data-toggle="modal" data-target={'#billLiModal-' + this.state.lineItem.id}>{TextResourceHelper.get('buttonlabel_bill')}</button>}
                        {showBillButtonFinance && <button type="button" onClick={this.confirmBillFinance} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_bill_finance')}</button>}
                        {this.state.lineItem.status === LineItemStatusHelper.pause && (userData.group === SessionConfig.group_ms || userData.allWrite) && <button onClick={this.reactivateLineItem} type="button" class="btn btn-success">{TextResourceHelper.get('buttonlabel_reactivate')}</button>}
                        {showSetImplementedButton && <button onClick={this.setLineItemImplemented} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_set_lineitem_implemented')}</button>}
                        {showExtendButton && <button type="button" class="btn btn-success" data-toggle="modal" data-target={'#extendLiModal-' + this.state.lineItem.id}>{TextResourceHelper.get('buttonlabel_extend')}</button>}
                        {showBillTotalButton && <button onClick={this.billLineItemTotal} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_bill_total')}</button>}
                        {showBillDeliveredButton && <button onClick={this.billLineItemDelivered} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_bill_delivered')}</button>}
                        {showActivateButton && <button onClick={this.billLineItemDelivered} type="button" class="btn btn-primary">{TextResourceHelper.get('activate_line_item')}</button>}
                    </div>
                }

                {/*DELETE CONFIRM MODAL*/}
                {
                    !this.state.selectable &&
                    <div class="modal fade" id={'deleteConfirmModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{TextResourceHelper.get('pagetitle_delete_lineitem')}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    {TextResourceHelper.get('label_confirm_delete_lineitem')}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button onClick={this.deleteLineItem} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_delete')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*EDIT CONFIRM MODAL*/}
                {
                    !this.state.selectable &&
                    <div class="modal fade" id={'editConfirmModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    {TextResourceHelper.get('label_confirm_edit_lineitem')}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button onClick={this.setEditView} type="button" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_edit')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/****************************************************************************************************/}
                {/*VALIDATE LINE ITEM IMPLEMENTATION MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'validateLiModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label className="w-100 text-center">{TextResourceHelper.get('label_select_adops_validate_implementation')}</label>
                                    <div class="dropdown text-center mt-3">
                                        <button class="btn btn-default dropdown-toggle" type="button" id={'selectAdopsUser-' + this.state.lineItem.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {this.state.validateAdopsFullName === '' ? TextResourceHelper.get('label_select') : this.state.validateAdopsFullName}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby={'selectAdopsUser-' + this.state.lineItem.id}>
                                            {this.state.adopsOwners.map(adopsOwner =>
                                                userData.email !== adopsOwner.email && <a key={adopsOwner.id} class="dropdown-item cursor-pointer" onClick={this.setValidateAdopsEmail(adopsOwner.email, adopsOwner.first_name + ' ' + adopsOwner.last_name)}>{adopsOwner.first_name + ' ' + adopsOwner.last_name}</a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer text-center">
                                <button onClick={this.sendToValidation} type="button" className={this.state.validateAdopsEmail === '' ? 'btn btn-primary disabled' : 'btn btn-primary'}>{TextResourceHelper.get('buttonlabel_select_notify')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*BILL LINE ITEM MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'billLiModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label className="w-100">{TextResourceHelper.get('label_select_lineitem_finished_reason')}</label>
                                    <select className="form-control form-control-sm border-radius" value={this.state.finishedDeliveryType} onChange={this.onChangeFinishedDeliveryType} required>
                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                        <option value="overdelivery">Overdelivery</option>
                                        <option value="ok">OK</option>
                                        {this.havePermission("notificarUnderdelivery") && < option value="underdelivery">Underdelivery</option>}
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label className="w-100">{TextResourceHelper.get('label_add_report_url')}</label>
                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.reportUrl} onChange={this.onChangeReportUrl} required />
                                </div>
                                {
                                    this.state.finishedDeliveryType === 'underdelivery' &&
                                    <div class="form-row mb-2">
                                        <div class="col-sm-6">
                                            <label className="w-100">{TextResourceHelper.get('label_add_imp_views_in_favor')}</label>
                                            <input type="number" class="form-control form-control-sm border-radius" value={this.state.impViewsInFavor} onChange={this.onChangeImpViewsInFavor} onBlur={this.calculateAmountInFavor} />
                                        </div>
                                        <div class="col-sm-6">
                                            <label className="w-100">{TextResourceHelper.get('label_amount_in_favor')}</label>
                                            <input readonly="" type="number" class="form-control form-control-sm border-radius" value={this.state.amountInFavor} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="modal-footer text-center">
                                <button onClick={this.confirmBillAdops} type="button" className={this.state.finishedDeliveryType === '' || this.state.reportUrl === '' ? 'btn btn-primary disabled' : 'btn btn-primary'}>{TextResourceHelper.get('buttonlabel_confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*EXTEND LINE ITEM MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'extendLiModal-' + this.state.lineItem.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label className="w-100">{TextResourceHelper.get('label_select_new_end_date')}</label>
                                    <input type="date" className="form-control form-control-sm border-radius" placeholder="" defaultValue={Helpers.formatDate(this.state.extendLineItemDate)} onChange={this.onChangeExtendLineItemDate} required />
                                </div>
                            </div>
                            <div class="modal-footer text-center">
                                <button onClick={this.extendLineItem} type="button" className={this.state.extendLineItemDate === '' || this.state.extendLineItemDate === null ? 'btn btn-primary disabled' : 'btn btn-primary'}>{TextResourceHelper.get('buttonlabel_confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    editView() {

        return (
            <form id={'lineItemEditForm-' + this.state.lineItem.id} className="needs-validation" onSubmit={this.saveLineItem} noValidate>
                <div class="modal-body p-2">
                    <div class="container p-0">
                        <div class="form-group row mb-0">
                            <label class="col-sm-3 col-form-label">
                                {TextResourceHelper.get('label_mediaplan_name')}
                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_mediaplan_name')}</Tooltip>}>
                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </label>
                            <div class="col-sm-9 p-1">
                                <input id={'mediaplanname-' + this.state.lineItem.id} type="text" readonly="" class="form-control-plaintext form-control-sm d-inline-block" value={this.state.lineItem.media_plan_name} style={{ width: '90%' }} />
                                <a onClick={this.copyMediaPlanName} className="cursor-pointer ml-2"><i className="far fa-clone"></i></a>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-sm-3 col-form-label">
                                &nbsp;&nbsp;&nbsp;&nbsp;{TextResourceHelper.get('label_lineitem_name')}
                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_lineitem_name')}</Tooltip>}>
                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </label>
                            <div class="col-sm-9 p-1">
                                <input id={'lineitemname-' + this.state.lineItem.id} type="text" readonly="" class="form-control-plaintext form-control-sm d-inline-block" value={this.state.lineItem.line_item_name} style={{ width: '90%' }} />
                                <a onClick={this.copyLineItemName} className="cursor-pointer ml-2"><i className="far fa-clone"></i></a>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                                &nbsp;&nbsp;&nbsp;&nbsp;{TextResourceHelper.get('label_lineitem_label')}
                                <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                </OverlayTrigger>
                            </label>
                            <div class="col-sm-9 p-1">
                                <input type="text" class="form-control form-control-sm border-radius d-inline-block" value={this.state.lineItemLabel} onChange={this.onChangeLineItemLabel} style={{ width: '90%' }} onBlur={this.validateLineItem} required />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <div className="text-center mb-2">
                                    <label className="font-weight-bold">{TextResourceHelper.get('label_programatic_deal')}</label>
                                </div>

                                <div class="form-row mb-4">
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className="w-100">
                                                {TextResourceHelper.get('label_sell_rate')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemSellRateTooltip !== '' ? this.state.lineItemSellRateTooltip : ''}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <input type="number" step=".001" class="form-control form-control-sm border-radius" value={this.state.lineItemSellRate} onChange={this.onChangeLineItemSellRate} onBlur={this.validateLineItem} required />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mb-2">
                                            <label className="w-100">
                                                {TextResourceHelper.get('label_investment')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemInversionTooltip !== '' ? this.state.lineItemInversionTooltip : ''}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <input type="number" step=".01" class="form-control form-control-sm border-radius" value={this.state.lineItemInversion} onChange={this.onChangeLineItemInversion} onBlur={this.validateLineItem} required />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_provider')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCreativeProviderTooltip !== '' ? this.state.lineItemCreativeProviderTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemCreativeProvider} onChange={this.onChangeLineItemCreativeProvider} onBlur={this.validateLineItem} required>
                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                        <option value="Kidscorp">Kidscorp</option>
                                        <option value="Anunciante">Anunciante</option>
                                    </select>
                                </div>

                                <div class="form-group mb-4">
                                    <label className="w-100">
                                        {TextResourceHelper.get('label_country')}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCountryTooltip !== '' ? this.state.lineItemCountryTooltip : ''}</Tooltip>}>
                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                        </OverlayTrigger>
                                    </label>
                                    <BootstrapSelect
                                        showSearch={true}
                                        showTick={true}
                                        showCountOn={1}
                                        placeholder={TextResourceHelper.get('label_select')}
                                        className="form-control form-control-sm border-radius border-1"
                                        selectStyle="btn-default selectcountry"
                                        options={this.state.lineItemCountryOptions}
                                        onChange={this.onChangeLineItemCountry} required />
                                </div>

                                <div class="form-group mb-4">
                                    <button type="submit" class="btn btn-primary w-100">{TextResourceHelper.get('buttonlabel_save')}</button>
                                </div>

                            </div>

                            <div class="col-sm">
                            </div>

                            <div class="col-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" onClick={this.setReadOnlyView} class="btn btn-default">{TextResourceHelper.get('buttonlabel_cancel')}</button>
                    <button type="submit" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                </div>
            </form>
        );
    }
}

export default LineItemAdsDeal;