import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import SessionHelper from '../helpers/SessionHelper'
import logo from '../../kite_logo_small.png';
import $ from 'jquery';
import TextResourceHelper from '../helpers/TextResourceHelper';
import parse from 'html-react-parser'

export class FirstLoginPasswordChange extends Component {
    static displayName = FirstLoginPasswordChange.name;

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);

        this.state = {
            userId: 0,
            email: '',
            username: '',
            lastName: '',
            firstName: '',
            password: '',
            passwordConfirm: '',
            loading: false
        };
    }

    componentDidMount() {
        this.populateData();
        Helpers.setClientValidation();
        $("#password").focus();
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>

                {/*USERNAME*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_username')}</label>
                    <input className="form-control" type="email" name="email" value={this.state.username} disabled />
                </div>
                <div className="form-group col-md-6" >
                </div>

                {/*PASSWORD / PASSWORD CONFIRM*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_password')}</label>
                    <input id="password" className="form-control dark" type="password" name="password" onChange={this.onChangePassword} value={this.state.password} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_invalid_password')}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">Confirmar contrase&ntilde;a</label>
                    <input id="passwordconfirm" className="form-control dark" type="password" name="passwordconfirm" onChange={this.onChangePasswordConfirm} value={this.state.passwordConfirm} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_invalid_password')}</div>
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-12 text-right">
                    <button type="submit" className="btn btn-primary">{TextResourceHelper.get('label_confirm_password')}</button>
                </div>

            </form>;

        return (
            <div className="container mt-5">
                <h2 className="text-info font-weight-bold mb-3">Generar contrase&ntilde;a</h2>
                <div className="card">
                    <div className="card-header">
                        <img src={logo} />
                        <p>{parse(TextResourceHelper.get('label_welcome_message').replace('{fullname}', this.state.lastName + ', ' + this.state.firstName).replace('{username}', this.state.username))}</p>
                    </div>
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangePasswordConfirm(e) {
        this.setState({
            passwordConfirm: e.target.value
        });
    }
    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        if (this.state.password !== this.state.passwordConfirm) {
            Helpers.notifyError(TextResourceHelper.get('label_error'), TextResourceHelper.get('errormessage_passwords_dont_match'));
            return;
        }

        this.setState({ loading: true });

        const data = {
            password: this.state.password
        };

        axios.post(window.config.REACT_APP_API_URL + '/auth/passwordchange/' + this.state.userId, data)
            .then((response) => {
                Helpers.notifySuccess(TextResourceHelper.get('label_password_saved'), TextResourceHelper.get('successmessage_password_saved'));

                // logout & redirect
                SessionHelper.doLogout();
                window.location.replace(window.location.origin);
            })
            .catch((error) => {
                console.log(error);

                let msg = TextResourceHelper.get('errormessage_password_save');

                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        let userData = SessionHelper.getUserData();

        this.setState({
            userId: userData.id,
            email: userData.email,
            username: userData.username,
            lastName: userData.last_name,
            firstName: userData.first_name,
            loading: false
        });
    }
}

export default FirstLoginPasswordChange;
