import Helpers from '../../Helpers'
import SessionConfig from '../session/SessionConfig'

const SessionHelper = {
    setExpiry: function () {
        Helpers.setCookie(SessionConfig.cookieName, SessionConfig.uuid, { path: '/', maxAge: SessionConfig.timeoutInMinutes * 60 });
    },

    resetExpiry: function () {
        Helpers.removeCookie(SessionConfig.cookieName);
        Helpers.setCookie(SessionConfig.cookieName, SessionConfig.uuid, { path: '/', maxAge: SessionConfig.timeoutInMinutes * 60 });
    },

    isExpired: function () {
        return Helpers.getCookieValue(SessionConfig.cookieName) === undefined;
    },

    doLogin: function (userData) {
        this.setExpiry();
        this.saveUserData(userData);
    },

    doLogout: function () {
        Helpers.removeCookie(SessionConfig.cookieName);
        this.clearUserData();
    },

    saveUserData: function (userData) {
        localStorage.setItem("userdata", JSON.stringify(userData));
    },

    clearUserData: function () {
        localStorage.removeItem("userdata");
    },

    getUserData: function () {
        let userData = null;

        if (localStorage.getItem("userdata")) {
            userData = JSON.parse(localStorage.getItem("userdata"));
        }

        return userData;
    },

    getAccessToken: function () {
        let userData = this.getUserData();

        return userData && userData.token;
    },
}

export default SessionHelper;