import $ from 'jquery';
import { store } from 'react-notifications-component';
import axios from 'axios';
import Cookies from 'universal-cookie';
import DataTable from 'datatables.net';
import TextResourceHelper from './components/helpers/TextResourceHelper';
import DealHelper from './components/helpers/DealHelper';

const Helpers = {
    notifySuccess: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    notifyError: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    notifyWarning: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    notifyInfo: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    getAuthorizationToken: async function () {
        
    },

    getUiLanguage: function () {
        
    },

    setUiLanguage: function (key) {
        
    },

    loadLanguageResources: function () {

    },

    getLanguageResources: function () {
        
    },

    showLoadingOverlay: function () {
        $("#loadingOverlay").show();
    },

    hideLoadingOverlay: function () {
        $("#loadingOverlay").fadeOut();
    },

    initDataTable: function () {
        $('.datatable').DataTable({
            "language": {
                "lengthMenu": TextResourceHelper.get('label_datatable_language_lengthmenu'),
                "zeroRecords": TextResourceHelper.get('label_datatable_language_zerorecords'),
                "info": TextResourceHelper.get('label_datatable_language_info'),
                "infoEmpty": TextResourceHelper.get('label_datatable_language_infoempty'),
                "infoFiltered": TextResourceHelper.get('label_datatable_language_infofiltered'),
                "search": TextResourceHelper.get('label_datatable_language_search'),
                "paginate": {
                    "previous": TextResourceHelper.get('label_datatable_language_paginate_previous'),
                    "next": TextResourceHelper.get('label_datatable_language_paginate_next')
                },
                "autoWidth": false
            }
        });
    },

    refreshDataTable: function () {
        $('.datatable').DataTable();
    },

    getDateTimeFromUtc: function (dateUtc) {
        let date = new Date(dateUtc);

        var now = new Date();
        let offfset = now.getTimezoneOffset() * -1;

        date.setMinutes(date.getMinutes() + offfset);

        return date;
    },

    getDisplayDateTimeFromUtc: function (dateUtc) {
        let date = new Date(dateUtc);

        var now = new Date();
        let offfset = now.getTimezoneOffset() * -1;

        date.setMinutes(date.getMinutes() + offfset);

        return date.toLocaleString();
    },

    getDisplayDate: function (date) {
        let _date = new Date(date);

        return _date.toLocaleDateString();
    },

    formatDate: function (inputString) {
        if (typeof inputString === "undefined" || inputString === null || inputString === "")
            return null;

        let date = new Date(inputString);

        return date.toISOString().split('T')[0];
    },

    setNavActive: function (id) {
        $("#sidebar-wrapper .list-group-item").removeClass("active");
        $(id).addClass("active");
    },

    setMainTitle: function (title) {
        $("#mainTitle").html(title);
    },

    toggleSidebar: function () {
        if ($(window).width() <= 990) // mobile
            $("body").toggleClass("sb-sidenav-toggled");
    },

    setClientValidation() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation');

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            });
    },

    setFormClientValidation(formId) {
        var form = document.getElementById(formId);

        form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
            }

            form.classList.add('was-validated')
        }, false);
    },

    setCookie: function (name, value, options) {
        const cookies = new Cookies();

        cookies.set(name, value, options);
    },

    getCookieValue: function (name) {
        const cookies = new Cookies();

        return cookies.get(name);
    },

    removeCookie: function (name) {
        const cookies = new Cookies();

        cookies.remove(name);
    },

    thousandSeparator: function (x) {
        if (!x || x === null)
            return '';

        if (x === 0)
            return '0';

        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getCountryCssClass: function (countryName) {
        if (typeof countryName === 'undefined' || countryName === '' || countryName === null)
            return '';

        var _countryName = countryName.toLowerCase();

        switch (_countryName) {
            case 'argentina': return 'argentina';
            case 'bolivia': return 'bolivia';
            case 'brasil': return 'brazil';
            case 'chile': return 'chile';
            case 'colombia': return 'colombia';
            case 'costa rica': return 'costarica';
            case 'republica dominicana': return 'dominicanrepublic';
            case 'ecuador': return 'ecuador';
            case 'el salvador': return 'elsalvador';
            case 'guatemala': return 'guatemala';
            case 'mexico': return 'mexico';
            case 'nicaragua': return 'nicaragua';
            case 'panamá': return 'panama';
            case 'panama': return 'panama';
            case 'paraguay': return 'paraguay';
            case 'perú': return 'peru';
            case 'peru': return 'peru';
            case 'puerto rico': return 'puertorico';
            case 'united states': return 'unitedstates';
            case 'estados unidos': return 'unitedstates';
            case 'uruguay': return 'uruguay';
            case 'venezuela': return 'venezuela';
        }

        return '';
    },

    getLineItemCategories: function (selectedCategories) {
        if (selectedCategories === null || typeof (selectedCategories) === "undefined")
            selectedCategories = '';

        const categories = [
            { 'labelKey': 'Todas', 'value': 'Todas', 'isSelected': selectedCategories.indexOf('Todas') !== -1 }
            ,{ 'labelKey': 'Acción (AA)', 'value': 'Acción (AA)', 'isSelected': selectedCategories.indexOf('Acción (AA)') !== -1 }
            ,{ 'labelKey': 'Alimentos y Bebidas (AA)', 'value': 'Alimentos y Bebidas (AA)', 'isSelected': selectedCategories.indexOf('Alimentos y Bebidas (AA)') !== -1 }
            ,{ 'labelKey': 'Animales y Mascotas (AA)', 'value': 'Animales y Mascotas (AA)', 'isSelected': selectedCategories.indexOf('Animales y Mascotas (AA)') !== -1 }
            ,{ 'labelKey': 'Arte (AA)', 'value': 'Arte (AA)', 'isSelected': selectedCategories.indexOf('Arte (AA)') !== -1 }
            ,{ 'labelKey': 'Aventura (AA)', 'value': 'Aventura (AA)', 'isSelected': selectedCategories.indexOf('Aventura (AA)') !== -1 }
            ,{ 'labelKey': 'Belleza y Moda (AA)', 'value': 'Belleza y Moda (AA)', 'isSelected': selectedCategories.indexOf('Belleza y Moda (AA)') !== -1 }
            ,{ 'labelKey': 'Carreras (AA)', 'value': 'Carreras (AA)', 'isSelected': selectedCategories.indexOf('Carreras (AA)') !== -1 }
            ,{ 'labelKey': 'Deportes (AA)', 'value': 'Deportes (AA)', 'isSelected': selectedCategories.indexOf('Deportes (AA)') !== -1 }
            ,{ 'labelKey': 'Educación y Ciencias (AA)', 'value': 'Educación y Ciencias (AA)', 'isSelected': selectedCategories.indexOf('Educación y Ciencias (AA)') !== -1 }
            ,{ 'labelKey': 'Estrategia y Habilidad (AA)', 'value': 'Estrategia y Habilidad (AA)', 'isSelected': selectedCategories.indexOf('Estrategia y Habilidad (AA)') !== -1 }
            ,{ 'labelKey': 'Juguetes (AA)', 'value': 'Juguetes (AA)', 'isSelected': selectedCategories.indexOf('Juguetes (AA)') !== -1 }
            ,{ 'labelKey': 'Role Play (AA)', 'value': 'Role Play (AA)', 'isSelected': selectedCategories.indexOf('Role Play (AA)') !== -1 }
            ,{ 'labelKey': 'Música (AA)', 'value': 'Música (AA)', 'isSelected': selectedCategories.indexOf('Música (AA)') !== -1 }
            ,{ 'labelKey': 'Películas (AA)', 'value': 'Películas (AA)', 'isSelected': selectedCategories.indexOf('Películas (AA)') !== -1 }
            ,{ 'labelKey': 'TV Shows (AA)', 'value': 'TV Shows (AA)', 'isSelected': selectedCategories.indexOf('TV Shows (AA)') !== -1 }
            ,{ 'labelKey': 'Action/Adventure (KSV)', 'value': 'Action/Adventure (KSV)', 'isSelected': selectedCategories.indexOf('Action/Adventure (KSV)') !== -1 }
            ,{ 'labelKey': 'Animals (KSV)', 'value': 'Animals (KSV)', 'isSelected': selectedCategories.indexOf('Animals (KSV)') !== -1 }
            ,{ 'labelKey': 'Animation Adventure (KSV)', 'value': 'Animation Adventure (KSV)', 'isSelected': selectedCategories.indexOf('Animation Adventure (KSV)') !== -1 }
            ,{ 'labelKey': 'Arts/Crafts/DIY (KSV)', 'value': 'Arts/Crafts/DIY (KSV)', 'isSelected': selectedCategories.indexOf('Arts/Crafts/DIY (KSV)') !== -1 }
            ,{ 'labelKey': 'Beauty and Make Up (KSV)', 'value': 'Beauty and Make Up (KSV)', 'isSelected': selectedCategories.indexOf('Beauty and Make Up (KSV)') !== -1 }
            ,{ 'labelKey': 'Builders/Engineering (KSV)', 'value': 'Builders/Engineering (KSV)', 'isSelected': selectedCategories.indexOf('Builders/Engineering (KSV)') !== -1 }
            ,{ 'labelKey': 'Cars and Trucks (KSV)', 'value': 'Cars and Trucks (KSV)', 'isSelected': selectedCategories.indexOf('Cars and Trucks (KSV)') !== -1 }
            ,{ 'labelKey': 'Cartoons (KSV)', 'value': 'Cartoons (KSV)', 'isSelected': selectedCategories.indexOf('Cartoons (KSV)') !== -1 }
            ,{ 'labelKey': 'Christmas (KSV)', 'value': 'Christmas (KSV)', 'isSelected': selectedCategories.indexOf('Christmas (KSV)') !== -1 }
            ,{ 'labelKey': 'Claymation (KSV)', 'value': 'Claymation (KSV)', 'isSelected': selectedCategories.indexOf('Claymation (KSV)') !== -1 }
            ,{ 'labelKey': 'Comedy (KSV)', 'value': 'Comedy (KSV)', 'isSelected': selectedCategories.indexOf('Comedy (KSV)') !== -1 }
            ,{ 'labelKey': 'Dolls (KSV)', 'value': 'Dolls (KSV)', 'isSelected': selectedCategories.indexOf('Dolls (KSV)') !== -1 }
            ,{ 'labelKey': 'Education/Learning/Sciences (KSV)', 'value': 'Education/Learning/Sciences (KSV)', 'isSelected': selectedCategories.indexOf('Education/Learning/Sciences (KSV)') !== -1 }
            ,{ 'labelKey': 'Entertainment (KSV)', 'value': 'Entertainment (KSV)', 'isSelected': selectedCategories.indexOf('Entertainment (KSV)') !== -1 }
            ,{ 'labelKey': 'Family Play (KSV)', 'value': 'Family Play (KSV)', 'isSelected': selectedCategories.indexOf('Family Play (KSV)') !== -1 }
            ,{ 'labelKey': 'Fashion (KSV)', 'value': 'Fashion (KSV)', 'isSelected': selectedCategories.indexOf('Fashion (KSV)') !== -1 }
            ,{ 'labelKey': 'Film/Theatre/Movies (KSV)', 'value': 'Film/Theatre/Movies (KSV)', 'isSelected': selectedCategories.indexOf('Film/Theatre/Movies (KSV)') !== -1 }
            ,{ 'labelKey': 'Fitness/Lifestyle (KSV)', 'value': 'Fitness/Lifestyle (KSV)', 'isSelected': selectedCategories.indexOf('Fitness/Lifestyle (KSV)') !== -1 }
            ,{ 'labelKey': 'Food (KSV)', 'value': 'Food (KSV)', 'isSelected': selectedCategories.indexOf('Food (KSV)') !== -1 }
            ,{ 'labelKey': 'Gaming (KSV)', 'value': 'Gaming (KSV)', 'isSelected': selectedCategories.indexOf('Gaming (KSV)') !== -1 }
            ,{ 'labelKey': 'Girls (KSV)', 'value': 'Girls (KSV)', 'isSelected': selectedCategories.indexOf('Girls (KSV)') !== -1 }
            ,{ 'labelKey': 'Halloween (KSV)', 'value': 'Halloween (KSV)', 'isSelected': selectedCategories.indexOf('Halloween (KSV)') !== -1 }
            ,{ 'labelKey': 'Holidays (KSV)', 'value': 'Holidays (KSV)', 'isSelected': selectedCategories.indexOf('Holidays (KSV)') !== -1 }
            ,{ 'labelKey': 'Influencer (KSV)', 'value': 'Influencer (KSV)', 'isSelected': selectedCategories.indexOf('Influencer (KSV)') !== -1 }
            ,{ 'labelKey': 'Music (KSV)', 'value': 'Music (KSV)', 'isSelected': selectedCategories.indexOf('Music (KSV)') !== -1 }
            ,{ 'labelKey': 'Mythical/Magical (KSV)', 'value': 'Mythical/Magical (KSV)', 'isSelected': selectedCategories.indexOf('Mythical/Magical (KSV)') !== -1 }
            ,{ 'labelKey': 'Nursery Rhymes (KSV)', 'value': 'Nursery Rhymes (KSV)', 'isSelected': selectedCategories.indexOf('Nursery Rhymes (KSV)') !== -1 }
            ,{ 'labelKey': 'Other (KSV)', 'value': 'Other (KSV)', 'isSelected': selectedCategories.indexOf('Other (KSV)') !== -1 }
            ,{ 'labelKey': 'PRANKS & CHALLENGES (KSV)', 'value': 'PRANKS & CHALLENGES (KSV)', 'isSelected': selectedCategories.indexOf('PRANKS & CHALLENGES (KSV)') !== -1 }
            ,{ 'labelKey': 'Princess (KSV)', 'value': 'Princess (KSV)', 'isSelected': selectedCategories.indexOf('Princess (KSV)') !== -1 }
            ,{ 'labelKey': 'Puzzles & Strategy (KSV)', 'value': 'Puzzles & Strategy (KSV)', 'isSelected': selectedCategories.indexOf('Puzzles & Strategy (KSV)') !== -1 }
            ,{ 'labelKey': 'Sports (KSV)', 'value': 'Sports (KSV)', 'isSelected': selectedCategories.indexOf('Sports (KSV)') !== -1 }
            ,{ 'labelKey': 'Stopmotion (KSV)', 'value': 'Stopmotion (KSV)', 'isSelected': selectedCategories.indexOf('Stopmotion (KSV)') !== -1 }
            ,{ 'labelKey': 'Storytelling (KSV)', 'value': 'Storytelling (KSV)', 'isSelected': selectedCategories.indexOf('Storytelling (KSV)') !== -1 }
            ,{ 'labelKey': 'Super Hero (KSV)', 'value': 'Super Hero (KSV)', 'isSelected': selectedCategories.indexOf('Super Hero (KSV)') !== -1 }
            ,{ 'labelKey': 'Toy Reviews and Unboxing (KSV)', 'value': 'Toy Reviews and Unboxing (KSV)', 'isSelected': selectedCategories.indexOf('Toy Reviews and Unboxing (KSV)') !== -1 }
            ,{ 'labelKey': 'Toys and Play (KSV)', 'value': 'Toys and Play (KSV)', 'isSelected': selectedCategories.indexOf('Toys and Play (KSV)') !== -1 }
            ,{ 'labelKey': 'Travel/Holiday (KSV)', 'value': 'Travel/Holiday (KSV)', 'isSelected': selectedCategories.indexOf('Travel/Holiday (KSV)') !== -1 }
            ,{ 'labelKey': 'TV & Network Shows (KSV)', 'value': 'TV & Network Shows (KSV)', 'isSelected': selectedCategories.indexOf('TV & Network Shows (KSV)') !== -1 }
        ];

        return categories;
    },

    getLineItemDevices: function (selectedDevices) {
        if (selectedDevices === null || typeof (selectedDevices) === "undefined")
            selectedDevices = '';

        const devices = [
            {
                "labelKey": "Mobile App/Web",
                "value": "Mobile App/Web",
                "isSelected": selectedDevices.indexOf("Mobile App/Web") !== -1
            },
            {
                "labelKey": "Desktop",
                "value": "Desktop",
                "isSelected": selectedDevices.indexOf("Desktop") !== -1
            },
            {
                "labelKey": "Tablet",
                "value": "Tablet",
                "isSelected": selectedDevices.indexOf("Tablet") !== -1
            },
            {
                "labelKey": "Connected TV",
                "value": "Connected TV",
                "isSelected": selectedDevices.indexOf("Connected TV") !== -1
            }
        ];

        return devices;
    },

    getLineItemCountries: function (selectedCountry) {

        if (selectedCountry === null || typeof (selectedCountry) === "undefined" || selectedCountry == '')
            selectedCountry = 'Seleccionar...';

        var countries = localStorage.getItem("countries");

        countries = JSON.parse(countries);
        var countriesStore = [];

        countries.forEach(country => {
            countriesStore.push({ "labelKey": country.labelKey, "value": country.value, "isSelected": selectedCountry === country.value })
        });

        countries = countriesStore;

        return countries;
    },
    loadCountryCache: function () {

        var countries = [];

        axios.get(window.config.REACT_APP_API_URL + '/Countries/getCountries')
            .then(response => {

                response.data.data.forEach(country => {
                    countries.push({ "labelKey": country.labelKey, "value": country.value, "isSelected": false })
                });
                var countryCopy = countries;

                localStorage.setItem("countries", JSON.stringify(countryCopy));

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    roundNumber: function (num, places) {
        let _places = 100; // 2 places

        if (places === 3) // 3 places
            _places = 1000;

        if (places === 4) // 4 places
            _places = 10000;

        return Math.round((num + Number.EPSILON) * _places) / _places;
    },

    closeModals: function () {
        $('.modal').modal('hide'); // closes all active pop ups.
        $('.modal-backdrop').remove(); // removes the grey overlay.
    },

    getDealStatusCssClass: function (status) {
        if (typeof status === 'undefined' || status === '' || status === null)
            return '';

        let statusCssClass = '';
        if (status === DealHelper.status_kick_off)
            statusCssClass = 'status-kickoff';
        else if (status === DealHelper.status_preparation)
            statusCssClass = 'status-preparation';
        else if (status === DealHelper.status_implementation)
            statusCssClass = 'status-implementation';
        else if (status === DealHelper.status_active)
            statusCssClass = 'status-active';
        else if (status === DealHelper.status_paused || status === DealHelper.status_finished || status === DealHelper.status_to_bill || status === DealHelper.status_billed)
            statusCssClass = 'status-paused-finished';

        return statusCssClass;
    },

    loadModelosDeCompra: function () {
        let baseUrl = window.config.REACT_APP_API_URL + '/deal/kcbenchmarks/get?modeloDeCompra=';
        axios.get(baseUrl + "CPM")
            .then(response => {
                const data = response.data.data;
                localStorage.setItem("ModelosDeCompra_CPM", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(baseUrl + "CPV")
            .then(response => {
                const data = response.data.data;
                localStorage.setItem("ModelosDeCompra_CPV", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(baseUrl + "CPCV")
            .then(response => {
                const data = response.data.data;
                localStorage.setItem("ModelosDeCompra_CPCV", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });

    },
    getModelosDeCompra: function (modeloDeCompra) {

        var storageKey = "ModelosDeCompra_" + modeloDeCompra.toUpperCase();
        var modelos = localStorage.getItem(storageKey);
        return JSON.parse(modelos);
    },
}

export default Helpers;