import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import TextResourceHelper from '../helpers/TextResourceHelper';
import logoPlus from '../../plus.png';
import logoEdit from '../../edit.png';
import logoDelete from '../../delete.png';
import SessionHelper from '../helpers/SessionHelper';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export class UserEdit extends Component {
    static displayName = UserEdit.name;

    constructor(props) {
        super(props);

        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        //check permisos
        this.toggleSelectCategories = this.toggleSelectCategories.bind(this);
        this.toggleSelectPermision = this.toggleSelectPermision.bind(this);
        this.saveOrEditSubGroup = this.saveOrEditSubGroup.bind(this);
        this.deleteSubGroup = this.deleteSubGroup.bind(this);
        this.onChangeSubGroupName = this.onChangeSubGroupName.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.onChangeSubGroup = this.onChangeSubGroup.bind(this);
        this.selectedSubGroupEdit = this.selectedSubGroupEdit.bind(this);
        this.onSubmitModalSubGroup = this.onSubmitModalSubGroup.bind(this);
        this.toggleSelectOwnerPermission = this.toggleSelectOwnerPermission.bind(this);
        this.toggleSelectAllRead = this.toggleSelectAllRead.bind(this);
        this.toggleSelectAllWrite = this.toggleSelectAllWrite.bind(this);
        

        this.state = {
            id: 0,
            lastName: null,
            firstName: null,
            email: null,
            groupId: null,
            subgroupId: null,
            roleId: null,
            languageKey: null,
            allRead: false,
            allWrite: false,
            editSubGroup: false,
            roles: [],
            groups: [],
            subgroups: [],
            //lista de todas las categorias con todos los permisos en la base
            categories: [],
            //lista de todos los permisos que tiene un subgrupo(al mostrarlos en la pantalla principal)
            categoriesSubGroup: [],
            permissionWithCategory: [],
            languages: [],
            subGroupName: '',
            //lista de permisos que se selecciono para un subgrupo (al momento del alta/edit)
            selectedPermissions: [],
            ownerPermissions: [],
            selectedOwnerPermissions: []
        };
    }

    componentDidMount() {
        Helpers.setNavActive("#nav-users");
        this.populateData();
        Helpers.setClientValidation();
        this.getAllCategories();
    }

    havePermission(namePermission) {

        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission) {
                result = true;
            }
        });

        let haveOwnerPermission = this.isOwner();

        return result || haveOwnerPermission;
    }
    isOwner() {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == "allRead" || value == "allWrite") {
                result = true;
            }
        });

        return result;
    }
    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>

                {/*LAST NAME / FIRST NAME*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_lastname')}</label>
                    <input className="form-control dark" type="text" name="lastname" onChange={this.onChangeLastName} value={this.state.lastName} required />
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_firstname')}</label>
                    <input className="form-control dark" type="text" name="firstname" onChange={this.onChangeFirstName} value={this.state.firstName} required />
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>

                {/*EMAIL / LANGUAGE */}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_email')}</label>
                    <input className="form-control dark" type="email" name="email" onChange={this.onChangeEmail} value={this.state.email} required />
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_invalid_email')}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_language')}</label>
                    <select className="form-control dark" data-val="true" name="role" defaultValue={this.state.languageId} onChange={this.onChangeLanguage} required>
                        <option value="">{TextResourceHelper.get('label_select')}</option>
                        {this.state.languages.map(lang =>
                            <option key={lang.id} value={lang.key} selected={this.state.languageKey == lang.key}>{TextResourceHelper.get('label_' + lang.key)}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>

                {/*ROLE / GROUP*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_role')}</label>
                    <select className="form-control dark" data-val="true" name="role" defaultValue={this.state.roleId} onChange={this.onChangeRole} required>
                        <option value="">{TextResourceHelper.get('label_select')}</option>
                        {this.state.roles.map(role =>
                            <option key={role.id} value={role.id} selected={this.state.roleId == role.id}>{role.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_group')}</label>
                    <select className="form-control dark" data-val="true" name="group" defaultValue={this.state.groupId} onChange={this.onChangeGroup} required>
                        <option value="">{TextResourceHelper.get('label_select')}</option>
                        {this.state.groups.map(group =>
                            <option key={group.id} value={group.id} selected={this.state.groupId == group.id}>{group.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>

                {/*SUBGROUP*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{TextResourceHelper.get('label_subgroup')}</label>
                    {this.havePermission("agregarSubGrupos") && <img className="ml-15" src={logoPlus} alt="" data-toggle="modal" data-target='#subGroupModal' />}
                    {this.havePermission("editarSubGrupos") && !(this.state.subgroupId == 0 || this.state.subgroupId == null) && <img className="ml-15" src={logoEdit} alt="" data-toggle="modal" data-target='#subGroupModal' onClick={this.selectedSubGroupEdit} />}
                    {this.havePermission("eliminarSubGrupos") && !(this.state.subgroupId == 0 || this.state.subgroupId == null) && <img className="ml-15" src={logoDelete} alt="" onClick={this.deleteSubGroup} />}

                    <select className="form-control dark" data-val="true" name="subgroup" defaultValue={this.state.subgroupId} onChange={this.onChangeSubGroup} required>
                        <option value="">{TextResourceHelper.get('label_select')}</option>
                        {this.state.subgroups.map(subgroup =>
                            <option key={subgroup.id} value={subgroup.id} selected={this.state.subgroupId == subgroup.id}>{subgroup.description}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>

                {/*OWNER PERMISSION*/}
                <div className="form-group col-md-6 display-flex-space-evenly">
                    <div>
                        <input className="check-2" id="chkOwnerAllRead" type="checkbox" checked={this.state.allRead} onChange={this.toggleSelectAllRead}/>
                        <label class="form-check-label ml-15">{TextResourceHelper.get('text_allRead')}</label>
                    </div>

                    <div>
                        <input className="check-2" id="chkOwnerAllWrite" type="checkbox" checked={this.state.allWrite} onChange={this.toggleSelectAllWrite}/>
                        <label class="form-check-label ml-15">{TextResourceHelper.get('text_allWrite')}</label>
                    </div>
                </div>

                {/*{!(this.state.subgroupId == 0 || this.state.subgroupId == null) && userData.allWrite &&*/}
                {/*    <div className="form-group col-md-6 display-flex-space-evenly">*/}
                {/*        {this.state.ownerPermissions.map((ownerPermission) => {*/}
                {/*            return (*/}
                {/*                ownerPermission.permissions.map((permission) => {*/}
                {/*                    return (*/}
                {/*                        <div>*/}
                {/*                            <input className="check-2" id={"chkOwner-" + permission.id} type="checkbox" value={permission.id} onChange={this.toggleSelectOwnerPermission} />*/}
                {/*                            <label class="form-check-label ml-15">{permission.description}</label>*/}
                {/*                        </div>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            )*/}
                {/*        })}*/}
                {/*    </div>*/}
                {/*}*/}
                
                <div className="form-group col-md-6 ">
                    <div id="accordion">
                        {this.state.permissionWithCategory.map((category) => {
                            return (
                                <div className="card">
                                    <div className="card-header" id={"heading" + category.id} data-toggle="collapse" data-target={"#collapse" + category.id} aria-expanded="true" aria-controls={"collapse" + category.id}>
                                        <div className="display-flex-baseline">
                                            <p className="ml-30"> <b>Categoria:</b> {category.description}</p>
                                        </div>
                                    </div>

                                    <div id={"collapse" + category.id} className="collapse" aria-labelledby={"heading" + category.id} data-parent="#accordion">
                                        <div className="card-body">
                                            {category.permissions.map((permission) => {
                                                return (
                                                    <div className="ml-30 display-flex-aling-center">
                                                        <p className="ml-30 mb-0" key={permission.id}> <b>Permiso:</b> {permission.description}</p>
                                                        <a className="tooltip-alert-trigger" id={'messagePermission-' + permission.id}>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{TextResourceHelper.get('label_' + permission.internalCode)}</Tooltip>}>
                                                                <div className="mediaplan-permission ml-1 cursor-pointer">
                                                                    <h6>
                                                                        <i className="fa fa-question-circle"></i>
                                                                    </h6>
                                                                </div>
                                                            </OverlayTrigger>
                                                        </a>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-md-6 text-right" >
                    <button type="submit" className="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{TextResourceHelper.get('buttonlabel_cancel')}</button>
                </div>

            </form>;

        return (
            <div>
                <h2 className="text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_users')}</h2>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? Helpers.showLoadingOverlay() : Helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>

                {/*EDIT CONFIRM MODAL*/}
                <div className="modal fade" id='subGroupModal' tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <form className="needs-validationSubGroup" onSubmit={this.onSubmitModalSubGroup}>
                                <div className="modal-body">
                                    <label className="w-100">{TextResourceHelper.get('label_subgroup')}</label>
                                    <input type="text" placeholder={TextResourceHelper.get('label_subgroup_placeholder')} className="form-control form-control-sm border-radius mb-15" value={this.state.subGroupName} onChange={this.onChangeSubGroupName} required />
                                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>

                                    <div id="accordion">
                                        {this.state.categories.map((category) => {
                                            return (
                                                <div className="card">
                                                    <div className="card-header" id={"heading-" + category.id} data-toggle="collapse" data-target={"#collapse-" + category.id} aria-expanded="true" aria-controls={"collapse-" + category.id}>
                                                        <div className="display-flex-baseline">
                                                            <input className="check-2" id={"chkCategories" + category.id} type="checkbox" value={category.id} onChange={this.toggleSelectCategories} />
                                                            <p className="ml-30"> <b> {category.description} </b></p>
                                                        </div>
                                                    </div>

                                                    <div id={"collapse-" + category.id} className="collapse" aria-labelledby={"heading-" + category.id} data-parent="#accordion">
                                                        <div className="card-body">
                                                            {category.permissions.map((permission) => {
                                                                return (
                                                                    <div className="ml-30 display-flex-aling-center">
                                                                        <input className="check-2" id={"chkPermission" + permission.id} value={permission.id} className="form-check-input" type="checkbox" onChange={this.toggleSelectPermision} />
                                                                        <p className="ml-30 mb-0" key={permission.id}>{permission.description}</p>
                                                                        <a className="tooltip-alert-trigger" id={'messagePermission-' + permission.id}>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{TextResourceHelper.get('label_' + permission.internalCode)}</Tooltip>}>
                                                                                <div className="mediaplan-permission ml-1 cursor-pointer">
                                                                                    <h6>
                                                                                        <i className="fa fa-question-circle"></i>
                                                                                    </h6>
                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button id="closeSubGroupModal" type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.closeEdit}>{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button type="submit" className="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    toggleSelectOwnerPermission(e) {

        //Asignamos el valor de la lista que ya tiene los valores para guardar a la base
        let permisosSeleccionadas = this.state.selectedOwnerPermissions;
        
        //Lo mismo que con las categorias pero con un IF de mas que solo agrega la permiso especifico
        if (e.target.checked) {

            //agregamos la lista 
            this.state.ownerPermissions.map(category => {
                category.permissions.map(permission => {
                    //Solo agregamos el permiso seleccionado
                    if (permission.id == e.target.value)
                        permisosSeleccionadas.push(permission);
                });
            });

        }
        //aca, recorremos la lista de todos los permisos que estan seleccionados y quitamos el corresponde
        else {
            for (var i = 0; i < permisosSeleccionadas.length; i++) {
                if (permisosSeleccionadas[i].id == e.target.value) {
                    permisosSeleccionadas.splice(i, 1);
                }
            }
        }

        //le damos el nuevo valor a la lista
        this.setState({
            selectedOwnerPermissions: permisosSeleccionadas
        });

    }
    toggleSelectCategories(e) {

        //Asignamos el valor de la lista que ya tiene los valores para guardar a la base
        let permisosSeleccionadas = this.state.selectedPermissions;

        //si lo esta checkeando, recorremos la lista para agregarlo a la lista de las categorias seleccionadas
        if (e.target.checked) {
            //agregamos la lista 
            this.state.categories.map(category => {
                //comparamos los IDs para agregar el que se selecciono
                if (category.id == e.target.value) {
                    category.permissions.map(permission => {
                        //En el caso que haya seleccionado de permiso en permiso y despues selecciono toda la categoria, evitamos cargar de nuevo los permisos que ya fueron seleccionados
                        if (!permisosSeleccionadas.includes(permission)) {
                            permisosSeleccionadas.push(permission);
                            //checkeamos los checks de los permisos
                            document.getElementById('chkPermission' + permission.id).checked = true;
                        }
                    });
                }
            });
        } else {
            //En caso que el check fue deseleccionado, quitamos los permisos que son de esa categoria
            for (var i = 0; i < this.state.categories.length; i++) {
                if (this.state.categories[i].id == e.target.value) {
                    for (var j = 0; j < this.state.categories[i].permissions.length; j++) {
                        for (var k = 0; k < permisosSeleccionadas.length; k++)
                            if (this.state.categories[i].permissions[j].id == permisosSeleccionadas[k].id) {
                                permisosSeleccionadas.splice(k, 1);
                                //checkeamos los checks de los permisos
                                document.getElementById('chkPermission' + this.state.categories[i].permissions[j].id).checked = false;
                            }
                    }
                }
            }
        }

        //le damos el nuevo valor a la lista
        this.setState({
            selectedPermissions: permisosSeleccionadas
        });
    }
    toggleSelectAllWrite() {
        this.setState({
            allWrite: !this.state.allWrite
        });

        console.log(this.state.allWrite);
    }
    toggleSelectAllRead() {
        this.setState({
            allRead: !this.state.allRead
        });

        console.log(this.state.allRead);
    }
    validateModalSubGroup() {

        if (this.state.subGroupName == '')
            return true;

        return false;
    }
    selectedSubGroupEdit(e) {

        this.setState({
            editSubGroup: true
        });

        //seteamos el nombre del subgrupo
        this.state.subgroups.map(subgroup => {
            if (subgroup.id == this.state.subgroupId) {
                this.setState({
                    subGroupName: subgroup.description
                });
            }
        });

        //checkeamos los check con los permisos que tenga el subgrupo
        this.state.categoriesSubGroup.map(category => {
            var check = document.getElementById('chkPermission' + category.id);

            if (check != null)
                check.checked = true;
        });

        //obtenemos los permisos seteados para el subgrupo
        axios.get(window.config.REACT_APP_API_URL + '/permissions/getpermissionsbysubgroup/' + this.state.subgroupId)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    selectedPermissions: data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    toggleSelectPermision(e) {

        //Asignamos el valor de la lista que ya tiene los valores ppara guardar a la base
        let permisosSeleccionadas = this.state.selectedPermissions;

        //Lo mismo que con las categorias pero con un IF de mas que solo agrega la permiso especifico
        if (e.target.checked) {

            //agregamos la lista 
            this.state.categories.map(category => {
                category.permissions.map(permission => {
                    //Solo agregamos el permiso seleccionado
                    if (permission.id == e.target.value)
                        permisosSeleccionadas.push(permission);
                });
            });

        }
        //aca, recorremos la lista de todos los permisos que estan seleccionados y quitamos el corresponde
        else {
            for (var i = 0; i < permisosSeleccionadas.length; i++) {
                if (permisosSeleccionadas[i].id == e.target.value) {
                    permisosSeleccionadas.splice(i, 1);
                }
            }
        }

        //le damos el nuevo valor a la lista
        this.setState({
            selectedPermissions: permisosSeleccionadas
        });
    }
    createListCategoriesPermissions() {

        var allCategories = [];
        var havePermission = false;

        //traemos de nuevo todos los permisos con sus categorias
        axios.get(window.config.REACT_APP_API_URL + '/permissions/getallcategories')
            .then(response => {
                const data = response.data.data;
                allCategories = data;

                //recorremos la lista cargada y comparamos con la lista de permisos que ya tiene
                for (var i = 0; i < allCategories.length; i++) {
                    for (var j = 0; j < allCategories[i].permissions.length; j++) {
                        for (var k = 0; k < this.state.categoriesSubGroup.length; k++) {
                            if (allCategories[i].permissions[j].id == this.state.categoriesSubGroup[k].id) {
                                havePermission = true;
                            }
                        }

                        //si no tiene el permiso, se lo quitamos de la lista que tiene las categorias
                        if (!havePermission) {
                            allCategories[i].permissions.splice(j, 1);
                            j--;//retrocedemos un indice para que recorra el nuevo 
                        }
                        //reiniciamos el flag
                        havePermission = false;
                    }
                }

                //al limpiartodos los permisos, algunas categorias quedaron sin permisos, asi que limpiamos esas categorias.
                for (i = 0; i < allCategories.length; i++) {
                    if (allCategories[i].permissions.length == 0) {
                        allCategories.splice(i, 1);
                        i--;
                    }
                }

                //seteamos la lista para mostrarla 
                this.setState({
                    permissionWithCategory: allCategories
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    saveOwnerPermission() {

        //Creamos un objeto del tipo que espera la API
        let subGroup = {
            id: this.state.subgroupId,
            permissions: this.state.selectedOwnerPermissions
        };

        //llamamos al endpoint correspondiente
        axios.post(window.config.REACT_APP_API_URL + '/permissions/addOwnerPermissionSubGroup', subGroup)
            .then(response => {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    saveOrEditSubGroup(e) {

        //Creamos un objeto del tipo que espera la API
        let subGroup = {
            id: this.state.editSubGroup ? this.state.subgroupId : 0,
            description: this.state.subGroupName,
            userGroupId: this.state.groupId,
            permissions: this.state.selectedPermissions
        };

        //llamamos al endpoint correspondiente
        axios.post(window.config.REACT_APP_API_URL + '/permissions/savesubgroup', subGroup)
            .then(response => {

                //Una vez guardado el subgrupo, refrescamos la lista para que se vea en tu respectivo control
                this.getSubGroup(this.state.groupId);

                //limpiamos los controlles
                this.cleanEditSubGroup();

                //cerramos el modal
                document.getElementById('closeSubGroupModal').click();

                //una vez guardado, le decimos que ya no esta en modo edicion
                this.setState({
                    editSubGroup: false
                });

                //refrescamos la lista de permisos
                this.getPermissionBySubGroup(this.state.subgroupId);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    //Metodo que se usaba para limpiar los controlles en la edicion/alta de un subgrupo
    cleanEditSubGroup() {

        if (this != null) {
            //limpiamos el nombre del subgrupo
            this.setState({
                subGroupName: ''
            });

            //recorremos los controles de los check y los ponemos todos FALSE
            this.state.categories.map(category => {
                document.getElementById('chkCategories' + category.id).checked = false;

                category.permissions.map(permission => {
                    document.getElementById('chkPermission' + permission.id).checked = false;
                });
            });

            //limpiamos tambien la lista de los permisos seleccionados
            this.setState({
                selectedPermissions : []
            });

            this.setState({
                editSubGroup: false
            });
        }
    }
    closeEdit(e) {
        this.cleanEditSubGroup();
    }
    deleteSubGroup(e){
        axios.post(window.config.REACT_APP_API_URL + '/permissions/deleteSubGroup/' + this.state.subgroupId, null)
            .then(response => {
                //una vez eliminado, refrescamos la lista
                this.getSubGroup(this.state.groupId);
                //limpiamos tambien la lista de los permisos seleccionados
                this.setState({
                    categoriesSubGroup: [],
                    permissionWithCategory: []
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onChangeSubGroupName(e) {
        this.setState({
            subGroupName: e.target.value
        });
    }
    onChangeLastName(e) {
        this.setState({
            lastName: e.target.value
        });
    }
    onChangeFirstName(e) {
        this.setState({
            firstName: e.target.value
        });
    }
    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }
    onChangeGroup(e) {
        this.setState({
            groupId: e.target.value
        });

        //volvemos nulo el subgroup
        this.setState({
            subgroupId: null
        });

        //seteamos el select de subgrupos
        this.getSubGroup(e.target.value);

        //limpiamos tambien la lista de los permisos
        this.setState({
            selectedPermissions: [],
            categoriesSubGroup: [],
            permissionWithCategory: []
        });
    }
    onChangeSubGroup(e) {
        //este if es para controlar el evento del cambio de grupos en su respectivo select
        if (e != null && e.target != null && this != null) {
            this.setState({
                subgroupId: e.target.value
            });

            if (e.target.value != 0 && e.target.value != null) {
                console.log("cambiaron los permisos del owner", e.target.value)
                this.getPermissionBySubGroup(e.target.value);
            } else {
                //limpiamos tambien la lista de los permisos seleccionados
                this.setState({
                    categoriesSubGroup: [],
                    permissionWithCategory: []
                });
            }
        }
    }
    checkAndFillOwnerPermission() {

        //Asignamos el valor de la lista que ya tiene los valores para guardar a la base
        let permisosSeleccionadas = this.state.categoriesSubGroup;
        let permisosOwner = this.state.ownerPermissions;

        this.setState({
            selectedOwnerPermissions: [],
        });

        permisosOwner[0].permissions.map(ownerP => {
            document.getElementById('chkOwner-' + ownerP.id).checked = false;
        });

        console.log(permisosSeleccionadas);
        console.log(permisosOwner[0].permissions);

        permisosSeleccionadas.map(permiso => {
            permisosOwner[0].permissions.map(ownerP => {
                if (ownerP.id == permiso.id) {

                    this.state.selectedOwnerPermissions.push(ownerP);
                    document.getElementById('chkOwner-' + ownerP.id).checked = true;
                }
            });
        });

    }
    onChangeRole(e) {
        this.setState({
            roleId: e.target.value
        });
    }
    onChangeLanguage(e) {
        this.setState({
            languageKey: e.target.value
        });
    }
    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/users");
    }
    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];
        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            last_name: this.state.lastName,
            first_name: this.state.firstName,
            email: this.state.email,
            group_id: this.state.groupId,
            role_id: this.state.roleId,
            allRead: this.state.allRead,
            allWrite: this.state.allWrite,
            language_key: this.state.languageKey,
            SubGroupId: this.state.subgroupId
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_API_URL + '/user/save', data, {
            headers: headers
        })
            .then((response) => {

                //guardamos los permisos de Owners que se editaron
                this.saveOwnerPermission();

                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_data_saved'));
                this.props.history.push('/users');
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');

                if (error.response.data.error_code == window.errorCodes.duplicated_user_email)
                    msg = TextResourceHelper.get('errormessage_duplicated_email');

                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }
    //obtenemos los permisos seteados para el subgrupo
    async getPermissionBySubGroup(subGroupId) {
        axios.get(window.config.REACT_APP_API_URL + '/permissions/getpermissionsbysubgroup/' + subGroupId)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    categoriesSubGroup: data
                });

                this.createListCategoriesPermissions();
                this.checkAndFillOwnerPermission();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async onSubmitModalSubGroup(e) {

        e.preventDefault();

        var form = document.querySelectorAll('.needs-validationSubGroup')[0];
        if (!form.checkValidity())
            return;

        this.saveOrEditSubGroup();
    }
    async getSubGroup(groupId) {

        axios.get(window.config.REACT_APP_API_URL + '/permissions/getsubgroups/' + groupId)
            .then(response => {
                const data = response.data.data;
                console.log(data);
                this.setState({ subgroups: data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getAllCategories() {

        axios.get(window.config.REACT_APP_API_URL + '/permissions/getallcategories')
            .then(response => {
                const data = response.data.data;
                this.setState({ categories: data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        // get languages
        axios.get(window.config.REACT_APP_API_URL + '/textresource/getlanguages')
            .then(response => {
                const data = response.data.data;
                this.setState({ languages: data });
            })
            .catch(function (error) {
                console.log(error);
            });

        // get roles
        axios.get(window.config.REACT_APP_API_URL + '/user/roles/get')
            .then(response => {
                const data = response.data.data;
                this.setState({ roles: data });
            })
            .catch(function (error) {
                console.log(error);
            });

        // get groups
        axios.get(window.config.REACT_APP_API_URL + '/user/groups/get')
            .then(response => {
                const data = response.data.data;
                this.setState({ groups: data });
            })
            .catch(function (error) {
                console.log(error);
            });

        // get Owner permissions
        axios.get(window.config.REACT_APP_API_URL + '/permissions/getOwnerCategories')
            .then(response => {
                const data = response.data.data;
                this.setState({ ownerPermissions: data });
            })
            .catch(function (error) {
                console.log(error);
            });

        // get user data
        axios.get(window.config.REACT_APP_API_URL + '/user/getbyid/' + this.props.match.params.id)
            .then(response => {
                const data = response.data.data;

                console.log("esta es la data");
                console.log(data);

                this.setState({
                    id: data.id,
                    lastName: data.last_name,
                    firstName: data.first_name,
                    email: data.email,
                    allRead: data.AllRead,
                    allWrite: data.AllWrite,
                    roleId: data.role_id,
                    groupId: data.group_id,
                    subgroupId: data.SubGroupId,
                    languageKey: data.language_key,
                    loading: false
                });

                //Una vez seteado el objeto, traemos la lista de los subgrupos del grupo del usuario
                this.getSubGroup(data.group_id);

                //obtenemos los permisos que tiene seteado los subgrupos
                this.getPermissionBySubGroup(data.SubGroupId);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
