import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import TextResourceHelper from '../helpers/TextResourceHelper';
import SessionHelper from '../helpers/SessionHelper';
import SessionConfig from '../session/SessionConfig';
import MediaPlan from './MediaPlan';

export class DealDetail extends Component {
    static displayName = DealDetail.name;

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            item: null
        };
    }

    componentDidMount() {
        Helpers.closeModals();
        Helpers.setNavActive("#nav-mediaplanner");
        Helpers.setMainTitle("Media Plan");
        this.populateData();
    }

    render() {

        if (this.state.item && this.state.item !== null) {
            let userData = SessionHelper.getUserData();

            return <div className="mt-4">
                <MediaPlan deal={this.state.item} />
            </div>;
        } 

        return <div></div>;
    }

    populateData() {
        axios.get(window.config.REACT_APP_API_URL + '/deal/getbydealid/' + this.props.match.params.id)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    item: data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
