import { UserManager, WebStorageStateStore } from 'oidc-client';
import { ApplicationPaths, ApplicationName } from './ApiAuthorizationConstants';
import Helpers from '../../Helpers';
import SessionHelper from '../helpers/SessionHelper';
import axios from 'axios';

export class AuthorizeService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _user = null;
    _isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    async isAuthenticated() {
        if (SessionHelper.isExpired())
            return false;

        const user = await this.getUser();
        return !!user;
    }

    async forcePasswordChange() {
        const user = await this.getUser();
        return !!user && user.first_login;
    }

    async getUser() {
        if (this._user) {
            return this._user;
        }

        const user = SessionHelper.getUserData();

        return user;
    }

    async getAccessToken() {
        //await this.ensureUserManagerInitialized();
        //const user = await this.userManager.getUser();
        //return user && user.access_token;
        return '';
    }

    async signIn(username, password) {
        const data = {
            username: username,
            password: password
        };

        let loginResponse = await axios.post(window.config.REACT_APP_API_URL + '/auth/login', data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response.data;
            });

        if (loginResponse.success === true)
            this.updateState(loginResponse.data);

        return loginResponse;
    }

    async completeSignIn(url) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager.signinCallback(url);
            this.updateState(user);
            return this.success(user && user.state);
        } catch (error) {
            console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in.');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state) {
        await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error('Popup disabled. Change \'AuthorizeService.js:AuthorizeService._popupDisabled\' to false to enable it.')
            }

            await this.userManager.signoutPopup(this.createArguments());
            this.updateState(undefined);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            try {
                await this.userManager.signoutRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectSignOutError) {
                console.log("Redirect signout error: ", redirectSignOutError);
                return this.error(redirectSignOutError);
            }
        }
    }

    async completeSignOut(url) {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager.signoutCallback(url);
            this.updateState(null);
            return this.success(response && response.data);
        } catch (error) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
    }

    updateState(user) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureUserManagerInitialized() {
        return;
    }

    setInterceptors = () => {
        //axios.defaults.headers.common['Token'] = localStorage.getItem("token");
        //axios.defaults.headers.common['Device'] = "device";

        axios.interceptors.response.use(response => {
            Helpers.hideLoadingOverlay();
            return response;
        }, async function (err) {
                const originalReq = err.config;
                Helpers.hideLoadingOverlay();
                if (err.response && err.response.status && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                    originalReq._retry = true;

                    //if (err.response.data.error_code === window.errorCodes.ExpiredToken) {
                    //    let userData = helpers.getUserData();
                    //    let apiName = helpers.getApiName(originalReq.url);
                    //    let key = helpers.getApiKey(apiName);

                    //    let response = await fetch(window.config.REACT_APP_SECURITY_API_URL + '/auth/getnewtoken/' + apiName + '/' + userData.id + '?key=' + encodeURIComponent(key), {
                    //        method: 'GET',
                    //    }).then(res => res.json()).then(res => {

                    //        helpers.refreshAuthorizationToken(apiName, res.data);

                    //        originalReq.headers['Authorization'] = key + ':' + res.data.token;

                    //        return axios(originalReq);
                    //    });

                    //    return Promise.resolve(response);
                    //} else {
                    //    let langResources = helpers.getLanguageResources();

                    //    helpers.notifyWarning(langResources.Label_AccessDenied, langResources.ErrorMessage_InvalidAuthorizatonToken);
                    //}
                }

                return Promise.reject(err);
        });

        axios.interceptors.request.use(
            async config => {
                Helpers.showLoadingOverlay();

                if (config.url.indexOf("/auth/login") !== -1
                    || config.url.indexOf("/textresource/getbylanguage") !== -1
                    || config.url.indexOf("/textresource/getlanguages") !== -1                ) {
                    if (config.method.toLowerCase() === "post") {
                        config.headers = {
                            'Content-Type': 'application/json'
                        };
                    }
                } else {
                    if (SessionHelper.isExpired()) {
                        // Logout
                        SessionHelper.doLogout();

                        window.location.replace(window.location.origin + "/login");
                    } else {
                        if (config.method.toLowerCase() === "post") {
                            config.headers = {
                                'Authorization': `Bearer ${SessionHelper.getAccessToken()}`,
                                'Content-Type': 'application/json'
                            };
                        } else if (config.method.toLowerCase() === "get") {
                            config.headers = {
                                'Authorization': `Bearer ${SessionHelper.getAccessToken()}`
                            };
                        }

                        // reset the expiry
                        SessionHelper.resetExpiry();
                    }
                }

                return config;
            },
            error => {
                Promise.reject(error)
            });
    };

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
