import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import logo from '../kite_logo_small.png';
import profileAvatar from '../profile_avatar.png';
import iconBell from '../icn_bell.png';
import SessionHelper from './helpers/SessionHelper';
import SessionConfig from './session/SessionConfig';
import Helpers from '../Helpers';
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';
import TextResourceHelper from './helpers/TextResourceHelper';
import axios from 'axios';
import $ from 'jquery';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

    constructor (props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            languages: [],
            unreadNotifications: []
        };
    }

    componentDidMount() {
        this.getLanguages();
        this.getUnreadNotifications();
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleSidebar() {
        $("body").toggleClass("sb-sidenav-toggled");
    }

    logout = () => () => {
        Helpers.showLoadingOverlay();
        SessionHelper.doLogout();
        return window.location.replace(this.getReturnUrl());
    }

    changeLanguage = (key) => () => {
        TextResourceHelper.changeLanguage(key);
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.Login}`;
    }

    getLanguages() {
        axios.get(window.config.REACT_APP_API_URL + '/textresource/getlanguages')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    languages: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getUnreadNotifications() {
        let userData = SessionHelper.getUserData();

        axios.get(window.config.REACT_APP_API_URL + '/notification/' + userData.id + '/get/unread')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    unreadNotifications: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        let userData = SessionHelper.getUserData();

        return (
            <div class="d-flex" id="wrapper">
                <div class="border-end bg-white" id="sidebar-wrapper">
                    <div class="sidebar-heading border-bottom bg-light">Start Bootstrap</div>
                    <div class="list-group list-group-flush">
                        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Dashboard</a>
                        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Shortcuts</a>
                        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Overview</a>
                        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Events</a>
                        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Profile</a>
                        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!">Status</a>
                    </div>
                </div>

            <div id="page-content-wrapper">
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3" light>
                        <Container>
                            <button onClick={this.toggleSidebar} class="btn btn-default" id="sidebarToggle"><i className="fa fa-bars"></i></button>
                <NavbarBrand tag={Link} to="/"><img src={logo} style={{ width: '100%' }} /></NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
                  <ul className="navbar-nav flex-grow">
                    <NavItem id="nav-deals">
                        {(userData.group === SessionConfig.group_ms || userData.allWrite) && <NavLink tag={Link} className="text-dark" to="/ms-deals">{TextResourceHelper.get('navitem_search_deal_id')}</NavLink>}
                        {(userData.group === SessionConfig.group_sales || userData.allWrite) && <NavLink tag={Link} className="text-dark" to="/sales-deals">{TextResourceHelper.get('navitem_search_deal_id')}</NavLink>}
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/">{TextResourceHelper.get('navitem_media_map')}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/">{TextResourceHelper.get('navitem_change_history')}</NavLink>
                    </NavItem>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle text-dark" href="#" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-globe"></i> {TextResourceHelper.get('label_' + TextResourceHelper.getUiLanguage())}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="languageDropdown">
                            {this.state.languages.map(lang =>
                                lang.key !== TextResourceHelper.getUiLanguage() && <a key={lang.key} className="dropdown-item" href="#" onClick={this.changeLanguage(lang.key)}>{TextResourceHelper.get('label_' + lang.key)}</a>
                            )}
                        </div>
                    </li>
                    <NavItem id="nav-logout">
                        <NavLink onClick={this.logout()} className="text-dark cursor-pointer">{TextResourceHelper.get('navitem_logout')}</NavLink>
                    </NavItem>
                  </ul>
                </Collapse>
                <div className="d-sm-inline-flex flex-sm-row-reverse profile-avatar-cont dropdown">
                    <div className="profile-avatar cursor-pointer" style={{ backgroundImage: 'url(' + profileAvatar + ')' }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </div>
                    <Link to="/notifications" className="">
                        <div className="icn-bell" style={{ backgroundImage: 'url(' + iconBell + ')' }}>
                            {this.state.unreadNotifications.length > 0 && <span id="notificationBadge" className="badge">{this.state.unreadNotifications.length}</span>}
                        </div>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right text-right" aria-labelledby="dropdownMenuButton">
                        <h6 className="dropdown-header">{userData.role_description}</h6>
                        {userData.role === SessionConfig.role_superuser && <Link className="dropdown-item" to="/users">{TextResourceHelper.get('navitem_users')}</Link>}
                        {userData.role === SessionConfig.role_superuser && <Link className="dropdown-item" to="/textresources">{TextResourceHelper.get('navitem_textresources')}</Link>}
                        <div className="dropdown-divider"></div>
                        <a onClick={this.logout()} className="dropdown-item" href="#">{TextResourceHelper.get('navitem_logout')}</a>
                    </div>
                </div>
              </Container>
                    </Navbar>
                </div>
          </div>
        );
    }
}
