import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import $ from 'jquery';
import MediaPlan from './MediaPlan'
import DealHelper from '../helpers/DealHelper';
import TextResourceHelper from '../helpers/TextResourceHelper';
import SessionConfig from '../session/SessionConfig';
import SessionHelper from '../helpers/SessionHelper';
import LineItemStatusHelper from '../helpers/LineItemStatusHelper';

export class MediaPlanner extends Component {
    static displayName = MediaPlanner.name;

    constructor(props) {
        super(props);

        this.goToPrevPage = this.goToPrevPage.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
        this.onSubmitSearch = this.onSubmitSearch.bind(this);
        this.onChangeInputSearch = this.onChangeInputSearch.bind(this);
        this.onChangeFilterDimension = this.onChangeFilterDimension.bind(this);
        this.onChangeHsOwner = this.onChangeHsOwner.bind(this);
        this.onChangeHsPipeline = this.onChangeHsPipeline.bind(this);
        this.onChangeHsAdvertiser = this.onChangeHsAdvertiser.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeLineItemStatus = this.onChangeLineItemStatus.bind(this);
        this.onChangeHsCampaign = this.onChangeHsCampaign.bind(this);
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this);
        this.onChangeDateTo = this.onChangeDateTo.bind(this);
        this.setFilterStatusKickOff = this.setFilterStatusKickOff.bind(this);
        this.setFilterStatusPreparation = this.setFilterStatusPreparation.bind(this);
        this.setFilterStatusImplementation = this.setFilterStatusImplementation.bind(this);
        this.setFilterStatusActive = this.setFilterStatusActive.bind(this);
        this.setFilterStatusPausedFinished = this.setFilterStatusPausedFinished.bind(this);

        // default date from/to
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        this.state = {
            results: [],
            inputSearchId: 0,
            currentPage: 1,
            pageSize: 10,
            pageCount: 0,
            rowCount: 0,
            owners: [],
            msOwners: [],
            adopsOwners: [],
            salesOwners: [],
            filterOwnerEmail: '',
            filterPipelineId: '',
            filterAdvertiser: '',
            filterCampaign: '',
            hs_pipelines: [],
            hs_advertisers: [],
            hs_campaigns: [],
            filterDateFrom: '',//firstDay.toISOString().split('T')[0],
            filterDateTo: '', //lastDay.toISOString().split('T')[0],
            filterDimension: '',
            filterStatus: [],
            filterLineItemStatus: -1,
            loading: true
        };
    }

    componentDidMount() {
        Helpers.loadCountryCache();
        Helpers.loadModelosDeCompra();

        Helpers.setNavActive("#nav-mediaplanner");
        Helpers.setMainTitle(TextResourceHelper.get('pagetitle_mediaplanner'));

        //Poner aca el getSalesOwners
        this.getSalesOwners();
        this.getMsOwners();
        this.getAdopsOwners();

        this.populateFilters();
        this.populateData();
    }

    goToPrevPage(e) {
        e.preventDefault();
        let prevPage = this.state.currentPage - 1;
        this.setState({ currentPage: prevPage }, () => {
            this.populateData();
        });
    }

    goToNextPage(e) {
        e.preventDefault();
        let nextPage = this.state.currentPage + 1;
        this.setState({ currentPage: nextPage }, () => {
            this.populateData();
        });
    }

    async onSubmitSearch(e) {
        e.preventDefault();

        this.setState({ currentPage: 1, filterStatus: [] }, () => {
            this.populateData();
        });
    }

    onChangeInputSearch(e) {
        this.setState({
            inputSearchId: e.target.value
        });
    }

    onChangeFilterDimension(e) {
        this.setState({
            filterDimension: e.target.value
        });

        this.setState({
            filterDimension: e.target.value,
            filterOwnerEmail: '',
            filterStatus: [],
            filterLineItemStatus: -1,
            filterPipelineId: '',
            filterAdvertiser: '',
            filterCampaign: '',
        }, () => {
            this.populateData();
        });
    }

    onChangeHsOwner(e) {
        this.setState({ filterOwnerEmail: e.target.value, filterStatus: [] }, () => {
            this.populateData();
        });
    }

    onChangeHsPipeline(e) {
        this.setState({ filterPipelineId: e.target.value, filterStatus: [] }, () => {
            this.populateData();
        });
    }

    onChangeHsAdvertiser(e) {
        this.setState({ filterAdvertiser: e.target.value, filterStatus: [] }, () => {
            this.populateData();
        });
    }

    onChangeStatus(e) {
        let status = [];

        if (e.target.value && e.target.value !== "")
            status.push(e.target.value);

        this.setState({ filterStatus: status }, () => {
            this.populateData();
        });
    }

    onChangeLineItemStatus(e) {
        this.setState({ filterLineItemStatus: e.target.value, filterStatus: [] }, () => {
            this.populateData();
        });
    }

    onChangeHsCampaign(e) {
        this.setState({ filterCampaign: e.target.value, filterStatus: [] }, () => {
            this.populateData();
        });
    }

    onChangeDateFrom(e) {
        this.setState({ filterDateFrom: e.target.value }, () => {
            this.populateData();
        });
    }

    onChangeDateTo(e) {
        this.setState({ filterDateTo: e.target.value }, () => {
            this.populateData();
        });
    }

    setFilterStatusKickOff(e) {
        let status = [];
        status.push(DealHelper.status_kick_off);

        this.setState({
            currentPage: 1,
            filterStatus: status
        }, () => {
            this.populateData();
        });
    }

    setFilterStatusPreparation(e) {
        let status = [];
        status.push(DealHelper.status_preparation);

        this.setState({
            currentPage: 1,
            filterStatus: status
        }, () => {
            this.populateData();
        });
    }

    setFilterStatusImplementation(e) {
        let status = [];
        status.push(DealHelper.status_implementation);

        this.setState({
            currentPage: 1,
            filterStatus: status
        }, () => {
            this.populateData();
        });
    }

    setFilterStatusActive(e) {
        let status = [];
        status.push(DealHelper.status_active);

        this.setState({
            currentPage: 1,
            filterStatus: status
        }, () => {
            this.populateData();
        });
    }

    setFilterStatusPausedFinished(e) {
        let status = [];
        status.push(DealHelper.status_paused);
        status.push(DealHelper.status_finished);

        this.setState({
            currentPage: 1,
            filterStatus: status
        }, () => {
            this.populateData();
        });
    }

    getMsOwners() {
        // hs owners
        axios.get(window.config.REACT_APP_API_URL + '/deal/owners/get?groupId=' + SessionConfig.group_ms)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    msOwners: data
                });
                localStorage.setItem("OwnersMs", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getAdopsOwners() {
        // adops owners
        axios.get(window.config.REACT_APP_API_URL + '/deal/owners/get?groupId=' + SessionConfig.group_adops)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    adopsOwners: data
                });
                localStorage.setItem("OwnersAdops", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getSalesOwners() {
        // sales owners
        axios.get(window.config.REACT_APP_API_URL + '/deal/owners/get?groupId=' + SessionConfig.group_sales)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    salesOwners: data
                });
                localStorage.setItem("OwnersSales", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    havePermission(namePermission) {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission)
                result = true;
        });

        return result;
    }
    isOwner() {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == "allRead" || value == "allWrite")
                result = true;
        });

        return result;
    }
    render() {
        let userData = SessionHelper.getUserData();

        return (
            <div>
                <div className="mt-4">
                    {(this.havePermission('buscador') || userData.allRead) &&
                        <form className="" onSubmit={this.onSubmitSearch}>
                            <div className="mb-2 text-right">
                                <i className="fa fa-search mr-2"></i>
                                    <input type="number" onChange={this.onChangeInputSearch} placeholder={TextResourceHelper.get('label_deal_id')} className="form-control-sm border-radius border-none dark" id="inputSearchByHubspotId" />
                            </div>
                        </form>
                    }
                   
                    <div className="mb-4 mt-4 text-center">
                        <button onClick={this.setFilterStatusKickOff} type="button" className="btn btn-default status-kickoff">{TextResourceHelper.get('label_status_kickoff')}</button>
                        <div className="mediaplanner-button-arrow"><i className="fa fa-arrow-right ml-4 mr-4"></i></div>
                        <button onClick={this.setFilterStatusPreparation} type="button" className="btn btn-default status-preparation">{TextResourceHelper.get('label_status_preparation')}</button>
                        <div className="mediaplanner-button-arrow"><i className="fa fa-arrow-right ml-4 mr-4"></i></div>
                        <button onClick={this.setFilterStatusImplementation} type="button" className="btn btn-default status-implementation">{TextResourceHelper.get('label_status_implementation')}</button>
                        <div className="mediaplanner-button-arrow"><i className="fa fa-arrow-right ml-4 mr-4"></i></div>
                        <button onClick={this.setFilterStatusActive} type="button" className="btn btn-default status-active">{TextResourceHelper.get('label_status_active')}</button>
                        <div className="mediaplanner-button-arrow"><i className="fa fa-arrow-right ml-4 mr-4"></i></div>
                        <button onClick={this.setFilterStatusPausedFinished} type="button" className="btn btn-default status-paused-finished">{TextResourceHelper.get('label_status_paused_finished')}</button>
                    </div>

                    <form className="mt-4 mb-2 row">
                        <div className="col-md-6">
                            <i className="fa fa-filter mr-2"></i>
                            <select onChange={this.onChangeFilterDimension} className="form-control-sm border-radius dark" style={{ width: '200px' }}>
                                <option value="" selected>{TextResourceHelper.get('label_select_dimension')}</option>
                                <option value="market">{TextResourceHelper.get('label_dimension_market')}</option>
                                <option value="owner">{TextResourceHelper.get('label_owner')}</option>
                                <option value="status">{TextResourceHelper.get('label_dimension_status')}</option>
                                {(userData.group === SessionConfig.group_finance || userData.allWrite) && <option value="listatus">{TextResourceHelper.get('label_dimension_lineitem_status')}</option>}
                                <option value="advertiser">{TextResourceHelper.get('label_dimension_advertiser')}</option>
                                <option value="campaign">{TextResourceHelper.get('label_dimension_campaign')}</option>
                            </select>
                            {/*FILTER PIPELINE*/}
                            {
                                this.state.filterDimension !== '' && this.state.filterDimension === 'market' &&
                                <select onChange={this.onChangeHsPipeline} className="form-control-sm border-radius dark ml-2" style={{ width: '200px' }}>
                                    <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                    {this.state.hs_pipelines.map(pipeline =>
                                        <option key={pipeline.id} value={pipeline.id}>{pipeline.label}</option>
                                    )}
                                </select>
                            }
                            {/*FILTER OWNER*/}
                            {
                                this.state.filterDimension !== '' && this.state.filterDimension === 'owner' &&
                                <select onChange={this.onChangeHsOwner} className="form-control-sm border-radius dark ml-2" style={{ width: '200px' }}>
                                    <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                    {this.state.owners.map(owner =>
                                        <option key={owner.id} value={owner.email}>{owner.last_name + ', ' + owner.first_name}</option>
                                    )}
                                </select>
                            }
                            {/*FILTER STATUS*/}
                            {
                                this.state.filterDimension !== '' && this.state.filterDimension === 'status' &&
                                <select onChange={this.onChangeStatus} className="form-control-sm border-radius dark ml-2" style={{ width: '200px' }}>
                                    <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                    <option key={DealHelper.status_kick_off} value={DealHelper.status_kick_off}>{TextResourceHelper.get('label_status_kickoff')}</option>
                                    <option key={DealHelper.status_preparation} value={DealHelper.status_preparation}>{TextResourceHelper.get('label_status_preparation')}</option>
                                    <option key={DealHelper.status_implementation} value={DealHelper.status_implementation}>{TextResourceHelper.get('label_status_implementation')}</option>
                                    <option key={DealHelper.status_active} value={DealHelper.status_active}>{TextResourceHelper.get('label_status_active')}</option>
                                    <option key={DealHelper.status_paused} value={DealHelper.status_paused}>{TextResourceHelper.get('label_status_paused')}</option>
                                    <option key={DealHelper.status_finished} value={DealHelper.status_finished}>{TextResourceHelper.get('label_status_finished')}</option>
                                </select>
                            }
                            {/*FILTER LINE ITEM STATUS*/}
                            {
                                this.state.filterDimension !== '' && this.state.filterDimension === 'listatus' &&
                                <select onChange={this.onChangeLineItemStatus} className="form-control-sm border-radius dark ml-2" style={{ width: '200px' }}>
                                    <option value="-1" selected>{TextResourceHelper.get('label_select')}</option>
                                    <option key={LineItemStatusHelper.active} value={LineItemStatusHelper.active}>{TextResourceHelper.get('lineitemstatus_active')}</option>
                                    <option key={LineItemStatusHelper.pause} value={LineItemStatusHelper.pause}>{TextResourceHelper.get('lineitemstatus_pause')}</option>
                                    <option key={LineItemStatusHelper.saved} value={LineItemStatusHelper.saved}>{TextResourceHelper.get('lineitemstatus_saved')}</option>
                                    <option key={LineItemStatusHelper.to_confirm} value={LineItemStatusHelper.to_confirm}>{TextResourceHelper.get('lineitemstatus_to_confirm')}</option>
                                    <option key={LineItemStatusHelper.confirmed} value={LineItemStatusHelper.confirmed}>{TextResourceHelper.get('lineitemstatus_confirmed')}</option>
                                    <option key={LineItemStatusHelper.to_validate} value={LineItemStatusHelper.to_validate}>{TextResourceHelper.get('lineitemstatus_to_validate')}</option>
                                    <option key={LineItemStatusHelper.to_implement} value={LineItemStatusHelper.to_implement}>{TextResourceHelper.get('lineitemstatus_to_implement')}</option>
                                    <option key={LineItemStatusHelper.require_validation} value={LineItemStatusHelper.require_validation}>{TextResourceHelper.get('lineitemstatus_require_validation')}</option>
                                    <option key={LineItemStatusHelper.implemented} value={LineItemStatusHelper.implemented}>{TextResourceHelper.get('lineitemstatus_implemented')}</option>
                                    <option key={LineItemStatusHelper.finished} value={LineItemStatusHelper.finished}>{TextResourceHelper.get('lineitemstatus_finished')}</option>
                                    <option key={LineItemStatusHelper.overdelivery} value={LineItemStatusHelper.overdelivery}>{TextResourceHelper.get('lineitemstatus_overdelivery')}</option>
                                    <option key={LineItemStatusHelper.ok} value={LineItemStatusHelper.ok}>{TextResourceHelper.get('lineitemstatus_ok')}</option>
                                    <option key={LineItemStatusHelper.finished} value={LineItemStatusHelper.finished}>{TextResourceHelper.get('lineitemstatus_finished')}</option>
                                    <option key={LineItemStatusHelper.to_bill} value={LineItemStatusHelper.to_bill}>{TextResourceHelper.get('lineitemstatus_to_bill')}</option>
                                    <option key={LineItemStatusHelper.billed} value={LineItemStatusHelper.billed}>{TextResourceHelper.get('lineitemstatus_billed')}</option>
                                    <option key={LineItemStatusHelper.preparation} value={LineItemStatusHelper.preparation}>{TextResourceHelper.get('lineitemstatus_preparation')}</option>
                                    <option key={LineItemStatusHelper.validated} value={LineItemStatusHelper.validated}>{TextResourceHelper.get('lineitemstatus_validated')}</option>
                                    <option key={LineItemStatusHelper.underdelivery} value={LineItemStatusHelper.underdelivery}>{TextResourceHelper.get('lineitemstatus_underdelivery')}</option>
                                </select>
                            }
                            {/*FILTER ADVERTISER*/}
                            {
                                this.state.filterDimension !== '' && this.state.filterDimension === 'advertiser' &&
                                <select onChange={this.onChangeHsAdvertiser} className="form-control-sm border-radius dark ml-2" style={{ width: '200px' }}>
                                    <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                    {this.state.hs_advertisers.map(advertiser =>
                                        <option key={advertiser.name} value={advertiser.name}>{advertiser.name}</option>
                                    )}
                                </select>
                            }
                            {/*FILTER CAMPAIGN*/}
                            {
                                this.state.filterDimension !== '' && this.state.filterDimension === 'campaign' &&
                                <select onChange={this.onChangeHsCampaign} className="form-control-sm border-radius dark ml-2" style={{ width: '200px' }}>
                                    <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                    {this.state.hs_campaigns.map(campaign =>
                                        <option key={campaign.name} value={campaign.name}>{campaign.name}</option>
                                    )}
                                </select>
                            }
                        </div>
                        <div className="col-md-6 text-right">
                            <label>{TextResourceHelper.get('label_from')}</label>
                            <input type="date" className="form-control-sm border-radius ml-2 mr-2" id="sentMsFrom" placeholder="" defaultValue={this.state.filterDateFrom} onChange={this.onChangeDateFrom} />
                            <label>{TextResourceHelper.get('label_to')}</label>
                            <input type="date" className="form-control-sm border-radius ml-2" id="sentMsTo" placeholder="" defaultValue={this.state.filterDateTo} onChange={this.onChangeDateTo} />
                        </div>
                    </form>

                    {this.state.loading && Helpers.showLoadingOverlay()}
                    {(this.havePermission('verMediaPlans') || userData.allRead) &&
                        <div className="mt-4">
                            {
                                !this.state.loading && this.state.results.length > 0 && this.state.results.map(item =>
                                    <MediaPlan deal={item} />
                                )
                            }
                            {
                                this.state.results.length == 0 && <p className="text-center">{TextResourceHelper.get('label_no_results_found')}</p>
                            }
                        </div>
                    }
                    {/*PAGINATION*/}
                    <hr />
                    {(this.havePermission('verMediaPlans') || userData.allRead) &&
                        <div className="w-100 mb-4">
                            {this.state.currentPage > 1 && <span><a onClick={this.goToPrevPage} href="#"><i className="fa fa-chevron-left"></i> {TextResourceHelper.get('buttonlabel_previous')}</a></span>}
                            {this.state.currentPage < this.state.pageCount && <span className="float-right"><a onClick={this.goToNextPage} href="#">{TextResourceHelper.get('buttonlabel_next')} <i className="fa fa-chevron-right"></i></a></span>}
                        </div>
                    }
                    
                </div>
          </div>
        );
    }

    populateData() {
        let url = window.config.REACT_APP_API_URL + '/deal/get/' + TextResourceHelper.getUiLanguage();
        let userData = SessionHelper.getUserData();

        url += '?page=' + this.state.currentPage;
        url += '&pageSize=' + this.state.pageSize;

        if (this.state.filterStatus.length > 0)
            url += '&status=' + this.state.filterStatus.join('-');

        if (this.state.filterLineItemStatus > 0)
            url += '&lineItemStatus=' + this.state.filterLineItemStatus;

        if (this.state.filterOwnerEmail !== '')
            url += '&ownerEmail=' + this.state.filterOwnerEmail;

        if (this.state.filterPipelineId !== '')
            url += '&pipeline=' + this.state.filterPipelineId;

        if (this.state.filterAdvertiser !== '')
            url += '&advertiser=' + this.state.filterAdvertiser;

        if (this.state.filterCampaign !== '')
            url += '&campaign=' + this.state.filterCampaign;

        if (this.state.filterDateFrom !== '')
            url += '&dateFrom=' + this.state.filterDateFrom;

        if (this.state.filterDateTo !== '')
            url += '&dateTo=' + this.state.filterDateTo;

        if (this.state.inputSearchId && this.state.inputSearchId > 0)
            url += '&dealId=' + this.state.inputSearchId;

        if (this.state.inputSearchId && this.state.inputSearchId > 0)
            url += '&isOwner=' + userData.allRead;

        axios.get(url)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    results: data.results,
                    pageCount: data.page_count,
                    rowCount: data.row_count,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    }

    populateFilters() {
        // hs owners
        axios.get(window.config.REACT_APP_API_URL + '/deal/owners/get')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    owners: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        // hs pipelines
        axios.get(window.config.REACT_APP_API_URL + '/deal/pipelines/get')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    hs_pipelines: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        // hs advertisers
        axios.get(window.config.REACT_APP_API_URL + '/deal/advertisers/get')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    hs_advertisers: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        // hs campaigns
        axios.get(window.config.REACT_APP_API_URL + '/deal/campaigns/get')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    hs_campaigns: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
