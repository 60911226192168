import Helpers from '../../Helpers'
import axios from 'axios';
import SessionHelper from './SessionHelper';

const TextResourceHelper = {

    changeLanguage: function (languageKey) {
        let userData = SessionHelper.getUserData();
        localStorage.setItem("uilanguagekey", languageKey);
        localStorage.removeItem("textresources");

        axios.post(window.config.REACT_APP_API_URL + '/user/changelanguage/' + userData.id + '/' + languageKey)
            .then((response) => {
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    setUiLanguage: function (languageKey) {
        localStorage.setItem("uilanguagekey", languageKey);
    },

    getUiLanguage: function () {
        let uiLanguage = localStorage.getItem('uilanguagekey');

        if (uiLanguage && uiLanguage !== '')
            return uiLanguage;

        return 'language0'; // default
    },

    getTextResources: function () {
        let textResources = null;

        if (localStorage.getItem("textresources")) {
            textResources = JSON.parse(localStorage.getItem("textresources"));
        }

        return textResources;
    },

    loadTextResources: async function () {
        await axios.get(window.config.REACT_APP_API_URL + '/textresource/getbylanguage/' + this.getUiLanguage())
            .then(response => {
                const data = response.data.data;
                localStorage.setItem("textresources", JSON.stringify(data));
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    get: function (key) {
        let textResources = this.getTextResources();

        if (textResources && typeof textResources[key] !== 'undefined')
            return textResources[key];

        return '';
    },
}

export default TextResourceHelper;