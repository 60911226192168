import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import $ from 'jquery';
import TextResourceHelper from '../helpers/TextResourceHelper';

export class TextResourcesList extends Component {
    static displayName = TextResourcesList.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            loading: true
        };
    }

    componentDidMount() {
        Helpers.setNavActive("#nav-textresources");
        this.populateData();
    }

    render() {
        let contents = 
            <table className='table table-striped datatable table-abm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>{TextResourceHelper.get('label_id')}</th>
                        <th>{TextResourceHelper.get('label_key')}</th>
                        <th>{TextResourceHelper.get('label_description')}</th>
                        <th style={{ width: '90px' }}>{TextResourceHelper.get('label_actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.items.map(item =>
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.key}</td>
                            <td>{item.description}</td>
                            <td>
                                <Link to={"/textresource-edit/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>;

        return (
            <div>
                <h2 className="text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_textresources')}</h2>
                <div className="container">
                    <div className="table-responsive mt-4">
                        {contents}
                    </div>
                </div>
          </div>
        );
    }

    async populateData() {
        axios.get(window.config.REACT_APP_API_URL + '/textresource/getall')
            .then(response => {
                const data = response.data.data;
                this.setState({ items: data, loading: false });
                Helpers.initDataTable();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
