import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import $ from 'jquery';
import TextResourceHelper from '../helpers/TextResourceHelper';
import parse from 'html-react-parser'
import SessionHelper from '../helpers/SessionHelper';

export class UsersList extends Component {
    static displayName = UsersList.name;

    constructor(props) {
        super(props);

        this.deleteUser = this.deleteUser.bind(this);

        this.state = {
            items: [],
            deleteUserId: 0,
            deleteUserFullName: '',
            loading: true
        };
    }

    componentDidMount() {
        Helpers.setNavActive("#nav-users");
        this.populateData(true);
    }

    setDeleteUserData = (id, fullName) => () => {
        this.setState({
            deleteUserId: id,
            deleteUserFullName: fullName
        });
    }
    deleteUser(e) {
        e.preventDefault();

        if (this.state.deleteUserId <= 0)
            return;

        this.setState({ loading: true });

        axios.post(window.config.REACT_APP_API_URL + '/user/delete/' + this.state.deleteUserId)
            .then((response) => {
                $('.close').click();
                this.populateData(false);
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_user_deleted'));
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    havePermission(namePermission) {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission)
                result = true;
        });

        return result;
    }
    isOwner() {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == "allRead" || value == "allWrite")
                result = true;
        });

        return result;
    }
    render() {
        let userData = SessionHelper.getUserData();

        let contents = 
            <table className='table table-striped datatable table-abm' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>{TextResourceHelper.get('label_id')}</th>
                        <th>{TextResourceHelper.get('label_name')}</th>
                        <th>{TextResourceHelper.get('label_email')}</th>
                        <th>{TextResourceHelper.get('label_role')}</th>
                        <th>{TextResourceHelper.get('label_group')}</th>
                        <th style={{ width: '90px' }}>{TextResourceHelper.get('label_actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.items.map(item =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.last_name + ', ' + item.first_name}</td>
                                <td>{item.email}</td>
                                <td>{item.role_name}</td>
                                <td>{item.group_name}</td>
                                <td>
                                    {(this.havePermission("modificarUsuario") || userData.allWrite)  && < Link to={"/user-edit/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>}
                                    &nbsp;
                                    {(this.havePermission("eliminarUsuario") || userData.allWrite)  && <a onClick={this.setDeleteUserData(item.id, item.last_name + ', ' + item.first_name)} className="btn btn-default" data-toggle="modal" data-target="#deleteUserModal"><i className="fas fa-trash"></i></a>}
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>;

        return (
            <div>
                <h2 className="text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_users')}</h2>
                <div className="container">
                    <div className="text-right">
                        {(this.havePermission("agregarUsuario") || userData.allWrite) && <Link to={"/user-create"} className="btn btn-primary"><i className="fas fa-plus" aria-hidden="true"></i> {TextResourceHelper.get('buttonlabel_new_user')}</Link>}
                    </div>
                    <div className="table-responsive mt-4">
                        {contents}
                    </div>
                </div>

                <div className="modal fade" id="deleteUserModal" tabindex="-1" role="dialog" aria-labelledby="deleteUserModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteUserModal">{TextResourceHelper.get('pagetitle_delete_user')}</h5>
                                <button className="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {parse(TextResourceHelper.get('label_confirm_delete_user').replace('{fullname}', this.state.deleteUserFullName))}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-dark" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                <button onClick={this.deleteUser} type="button" className="btn btn-primary">{TextResourceHelper.get('buttonlabel_delete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        );
    }

    async populateData(initDataTable) {
        axios.get(window.config.REACT_APP_API_URL + '/user/getall')
            .then(response => {
                const data = response.data.data;
                this.setState({ items: data, loading: false });
                if (initDataTable)
                    Helpers.initDataTable();
                else
                    Helpers.refreshDataTable();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
