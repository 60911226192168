export default {
    cookieName: "kite.session",
    uuid: "bb24f04a-7ceb-4e98-a9f8-0f981f71226e",
    timeoutInMinutes: 60 * 24, // process.env.REACT_APP_SESSION_TIMEOUT
    cacheLocation: 'localStorage',
    debugMode: true, // boolean to show or hide console log statements, useful while developing
    role_superuser: 1,
    role_user: 2,
    group_sales: 1,
    group_ms: 2,
    group_adops: 3,
    group_finance: 4
}