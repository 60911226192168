import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../Helpers'
import dealSentImg from '../../deal_sent.png';
import TextResourceHelper from '../helpers/TextResourceHelper';

export class MsDealSent extends Component {
    static displayName = MsDealSent.name;

    componentDidMount() {
        Helpers.setNavActive("#nav-deals");
    }

    backToHome(e) {
        e.preventDefault();
        this.props.history.push("/");
    }

    render () {
        return (
            <div>
                <div className="container mt-4 mb-4 text-center">
                    <h3 className="text-dark font-weight-bold mb-3">{TextResourceHelper.get('successmessage_deal_sent')}</h3>
                    <div className="container">
                        <img src={dealSentImg} />
                    </div>
                    <div className="container">
                        <Link to="/" className="btn btn-dark text-uppercase font-weight-bold">{TextResourceHelper.get('buttonlabel_back_home')}</Link>
                    </div>
                </div>
          </div>
        );
    }
}
