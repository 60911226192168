import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import TextResourceHelper from '../helpers/TextResourceHelper';

export class TextResourceEdit extends Component {
    static displayName = TextResourceEdit.name;

    constructor(props) {
        super(props);

        this.onChangeLanguage0 = this.onChangeLanguage0.bind(this);
        this.onChangeLanguage1 = this.onChangeLanguage1.bind(this);
        this.onChangeLanguage2 = this.onChangeLanguage2.bind(this);
        this.onChangeLanguage3 = this.onChangeLanguage3.bind(this);
        this.onChangeLanguage4 = this.onChangeLanguage4.bind(this);
        this.onChangeLanguage5 = this.onChangeLanguage5.bind(this);
        this.onChangeLanguage6 = this.onChangeLanguage6.bind(this);
        this.onChangeLanguage7 = this.onChangeLanguage7.bind(this);
        this.onChangeLanguage8 = this.onChangeLanguage8.bind(this);
        this.onChangeLanguage9 = this.onChangeLanguage9.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            id: 0,
            key: '',
            description: '',
            language0: '',
            language1: null,
            language2: null,
            language3: null,
            language4: null,
            language5: null,
            language6: null,
            language7: null,
            language8: null,
            language9: null,
            loading: true
        };
    }

    componentDidMount() {
        Helpers.setNavActive("#nav-textresources");
        this.populateData();
        Helpers.setClientValidation();
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>

                {/*RESOURCE KEY*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_key')}</label>
                    <input className="form-control dark" type="text" name="lastname" onChange={this.onChangeLastName} value={this.state.key} readOnly />
                </div>

                {/*DESCRIPTION*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_description')}</label>
                    <input className="form-control dark" type="text" name="lastname" onChange={this.onChangeLastName} value={this.state.description} readOnly />
                </div>

                {/*LANGUAGE 0*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language0')}</label>
                    <textarea className="form-control dark" rows="3" name="language0" onChange={this.onChangeLanguage0} value={this.state.language0} required />
                    <div className="invalid-feedback">{TextResourceHelper.get('errormessage_required_field')}</div>
                </div>

                {/*LANGUAGE 1*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language1')}</label>
                    <textarea className="form-control dark" rows="3" name="language1" onChange={this.onChangeLanguage1} value={this.state.language1} />
                </div>

                {/*LANGUAGE 2*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language2')}</label>
                    <textarea className="form-control dark" rows="3" name="language2" onChange={this.onChangeLanguage2} value={this.state.language2} />
                </div>

                {/*LANGUAGE 3*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language3')}</label>
                    <textarea className="form-control dark" rows="3" name="language3" onChange={this.onChangeLanguage3} value={this.state.language3} />
                </div>

                {/*LANGUAGE 4*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language4')}</label>
                    <textarea className="form-control dark" rows="3" name="language4" onChange={this.onChangeLanguage4} value={this.state.language4} />
                </div>

                {/*LANGUAGE 5*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language5')}</label>
                    <textarea className="form-control dark" rows="3" name="language5" onChange={this.onChangeLanguage5} value={this.state.language5} />
                </div>

                {/*LANGUAGE 6*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language6')}</label>
                    <textarea className="form-control dark" rows="3" name="language6" onChange={this.onChangeLanguage6} value={this.state.language6} />
                </div>

                {/*LANGUAGE 7*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language7')}</label>
                    <textarea className="form-control dark" rows="3" name="language7" onChange={this.onChangeLanguage7} value={this.state.language7} />
                </div>

                {/*LANGUAGE 8*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language8')}</label>
                    <textarea className="form-control dark" rows="3" name="language8" onChange={this.onChangeLanguage8} value={this.state.language8} />
                </div>

                {/*LANGUAGE 9*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_language9')}</label>
                    <textarea className="form-control dark" rows="3" name="language9" onChange={this.onChangeLanguage9} value={this.state.language9} />
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-12 text-right">
                    <button type="submit" className="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{TextResourceHelper.get('buttonlabel_cancel')}</button>
                </div>

            </form>;

        return (
            <div>
                <h2 className="text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_textresources')}</h2>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? Helpers.showLoadingOverlay() : Helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    onChangeLanguage0(e) {
        this.setState({
            language0: e.target.value
        });
    }
    onChangeLanguage1(e) {
        this.setState({
            language1: e.target.value
        });
    }
    onChangeLanguage2(e) {
        this.setState({
            language2: e.target.value
        });
    }
    onChangeLanguage3(e) {
        this.setState({
            language3: e.target.value
        });
    }
    onChangeLanguage4(e) {
        this.setState({
            language4: e.target.value
        });
    }
    onChangeLanguage5(e) {
        this.setState({
            language5: e.target.value
        });
    }
    onChangeLanguage6(e) {
        this.setState({
            language6: e.target.value
        });
    }
    onChangeLanguage7(e) {
        this.setState({
            language7: e.target.value
        });
    }
    onChangeLanguage8(e) {
        this.setState({
            language8: e.target.value
        });
    }
    onChangeLanguage9(e) {
        this.setState({
            language9: e.target.value
        });
    }
    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/users");
    }
    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];
        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            description: this.state.description,
            key: this.state.key,
            language_0: this.state.language0,
            language_1: this.state.language1,
            language_2: this.state.language2,
            language_3: this.state.language3,
            language_4: this.state.language4,
            language_5: this.state.language5,
            language_6: this.state.language6,
            language_7: this.state.language7,
            language_8: this.state.language8,
            language_9: this.state.language9
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_API_URL + '/textresource/save', data, {
            headers: headers
        })
            .then((response) => {
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_data_saved'));
                this.props.history.push('/textresources');
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        axios.get(window.config.REACT_APP_API_URL + '/textresource/getbyid/' + this.props.match.params.id)
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    key: data.key,
                    description: data.description,
                    language0: data.language_0,
                    language1: data.language_1,
                    language2: data.language_2,
                    language3: data.language_3,
                    language4: data.language_4,
                    language5: data.language_5,
                    language6: data.language_6,
                    language7: data.language_7,
                    language8: data.language_8,
                    language9: data.language_9,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
