import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import SessionHelper from '../helpers/SessionHelper'
import logo from '../../kite_logo_small.png';
import $ from 'jquery';
import TextResourceHelper from '../helpers/TextResourceHelper';
import parse from 'html-react-parser'

export class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);

        this.state = {
            email: '',
            loading: false
        };
    }

    componentDidMount() {
        Helpers.setClientValidation();
        $("#email").focus();
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*EMAIL*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{TextResourceHelper.get('label_email')}</label>
                    <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.onChangeEmail} required />
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-12 text-right">
                    <button type="submit" className="btn btn-primary">{TextResourceHelper.get('buttonlabel_send')}</button>
                </div>

            </form>;

        return (
            <div className="container mt-5">
                <h2 className="text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_password_recovery')}</h2>
                <div className="card">
                    <div className="card-header">
                        <img src={logo} />
                        <p>{TextResourceHelper.get('label_forgotpassword_info')}</p>
                    </div>
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            email: this.state.email
        };

        axios.post(window.config.REACT_APP_API_URL + '/auth/passwordrecovery', data)
            .then((response) => {
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), TextResourceHelper.get('successmessage_password_recovery'));

                setTimeout(function () {
                    window.location.replace(window.location.origin);
                }, 5000);
            })
            .catch((error) => {
                console.log(error);

                let msg = TextResourceHelper.get('errormessage_unknown_error');

                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                this.setState({ loading: false });
            });
    }
}

export default ForgotPassword;
