import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Helpers'
import TextResourceHelper from '../helpers/TextResourceHelper';
import LineItemHelper from '../helpers/LineItemHelper';
import LineItemAdsDirect from './LineItemAdsDirect';
import LineItemAdsDeal from './LineItemAdsDeal';
import DealHelper from '../helpers/DealHelper';
import $ from 'jquery';

export class ImportLineItemsModal extends Component {
    static displayName = ImportLineItemsModal.name;

    constructor(props) {
        super(props);

        this.searchDeal = this.searchDeal.bind(this);
        this.onChangeSearchDealId = this.onChangeSearchDealId.bind(this);
        this.importLineItems = this.importLineItems.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.setLineItemSelected = this.setLineItemSelected.bind(this);

        this.state = {
            id: 0,
            searchDealId: 0,
            deal: null,
            targetDealId: this.props.targetDealId,
            targetDealAdvertiser: this.props.targetDealAdvertiser,
            importLineItemIds: [],
            importAll: false,
            statusLabel: TextResourceHelper.get('label_search_deal_id')
        };
    }

    componentDidMount() {
        
    }

    setLineItemSelected(id, selected) {
        let _importLineItemIds = this.state.importLineItemIds;

        if (selected)
            _importLineItemIds.push(id);
        else {
            const index = _importLineItemIds.indexOf(id);
            if (index > -1)
                _importLineItemIds.splice(index, 1);
        }

        this.setState({
            importLineItemIds: _importLineItemIds
        });
    }

    toggleSelectAll(e) {
        let _selected = e.target.checked;
        let _importLineItemIds = this.state.importLineItemIds;

        if (_selected) {
            if (this.state.deal.items.length > 0) {
                _importLineItemIds = [];
                this.state.deal.items.map(item => {
                    _importLineItemIds.push(item.id);
                });
            }
        } else {
            _importLineItemIds = [];
        }

        this.setState({
            importAll: _selected,
            importLineItemIds: _importLineItemIds
        });
    }

    render() {

        let contents = <div className="text-center"><p>{this.state.statusLabel}</p></div>;
        if (this.state.deal !== null) {
            // status
            let statusCssClass = Helpers.getDealStatusCssClass(this.state.deal.status);

            contents =
                <div class="container p-0">
                    <div class="form-check mb-2">
                        <input id={'importall-' + this.state.deal.deal_id} class="form-check-input" type="checkbox" value="importall" onChange={this.toggleSelectAll} />
                        <label htmlFor={'importall-' + this.state.deal.deal_id} class="form-check-label">{TextResourceHelper.get('label_all')}</label>
                    </div>
                    <div class="table-responsive">
                        <table className="table campaigns-table mb-0">
                            <tbody>
                                <tr>
                                    <th className="fit">{TextResourceHelper.get('label_hs_deal_id')}</th>
                                    <th className="data fit">{this.state.deal.deal_id}</th>
                                    <th className="fit">{TextResourceHelper.get('label_advertiser')}</th>
                                    <th className="data fit">{this.state.deal.nombre_anunciante == null ? '---' : this.state.deal.nombre_anunciante}</th>
                                    <th className="fit">{TextResourceHelper.get('label_campaign_name')}</th>
                                    <th className="data">{this.state.deal.campaign == null ? '---' : this.state.deal.campaign}</th>
                                    <th className="fit">{TextResourceHelper.get('label_mediaplan_id')}</th>
                                    <th className="data">{this.state.deal.id}</th>
                                </tr>
                                <tr className={statusCssClass}>
                                    <td colSpan="8" style={{ width: '100%' }}>
                                        {this.state.deal.media_plan_name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {
                            this.state.deal.items.length > 0 &&
                            <div class="accordion" id={'lineItemsAccordion-' + this.state.deal.deal_id}>
                                {
                                    this.state.deal.items.map(item => {
                                        if (item.line_item_type == LineItemHelper.awesome_ads_direct)
                                            return <LineItemAdsDirect lineItem={item} selectable={true} setSelected={this.setLineItemSelected} selectAll={this.state.importAll} />;
                                        else if (item.line_item_type == LineItemHelper.awesome_ads_deal)
                                            return <LineItemAdsDeal lineItem={item} selectable={true} setSelected={this.setLineItemSelected} selectAll={this.state.importAll} />;
                                    })
                                }
                            </div>
                        }
                        <div className="text-right mt-2">
                            <button type="button" onClick={this.importLineItems} className="btn btn-primary btn-xl" disabled={this.state.importLineItemIds.length === 0}>{TextResourceHelper.get('buttonlabel_save')}</button>
                        </div>
                    </div>
                </div>;
        }

        return (
            <div id={'importLiModal-' + this.state.targetDealId} class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content small">
                        <div class="modal-header">
                            <h6 class="modal-title">{TextResourceHelper.get('pagetitle_select_lineitems_import')}</h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body p-2">
                            <form className="" onSubmit={this.searchDeal}>
                                <div className="mb-2 text-right">
                                    <i className="fa fa-search mr-2"></i>
                                    <input type="number" onChange={this.onChangeSearchDealId} placeholder={TextResourceHelper.get('label_deal_id')} className="form-control-sm border-radius border-none dark" />
                                </div>
                            </form>
                            {contents}
                        </div>
                    </div>
                </div>
            </div>
            );
    }

    searchDeal(e) {
        e.preventDefault();

        var self = this;

        axios.get(window.config.REACT_APP_API_URL + '/deal/getbydealid/' + this.state.searchDealId)
            .then(response => {
                const data = response.data.data;

                if (data !== null && data.nombre_anunciante === this.state.targetDealAdvertiser) {
                    self.setState({
                        deal: data,
                        importLineItemIds: [],
                        importAll: false
                    });
                } else {
                    self.setState({
                        deal: null,
                        importLineItemIds: [],
                        importAll: false,
                        statusLabel: TextResourceHelper.get('label_no_results_found_advertiser')
                    });
                }
            })
            .catch(function (error) {
                console.log(error);

                if (error.response.data.error_code == window.errorCodes.entity_not_found) {
                    self.setState({
                        deal: null,
                        importLineItemIds: [],
                        importAll: false,
                        statusLabel: TextResourceHelper.get('label_no_results_found')
                    });
                } else {
                    let msg = TextResourceHelper.get('errormessage_unknown_error');
                    Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
                }
            });
    }

    onChangeSearchDealId(e) {
        this.setState({
            searchDealId: e.target.value
        });
    }

    importLineItems(e) {
        e.preventDefault();

        const data = {
            line_item_ids: this.state.importLineItemIds
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/' + this.state.targetDealId + '/lineitems/duplicate', data)
            .then((response) => {
                this.props.refreshDeal();
                $('.modal').modal('hide'); // closes all active pop ups.

                let msg = TextResourceHelper.get('infomessage_lineitem_imported');
                Helpers.notifyInfo(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }
}

export default ImportLineItemsModal;