export default {
    active: 1,
    pause: 2,
    saved: 3,
    to_confirm: 4,
    confirmed: 5,
    to_validate: 6,
    to_implement: 7,
    require_validation: 8,
    implemented: 9,
    finished: 10,
    overdelivery: 11,
    ok: 12,
    to_bill: 13,
    billed: 14,
    preparation: 15,
    validated: 16,
    underdelivery: 17
}