import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, DropdownItem } from 'reactstrap';
import { NavMenu } from './NavMenu';
import './NavMenu.css';
import logo from '../kite_logo_white.png';
import profileAvatar from '../profile_avatar.png';
import iconBell from '../icn_bell.png';
import authService from '../components/api-authorization/AuthorizeService';
import Login from '../components/api-authorization/Login';
import FirstLoginPasswordChange from './account/FirstLoginPasswordChange';
import SessionHelper from './helpers/SessionHelper';
import SessionConfig from './session/SessionConfig';
import TextResourceHelper from './helpers/TextResourceHelper';
import $ from 'jquery';
import axios from 'axios';
import Helpers from '../Helpers';
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            isAuthenticated: false,
            forcePasswordChange: false,
            userData: null,
            ready: false,
            collapsed: true,
            languages: [],
            unreadNotifications: []
        };
    }

    async componentDidMount() {
        this.populateState();
        authService.setInterceptors();

        // load text resources
        await TextResourceHelper.loadTextResources();
        this.setState({
            ready: true
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleSidebar() {
        $("body").toggleClass("sb-sidenav-toggled");
    }

    logout = () => () => {
        Helpers.showLoadingOverlay();
        SessionHelper.doLogout();
        return window.location.replace(this.getReturnUrl());
    }

    changeLanguage = (key) => () => {
        TextResourceHelper.changeLanguage(key);
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.Login}`;
    }

    getLanguages() {
        axios.get(window.config.REACT_APP_API_URL + '/textresource/getlanguages')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    languages: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getUnreadNotifications() {
        let userData = SessionHelper.getUserData();

        axios.get(window.config.REACT_APP_API_URL + '/notification/' + userData.id + '/get/unread')
            .then(response => {
                const data = response.data.data;
                this.setState({
                    unreadNotifications: data,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateState(languages, uiLanguage) {
        const [isAuthenticated, forcePasswordChange] = await Promise.all([authService.isAuthenticated(), authService.forcePasswordChange()])

        if (isAuthenticated) {
            this.getLanguages();
            this.getUnreadNotifications();
        }

        this.setState({
            isAuthenticated: isAuthenticated,
            forcePasswordChange: forcePasswordChange,
            userData: SessionHelper.getUserData()
        });
    }

    havePermission(namePermission) {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission)
                result = true;
        });

        return result;
    }
    render() {
        if (!this.state.ready)
            return <div></div>;

        const { isAuthenticated, forcePasswordChange } = this.state;

        if (!isAuthenticated)
            return <Login />;

        if (forcePasswordChange)
            return <FirstLoginPasswordChange />;

        let userData = SessionHelper.getUserData();

        return (
            <div className="d-flex" id="wrapper">
                <div className="border-end bg-white" id="sidebar-wrapper">
                    <div className="sidebar-heading bg-dark text-center"><img src={logo} /></div>
                    <div className="list-group list-group-flush">
                        <Link id="nav-mediaplanner" className="list-group-item list-group-item-action list-group-item-dark p-4" to="/media-planner">
                            <div className="icon mediaplanner align-middle mr-3"></div>
                            <i className="fa fa-caret-right menu-pointer"></i>
                            <div className="align-middle d-inline-block text-uppercase" style={{ width: '78%' }}>{TextResourceHelper.get('navitem_mediaplanner')}</div>
                        </Link>
                        <Link id="nav-campaignmanager" className="list-group-item list-group-item-action list-group-item-dark p-4" to="/">
                            <div className="icon campaignmanager align-middle mr-3"></div>
                            <i className="fa fa-caret-right menu-pointer"></i>
                            <div className="align-middle d-inline-block text-uppercase" style={{ width: '78%' }}>{TextResourceHelper.get('navitem_campaignmanager')}</div>
                        </Link>
                        {(this.havePermission('reporting') || this.havePermission('allRead')) &&
                            <Link id="nav-reporting" className="list-group-item list-group-item-action list-group-item-dark p-4" to="/">
                                <div className="icon reporting align-middle mr-3"></div>
                                <i className="fa fa-caret-right menu-pointer"></i>
                                <div className="align-middle d-inline-block text-uppercase" style={{ width: '78%' }}>{TextResourceHelper.get('navitem_reporting')}</div>
                            </Link>
                        }
                        <Link id="nav-notifications" className="list-group-item list-group-item-action list-group-item-dark p-4" to="/notifications">
                            <div className="icon notifications align-middle mr-3"></div>
                            <i className="fa fa-caret-right menu-pointer"></i>
                            <div className="align-middle d-inline-block text-uppercase" style={{ width: '78%' }}>{TextResourceHelper.get('navitem_notifications')}</div>
                        </Link>
                        <a id="nav-signout" href="#" onClick={this.logout()} className="list-group-item list-group-item-action list-group-item-dark p-4">
                            <div className="icon sign-out align-middle mr-3">
                                <i className="fa fa-18x fa-sign-out-alt" style={{ opacity: '.6'}}></i>
                            </div>
                            <div className="align-middle d-inline-block text-uppercase" style={{ width: '78%' }}>{TextResourceHelper.get('navitem_logout')}</div>
                        </a>
                    </div>
                </div>

                <div id="page-content-wrapper">
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3" light>
                        <div className="container-fluid">
                            <button onMouseOver={this.toggleSidebar} onClick={this.toggleSidebar} className="btn btn-default" id="sidebarToggle"><i className="fa fa-bars"></i></button>
                            {/*<NavbarBrand tag={Link} to="/"><img src={logo} style={{ width: '100%' }} /></NavbarBrand>*/}
                            {/*<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />*/}
                            {/*<Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>*/}
                            {/*    <ul className="navbar-nav flex-grow">*/}
                            {/*        <NavItem id="nav-deals">*/}
                            {/*            {userData.group === SessionConfig.group_ms && <NavLink tag={Link} className="text-dark" to="/ms-deals">{TextResourceHelper.get('navitem_search_deal_id')}</NavLink>}*/}
                            {/*            {userData.group === SessionConfig.group_sales && <NavLink tag={Link} className="text-dark" to="/sales-deals">{TextResourceHelper.get('navitem_search_deal_id')}</NavLink>}*/}
                            {/*        </NavItem>*/}
                            {/*        <NavItem>*/}
                            {/*            <NavLink tag={Link} className="text-dark" to="/">{TextResourceHelper.get('navitem_media_map')}</NavLink>*/}
                            {/*        </NavItem>*/}
                            {/*        <NavItem>*/}
                            {/*            <NavLink tag={Link} className="text-dark" to="/">{TextResourceHelper.get('navitem_change_history')}</NavLink>*/}
                            {/*        </NavItem>*/}
                            {/*        <li className="nav-item dropdown">*/}
                            {/*            <a className="nav-link dropdown-toggle text-dark" href="#" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*                <i className="fa fa-globe"></i> {TextResourceHelper.get('label_' + TextResourceHelper.getUiLanguage())}*/}
                            {/*            </a>*/}
                            {/*            <div className="dropdown-menu" aria-labelledby="languageDropdown">*/}
                            {/*                {this.state.languages.map(lang =>*/}
                            {/*                    lang.key !== TextResourceHelper.getUiLanguage() && <a key={lang.key} className="dropdown-item" href="#" onClick={this.changeLanguage(lang.key)}>{TextResourceHelper.get('label_' + lang.key)}</a>*/}
                            {/*                )}*/}
                            {/*            </div>*/}
                            {/*        </li>*/}
                            {/*        <NavItem id="nav-logout">*/}
                            {/*            <NavLink onClick={this.logout()} className="text-dark cursor-pointer">{TextResourceHelper.get('navitem_logout')}</NavLink>*/}
                            {/*        </NavItem>*/}
                            {/*    </ul>*/}
                            {/*</Collapse>*/}
                            <div className="d-sm-inline-flex main-title-cont ml-2">
                                <h1 id="mainTitle"></h1>
                            </div>

                            <div className="d-sm-inline-flex flex-sm-row-reverse main-title-cont ml-2">
                                <ul className="navbar-nav flex-grow">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle text-dark" href="#" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-globe"></i> {TextResourceHelper.get('label_' + TextResourceHelper.getUiLanguage())}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="languageDropdown">
                                            {this.state.languages.map(lang =>
                                                lang.key !== TextResourceHelper.getUiLanguage() && <a key={lang.key} className="dropdown-item" href="#" onClick={this.changeLanguage(lang.key)}>{TextResourceHelper.get('label_' + lang.key)}</a>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="d-sm-inline-flex flex-sm-row-reverse profile-avatar-cont dropdown">
                                <div className="profile-avatar cursor-pointer" style={{ backgroundImage: 'url(' + profileAvatar + ')' }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </div>
                                {
                                    this.state.unreadNotifications.length > 0 &&
                                    <Link to="/notifications" className="">
                                        <span id="notificationBadge" className="badge">{this.state.unreadNotifications.length}</span>
                                    </Link>
                                }
                                <div className="dropdown-menu dropdown-menu-right text-right" aria-labelledby="dropdownMenuButton">
                                    <h6 className="dropdown-header">{userData.role_description}</h6>
                                    {userData.role === SessionConfig.role_superuser && (this.havePermission("usuarios") || this.havePermission('allRead')) && <Link className="dropdown-item" to="/users">{TextResourceHelper.get('navitem_users')}</Link>}
                                    {userData.role === SessionConfig.role_superuser && <Link className="dropdown-item" to="/textresources">{TextResourceHelper.get('navitem_textresources')}</Link>}
                                    <div className="dropdown-divider"></div>
                                    <a onClick={this.logout()} className="dropdown-item" href="#">{TextResourceHelper.get('navitem_logout')}</a>
                                </div>
                            </div>
                        </div>
                    </Navbar>

                    <div className="container-fluid">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
  }
}
