import React, { Component } from 'react';
import video from '../video.mp4';
import imgRocketRight from '../inhousenews_img.png';
import imgLeftBg from '../inhousenews_left.png';
import imgGoBig from '../gobig.png';
import imgNews1 from '../news_1.png';
import imgNews2 from '../news_2.png';
import Helpers from '../Helpers'
import SessionHelper from './helpers/SessionHelper'
import SessionConfig from './session/SessionConfig';
import TextResourceHelper from './helpers/TextResourceHelper';

export class Home extends Component {
  static displayName = Home.name;

    componentDidMount() {
        Helpers.setNavActive("#nav-home");
    }

    render() {
        //let userData = SessionHelper.getUserData();

        //if (userData.group === SessionConfig.group_ms)
        //    return this.msView();

        //if (userData.group === SessionConfig.group_sales)
        //    return this.salesView();

        return (
            <div>
                <h2 className="text-center text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_learn_to_use_platform')}</h2>
                <div className="container text-center">
                    <video controls>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </div>
        );
    }

    msView() {
        return (
            <div>
                <h2 className="text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_inhousenews')}</h2>
                <div class="row mt-2">
                    <div class="col-sm-5 no-padding">
                        <img src={imgLeftBg} style={{ width: '100%' }} />
                    </div>
                    <div class="col-sm-7 no-padding" style={{ backgroundColor: '#00afa6' }}>
                        <div className="inhousenews-inner">
                            <h3 className="mb-4">&iexcl;Bienvenidos!</h3>
                            <p>Le dimos la bienvenida a este 2021 con ventaja: Sabemos trabajar de forma remota, tenemos m&aacute;s cintura ante la incertidumbre pero sobre todo contamos con un equipo talentoso para hacer frente a un a&ntilde;o con desaf&iacute;os emocionantes.</p>
                            <p>Continuamos creciendo y solidificando nuestra presencia en cada mercado relevante de latinoam&eacute;rica. Tenemos casos de &eacute;xito de equipos que se han constru&iacute;do y a los pocos meses comenzaron a mostrar impacto como es el caso de nuestras oficinas en Montevideo, Uruguay.</p>
                            <p>Sin duda alguna, el 2020 fue un a&ntilde;o que no pas&oacute; desapercibido. Dej&oacute; huellas, pero por sobre todas las cosas, nos dej&oacute; la certeza que cada uno de nosotros a trav&eacute;s del compromiso, la pasi&oacute;n y el trabajo en equipo, lo hizo posible, a pesar de haberlo vivido bajo un contexto mundial incierto, nosotros, lo transformamos y lo convertimos en un escenario de oportunidades, en el que los desaf&iacute;os fueron pruebas superadas, los errores, aprendizajes y los grandes logros, el resultado de nuestro GO BIG!</p>
                        </div>
                        <img className="img-gobig" src={imgGoBig} />
                        <img className="img-rocket" src={imgRocketRight} />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 mt-3 no-padding">
                        <div className="card inhousenews-card">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="" src={imgNews1} alt="" />
                                </div>
                                <div className="card-body">
                                    <p><strong>&iexcl;Nos encanta ver crecer a nuestro team!</strong></p>
                                    <p>Arrancamos los primeros d&iacute; del a&ntilde;o con dos ingresos.</p>
                                    <p>
                                        Le dimos la bienvenida a Azul Fernandez Iroume, como Jr. Accounting Analyst y a Milton Pedro como Head de Ad Ops en nuestra HQ en Buenos Aires.
                                </p>
                                    <p>El dato de color de ella es que es fan&aacute;tica del MCU, Marvel Cinematic Films.</p>
                                    <p>El es hincha de San Lorenzo y come de todo menos espinaca.</p>
                                    <p>Go Big!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-3 no-padding">
                        <div className="card inhousenews-card">
                            <div className="card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="" src={imgNews2} alt="" />
                                </div>
                                <div className="card-body">
                                    <p><strong>Best Team Player KC 2020!</strong></p>
                                    <br />
                                    <p>&iexcl;Cerramos el 2020 con algunos reconocimientos hacia nuestros colaboradores! &#127881;&#127881;</p>
                                    <br />
                                    <ul style={{ paddingInlineStart: '20px' }}>
                                        <li>Best Team Player | Mapo</li>
                                        <li>Culture Champion | Cami Favero</li>
                                        <li>Promesa Junior | Agus Outeiro</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    salesView() {
        return (
            <div>
                <h2 className="text-center text-info font-weight-bold mb-3">{TextResourceHelper.get('pagetitle_learn_to_use_platform')}</h2>
                <div className="container text-center">
                    <video controls>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </div>
        );
    }
}

export default Home;