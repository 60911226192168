export default {
    status_kick_off: 1,
    status_preparation: 2,
    status_implementation: 3,
    status_pause: 4,
    status_active: 5,
    status_paused: 6,
    status_finished: 7,
    status_to_bill: 8,
    status_billed: 9
}