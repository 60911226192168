import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-input-range/lib/css/index.css'
//import registerServiceWorker from './registerServiceWorker';
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import es from 'javascript-time-ago/locale/es'
import TextResourceHelper from './components/helpers/TextResourceHelper';
import "bootstrap/dist/js/bootstrap.min.js";

let uiLanguage = TextResourceHelper.getUiLanguage();

switch (uiLanguage) {
    case 'language0':
        TimeAgo.addDefaultLocale(es)
        TimeAgo.addLocale(es)
        break;
    case 'language1':
        TimeAgo.addDefaultLocale(en)
        TimeAgo.addLocale(en)
        break;
    default:
        TimeAgo.addDefaultLocale(es)
        TimeAgo.addLocale(es)
        break;
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

