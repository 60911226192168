import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Helpers from '../../Helpers';
import axios from 'axios';
import iconCalendar from '../../icn_calendar.png';
import iconTime from '../../icn_time.png';
import $ from 'jquery';
import InputRange from 'react-input-range';
import DealHelper from '../helpers/DealHelper';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactTimeAgo from 'react-time-ago'
import TextResourceHelper from '../helpers/TextResourceHelper';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import LineItem from './LineItemAdsDirect'
import LineItemHelper from '../helpers/LineItemHelper';
import LineItemAdsDirect from './LineItemAdsDirect';
import LineItemAdsDeal from './LineItemAdsDeal';
import SessionHelper from '../helpers/SessionHelper';
import SessionConfig from '../session/SessionConfig';
import ImportLineItemsModal from './ImportLineItemsModal';
import LineItemStatusHelper from '../helpers/LineItemStatusHelper';

export class MediaPlan extends Component {
    static displayName = MediaPlan.name;

    constructor(props) {
        super(props);

        this.saveLineItemAdsDirect = this.saveLineItemAdsDirect.bind(this);
        this.validateLineItemAdsDirect = this.validateLineItemAdsDirect.bind(this);
        this.forceSaveLineItemAdsDirect = this.forceSaveLineItemAdsDirect.bind(this);
        this.saveLineItemAdsDeal = this.saveLineItemAdsDeal.bind(this);
        this.validateLineItemAdsDeal = this.validateLineItemAdsDeal.bind(this);
        this.forceSaveLineItemAdsDeal = this.forceSaveLineItemAdsDeal.bind(this);
        this.pauseLineItems = this.pauseLineItems.bind(this);
        this.sendToPreparation = this.sendToPreparation.bind(this);
        this.sendToAdops = this.sendToAdops.bind(this);
        this.activateMediaPlan = this.activateMediaPlan.bind(this);
        this.refreshDeal = this.refreshDeal.bind(this);
        this.onChangeLineItemLabel = this.onChangeLineItemLabel.bind(this);
        this.onChangeLineItemModeloDeCompra = this.onChangeLineItemModeloDeCompra.bind(this);
        this.onChangeLineItemSellRate = this.onChangeLineItemSellRate.bind(this);
        this.onChangeLineItemBonificacion = this.onChangeLineItemBonificacion.bind(this);
        this.onChangeLineItemInversion = this.onChangeLineItemInversion.bind(this);
        this.onChangeLineItemFacturacionMensual = this.onChangeLineItemFacturacionMensual.bind(this);
        this.onChangeLineItemFechaInicio = this.onChangeLineItemFechaInicio.bind(this);
        this.onChangeLineItemFechaFin = this.onChangeLineItemFechaFin.bind(this);
        this.onChangeLineItemCountry = this.onChangeLineItemCountry.bind(this);
        this.onChangeLineItemDevices = this.onChangeLineItemDevices.bind(this);
        this.onChangeLineItemGenderBoys = this.onChangeLineItemGenderBoys.bind(this);
        this.onChangeLineItemGenderGirls = this.onChangeLineItemGenderGirls.bind(this);
        this.onChangeLineItemAudiences = this.onChangeLineItemAudiences.bind(this);
        this.onChangeLineItemCategories = this.onChangeLineItemCategories.bind(this);
        this.onChangeLineItemFrecuency = this.onChangeLineItemFrecuency.bind(this);
        this.onChangeLineItemBlUrl = this.onChangeLineItemBlUrl.bind(this);
        this.onChangeLineItemWlUrl = this.onChangeLineItemWlUrl.bind(this);
        this.onChangeLineItemFormat = this.onChangeLineItemFormat.bind(this);
        this.onChangeLineItemCreativeProvider = this.onChangeLineItemCreativeProvider.bind(this);
        this.onChangeLineItemAuditoriaMoat = this.onChangeLineItemAuditoriaMoat.bind(this);
        this.onChangeLineItemLinkAMateriales = this.onChangeLineItemLinkAMateriales.bind(this);
        this.onChangeLineItemUrlDestino = this.onChangeLineItemUrlDestino.bind(this);
        this.onChangeLineItemTracking = this.onChangeLineItemTracking.bind(this);
        this.onChangeLineItemPauseReason = this.onChangeLineItemPauseReason.bind(this);
        this.copyMediaPlanName = this.copyMediaPlanName.bind(this);
        this.copyMediaPlan2 = this.copyMediaPlan2.bind(this);
        this.calculateGoal = this.calculateGoal.bind(this);
        this.setPauseLineItemSelected = this.setPauseLineItemSelected.bind(this);
        this.togglePauseSelectAll = this.togglePauseSelectAll.bind(this);
        this.closeFormModals = this.closeFormModals.bind(this);
        this.onClickChangeStatusAccept = this.onClickChangeStatusAccept.bind(this);

        this.state = {
            readOnly: true,
            deal: this.props.deal,
            sentToOT: false,
            sentToValidation: false,
            lineItem: null,
            lineItemLabel: null,
            lineItemModeloDeCompra: null,
            lineItemModeloDeCompraValid: true,
            lineItemModeloDeCompraTooltip: '',
            lineItemSellRate: '',
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemNetRate: '',
            lineItemGoal: '',
            lineItemTotalGoal: '',
            lineItemBonificacion: '',
            lineItemInversion: '',
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemFacturacionMensual: this.props.deal.facturacion_mensual,
            lineItemFechaInicio: null,
            lineItemFechaInicioValid: true,
            lineItemFechaInicioTooltip: true,
            lineItemFechaFin: null,
            lineItemFechaFinValid: true,
            lineItemFechaFinTooltip: null,
            lineItemCountry: null,
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemCountryOptions: Helpers.getLineItemCountries(),
            lineItemDevices: null,
            lineItemDevicesValid: true,
            lineItemDevicesTooltip: '',
            lineItemDevicesOptions: Helpers.getLineItemDevices(''),
            lineItemGenderBoys: false,
            lineItemGenderGirls: false,
            lineItemGenderValid: true,
            lineItemGenderTooltip: '',
            lineItemAudiences: { min: 0, max: 17 },
            lineItemCategories: null,
            lineItemCategoriesValid: true,
            lineItemCategoriesTooltip: '',
            lineItemCategoriesOptions: Helpers.getLineItemCategories(''),
            lineItemFrecuency: null,
            lineItemFrecuencyValid: true,
            lineItemFrecuencyTooltip: '',
            lineItemBlUrl: null,
            lineItemWlUrl: null,
            lineItemFormat: null,
            lineItemFormatValid: true,
            lineItemFormatTooltip: '',
            lineItemFormatOptions: [],
            lineItemCreativeProvider: null,
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
            lineItemAuditoriaMoat: null,
            lineItemAuditoriaMoatValid: true,
            lineItemAuditoriaMoatTooltip: '',
            lineItemLinkAMateriales: null,
            lineItemLinkAMaterialesValid: true,
            lineItemLinkAMaterialesTooltip: '',
            lineItemUrlDestino: null,
            lineItemUrlDestinoValid: true,
            lineItemUrlDestinoTooltip: '',
            lineItemTracking: null,
            lineItemTrackingValid: true,
            lineItemTrackingTooltip: '',
            pauseLineItemIds: [],
            pauseAllLineItems: false,
            lineItemPauseReason: '',
            msOwners: [],
            adopsOwners: [],
            salesOwners: [],
            //deal para cambiar estado
            changeStatusDealId: 0,
            changeStatusStatus: 0,
        };
    }

    componentDidMount() {
        Helpers.setClientValidation();

        var dealId = this.state.deal.id;

        $('#newLiAdsDirectModal-' + dealId).on('show.bs.modal', function (e) {
            $('#newLiAdsDirectModal-' + dealId).on('hide.bs.modal', function (e) {
                e.preventDefault();
                $('#closeConfirmAdsDirectModal-' + dealId).modal("show");
            });
        });

        $('#newLiAdsDealModal-' + dealId).on('show.bs.modal', function (e) {
            $('#newLiAdsDealModal-' + dealId).on('hide.bs.modal', function (e) {
                e.preventDefault();
                $('#closeConfirmAdsDealModal-' + dealId).modal("show");
            });
        });

        //Poner aca el getSalesOwners
        this.getSalesOwners();
        this.getMsOwners();
        this.getAdopsOwners();
    }

    componentDidUpdate() {
        Helpers.setClientValidation();

        var dealId = this.state.deal.id;

        $('#newLiAdsDirectModal-' + dealId).on('show.bs.modal', function (e) {
            $('#newLiAdsDirectModal-' + dealId).on('hide.bs.modal', function (e) {
                e.preventDefault();
                $('#closeConfirmAdsDirectModal-' + dealId).modal("show");
            });
        });

        $('#newLiAdsDealModal-' + dealId).on('show.bs.modal', function (e) {
            $('#newLiAdsDealModal-' + dealId).on('hide.bs.modal', function (e) {
                e.preventDefault();
                $('#closeConfirmAdsDealModal-' + dealId).modal("show");
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ deal: nextProps.deal });
    }

    sendToPreparation(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/sendtopreparation/' + this.state.deal.deal_id)
            .then((response) => {
                $('#prepareConfirmModal-' + this.state.deal.id).modal("hide");
                this.refreshDeal();
                $('#prepareStatusModal-' + this.state.deal.id).modal("show");
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    sendToAdops(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/sendtoadops/' + this.state.deal.deal_id)
            .then((response) => {
                this.refreshDeal();
                $('#implementStatusModal-' + this.state.deal.id).modal("show");
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    activateMediaPlan(e) {
        e.preventDefault();

        axios.post(window.config.REACT_APP_API_URL + '/deal/activate/' + this.state.deal.deal_id)
            .then((response) => {
                this.refreshDeal();

                let msg = TextResourceHelper.get('successmessage_mediaplan_activated');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    refreshDeal() {
        axios.get(window.config.REACT_APP_API_URL + '/deal/getbydealid/' + this.state.deal.deal_id)
            .then((response) => {
                let _deal = response.data.data;

                this.setState({
                    deal: _deal
                });

                this.forceUpdate();
                //let msg = TextResourceHelper.get('infomessage_data_updated');
                //Helpers.notifyInfo(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    setPauseLineItemSelected(id, selected) {
        let _pauseLineItemIds = this.state.pauseLineItemIds;

        if (selected)
            _pauseLineItemIds.push(id);
        else {
            const index = _pauseLineItemIds.indexOf(id);
            if (index > -1)
                _pauseLineItemIds.splice(index, 1);
        }

        this.setState({
            pauseLineItemIds: _pauseLineItemIds
        });
    }

    delegateMediaPlanToMs = (email) => () => {
        const data = {
            email: email
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/delegate/ms/' + this.state.deal.deal_id, data)
            .then((response) => {
                this.refreshDeal();

                let msg = TextResourceHelper.get('successmessage_mediaplan_delegated');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    delegateMediaPlanToAdops = (email) => () => {
        const data = {
            email: email
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/delegate/adops/' + this.state.deal.deal_id, data)
            .then((response) => {
                this.refreshDeal();

                let msg = TextResourceHelper.get('successmessage_mediaplan_delegated');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    delegateMediaPlanToSales = (email) => () => {
        const data = {
            email: email
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/delegate/sales/' + this.state.deal.deal_id, data)
            .then((response) => {
                this.refreshDeal();

                let msg = TextResourceHelper.get('successmessage_mediaplan_delegated');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    togglePauseSelectAll(e) {
        let _selected = e.target.checked;
        let _pauseLineItemIds = this.state.pauseLineItemIds;

        if (_selected) {
            if (this.state.deal.items.length > 0) {
                _pauseLineItemIds = [];
                this.state.deal.items.map(item => {
                    _pauseLineItemIds.push(item.id);
                });
            }
        } else {
            _pauseLineItemIds = [];
        }

        this.setState({
            pauseAllLineItems: _selected,
            pauseLineItemIds: _pauseLineItemIds
        });
    }

    closeFormModals(e) {
        $('#newLiAdsDirectModal-' + this.state.deal.id).unbind('hide.bs.modal');
        $('#closeConfirmAdsDirectModal-' + this.state.deal.id).modal('hide');
        $('#newLiAdsDirectModal-' + this.state.deal.id).modal('hide');

        $('#newLiAdsDealModal-' + this.state.deal.id).unbind('hide.bs.modal');
        $('#closeConfirmAdsDealModal-' + this.state.deal.id).modal('hide');
        $('#newLiAdsDealModal-' + this.state.deal.id).modal('hide');
        $('#changeStatusDealModal-' + this.state.deal.id).modal('hide');

        this.setState({
            readOnly: true,
            lineItemLabel: '',
            lineItemModeloDeCompra: null,
            lineItemSellRate: '',
            lineItemNetRate: '',
            lineItemGoal: '',
            lineItemTotalGoal: '',
            lineItemBonificacion: '',
            lineItemInversion: '',
            lineItemFechaInicio: '',
            lineItemFechaFin: '',
            lineItemCountry: '',
            lineItemCountryOptions: Helpers.getLineItemCountries(),
            lineItemDevices: null,
            lineItemDevicesOptions: Helpers.getLineItemDevices(''),
            lineItemGenderBoys: false,
            lineItemGenderGirls: false,
            lineItemAudiences: { min: 0, max: 17 },
            lineItemCategories: null,
            lineItemCategoriesOptions: Helpers.getLineItemCategories(''),
            lineItemFrecuency: null,
            lineItemBlUrl: null,
            lineItemWlUrl: null,
            lineItemFormat: null,
            lineItemFormatOptions: [],
            lineItemCreativeProvider: null,
            lineItemAuditoriaMoat: null,
            lineItemLinkAMateriales: null,
            lineItemUrlDestino: null,
            lineItemTracking: null,
        });

        $('.selected').each(function () {
            $(this).click();
        })
        $('li[data-value="Seleccionar..."]').find('button').click();
    }

    copyMediaPlan2(e) {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.deal.media_plan_name);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    copyMediaPlanName(e) {
        /* Get the text field */
        var copyText = document.getElementById('mediaplanname-' + this.state.deal.id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        Helpers.notifyInfo(TextResourceHelper.get('label_copied'), TextResourceHelper.get('label_text_to_clipboard'));
    }

    toggleAmountUsd = (dealId) => () => {
        if ($("#amountusd-" + dealId).hasClass("d-none")) {
            $("#amount-" + dealId).addClass("d-none");
            $("#amountusd-" + dealId).removeClass("d-none");
        } else {
            $("#amountusd-" + dealId).addClass("d-none");
            $("#amount-" + dealId).removeClass("d-none");
        }
    }

    onChangeLineItemLinkAMateriales(e) {
        this.setState({
            lineItemLinkAMateriales: e.target.value
        });
    }
    onChangeLineItemUrlDestino(e) {
        this.setState({
            lineItemUrlDestino: e.target.value
        });
    }
    onChangeLineItemTracking(e) {
        this.setState({
            lineItemTracking: e.target.value
        });
    }
    onChangeLineItemLabel(e) {
        this.setState({
            lineItemLabel: e.target.value
        });
    }
    onChangeLineItemModeloDeCompra(e) {
        let modeloDeCompra = e.target.value;

        if (modeloDeCompra === "") {
            this.setState({
                lineItemModeloDeCompra: e.target.value,
                lineItemFormatOptions: [],
                lineItemFormat: null
            }, () => {
                this.calculateGoal();
            });
        } else {
            this.setState({
                lineItemModeloDeCompra: modeloDeCompra,
                lineItemFormat: null,
                lineItemFormatOptions: Helpers.getModelosDeCompra(modeloDeCompra)
            }, () => {
                this.calculateGoal();
            });
        }
    }
    onChangeLineItemSellRate(e) {
        this.setState({
            lineItemSellRate: e.target.value === "" ? '' : Helpers.roundNumber(parseFloat(e.target.value), 3)
        });
    }
    onChangeLineItemBonificacion(e) {
        this.setState({
            lineItemBonificacion: e.target.value === "" ? '' : parseInt(e.target.value)
        });
    }
    onChangeLineItemInversion(e) {
        this.setState({
            lineItemInversion: e.target.value === "" ? '' : Helpers.roundNumber(parseFloat(e.target.value), 2)
        });
    }
    onChangeLineItemFacturacionMensual(e) {
        this.setState({
            lineItemFacturacionMensual: e.target.checked
        });
    }
    onChangeLineItemFechaInicio(e) {
        this.setState({
            lineItemFechaInicio: e.target.value
        }, () => {
            this.validateLineItemAdsDirect();
        });
    }
    onChangeLineItemFechaFin(e) {
        this.setState({
            lineItemFechaFin: e.target.value
        }, () => {
            this.validateLineItemAdsDirect();
        });
    }
    onChangeLineItemCountry(selectedOptions) {
        let country = '';
        if (selectedOptions.selectedValue && typeof (selectedOptions.selectedValue[0]) !== "undefined")
            country = selectedOptions.selectedValue[0];

        this.setState({
            lineItemCountry: country
        });

    }
    onChangeLineItemDevices(selectedOptions) {
        let devices = selectedOptions.selectedValue.join(';');

        this.setState({
            lineItemDevices: devices
        });
    }
    onChangeLineItemGenderBoys(e) {
        let isSet = this.state.lineItemGenderBoys;
        this.setState({
            lineItemGenderBoys: !isSet
        });
    }
    onChangeLineItemGenderGirls(e) {
        let isSet = this.state.lineItemGenderGirls;
        this.setState({
            lineItemGenderGirls: !isSet
        });
    }
    onChangeLineItemAudiences(e) {
        this.setState({
            lineItemAudiences: { min: e.min, max: e.max }
        });
    }
    onChangeLineItemCategories(selectedOptions) {
        let categories = selectedOptions.selectedValue.join(';');

        this.setState({
            lineItemCategories: categories
        });
    }
    onChangeLineItemFrecuency(e) {
        this.setState({
            lineItemFrecuency: e.target.value
        });
    }
    onChangeLineItemBlUrl(e) {
        this.setState({
            lineItemBlUrl: e.target.value
        });
    }
    onChangeLineItemWlUrl(e) {
        this.setState({
            lineItemWlUrl: e.target.value
        });
    }
    onChangeLineItemFormat(e) {
        this.setState({
            lineItemFormat: e.target.value
        });
    }
    onChangeLineItemCreativeProvider(e) {
        this.setState({
            lineItemCreativeProvider: e.target.value
        });
    }
    onChangeLineItemAuditoriaMoat(e) {
        this.setState({
            lineItemAuditoriaMoat: e.target.value
        });
    }

    calculateGoal() {
        let modeloDeCompra = this.state.lineItemModeloDeCompra;
        let investment = this.state.lineItemInversion === "" ? 0 : this.state.lineItemInversion;
        let sellRate = this.state.lineItemSellRate === "" ? 0 : this.state.lineItemSellRate;
        let bonificacion = this.state.lineItemBonificacion === "" ? 0 : this.state.lineItemBonificacion;
        let goal = 0;
        let totalGoal = 0;

        // goal
        if (modeloDeCompra !== "" && sellRate > 0) {
            if (modeloDeCompra === "CPM")
                goal = (investment / sellRate) * 1000;
            else if (modeloDeCompra === "CPV" || modeloDeCompra === "CPCV")
                goal = (investment / sellRate);
        }

        // total goal
        totalGoal = goal + bonificacion;

        // net rate
        let netRate = 0;
        if (this.state.deal.kick_back !== null && this.state.deal.kick_back !== '' && goal > 0) {
            let kickBack = parseInt(this.state.deal.kick_back.replace('%', '').trim());
            netRate = (sellRate * (1 - (kickBack / 100))) - (sellRate * (bonificacion / goal));
        }

        this.setState({
            lineItemGoal: Helpers.roundNumber(goal, 3),
            lineItemTotalGoal: Helpers.roundNumber(totalGoal, 3),
            lineItemNetRate: Helpers.roundNumber(netRate, 4)
        }, () => {
            this.validateLineItemAdsDirect();
        });
    }

    onChangeLineItemPauseReason(e) {
        this.setState({
            lineItemPauseReason: e.target.value
        });
    }

    forceSaveLineItemAdsDirect(e) {
        e.preventDefault();
        $('#closeConfirmAdsDirectModal-' + this.state.deal.id).modal('hide');

        // force form submit
        var form = document.getElementById('newLiAdsDirectForm-' + this.state.deal.id);
        form.dispatchEvent(new Event('submit'));
    }

    saveLineItemAdsDirect(e) {
        e.preventDefault();

        let gender = null;
        if (this.state.lineItemGenderBoys === true && this.state.lineItemGenderGirls === true)
            gender = 0;
        else if (this.state.lineItemGenderBoys === true && this.state.lineItemGenderGirls === false)
            gender = 1;
        else if (this.state.lineItemGenderBoys === false && this.state.lineItemGenderGirls === true)
            gender = 2;


        var paisdef = this.state.lineItemCountry;
        if (paisdef == "Seleccionar...") {
            paisdef = "";
        }
        const data = {
            deal_id: this.state.deal.deal_id,
            label: this.state.lineItemLabel,
            modelo_de_compra: this.state.lineItemModeloDeCompra,
            sell_rate: this.state.lineItemSellRate,
            net_rate: this.state.lineItemNetRate,
            goal: this.state.lineItemGoal,
            bonificacion: this.state.lineItemBonificacion,
            total_goal: this.state.lineItemTotalGoal,
            investment: this.state.lineItemInversion,
            facturacion_mensual: this.state.lineItemFacturacionMensual,
            fecha_inicio: this.state.lineItemFechaInicio,
            fecha_finalizacion: this.state.lineItemFechaFin,
            pais: paisdef,
            devices: this.state.lineItemDevices,
            //audiences: this.state.lineItemAudiences,
            gender: gender,
            min_age: this.state.lineItemAudiences.min,
            max_age: this.state.lineItemAudiences.max,
            categoria_de_contenidos: this.state.lineItemCategories,
            frecuency: this.state.lineItemFrecuency,
            bl_url: this.state.lineItemBlUrl,
            wl_url: this.state.lineItemWlUrl,
            formato: this.state.lineItemFormat,
            creative_provider: this.state.lineItemCreativeProvider,
            auditoria_moat: this.state.lineItemAuditoriaMoat,
            link_a_materiales: this.state.lineItemLinkAMateriales,
            tracking: this.state.lineItemTracking,
            url_destino: this.state.lineItemUrlDestino,
            goal: this.state.lineItemGoal,
            total_goal: this.state.lineItemTotalGoal,
            line_item_type: LineItemHelper.awesome_ads_direct
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/save', data)
            .then((response) => {
                this.setState({
                    readOnly: true,
                    lineItemLabel: '',
                    lineItemModeloDeCompra: '',
                    lineItemSellRate: '',
                    lineItemNetRate: '',
                    lineItemGoal: '',
                    lineItemTotalGoal: '',
                    lineItemBonificacion: '',
                    lineItemInversion: '',
                    lineItemFechaInicio: '',
                    lineItemFechaFin: '',
                    lineItemGenderBoys: false,
                    lineItemGenderGirls: false,
                    lineItemAudiences: { min: 0, max: 17 },
                    lineItemFrecuency: '',
                    lineItemBlUrl: null,
                    lineItemWlUrl: null,
                    lineItemFormat: '',
                    lineItemFormatOptions: [],
                    lineItemCreativeProvider: '',
                    lineItemAuditoriaMoat: '',
                    lineItemLinkAMateriales: null,
                    lineItemUrlDestino: null,
                    lineItemTracking: '',
                }, () => {
                    this.refreshDeal();
                });

                let form = document.getElementById('newLiAdsDirectForm-' + this.state.deal.id);
                form.classList.remove("was-validated");
                this.closeFormModals();

                let msg = TextResourceHelper.get('successmessage_data_saved');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }
    validateLineItemAdsDirect(e) {
        let userData = SessionHelper.getUserData();
        let today = new Date().toISOString().split('T')[0];
        let isValid = true;
        this.setState({
            lineItemModeloDeCompraValid: true,
            lineItemModeloDeCompraTooltip: '',
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemFechaInicioValid: true,
            lineItemFechaInicioTooltip: '',
            lineItemFechaFinValid: true,
            lineItemFechaFinTooltip: '',
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemDevicesValid: true,
            lineItemDevicesTooltip: '',
            lineItemGenderValid: true,
            lineItemGenderTooltip: '',
            lineItemCategoryValid: true,
            lineItemCategoryTooltip: '',
            lineItemFrecuencyValid: true,
            lineItemFrecuencyTooltip: '',
            lineItemFormatValid: true,
            lineItemFormatTooltip: '',
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
            lineItemAuditoriaMoatValid: true,
            lineItemAuditoriaMoatTooltip: '',
            lineItemLinkAMaterialesValid: true,
            lineItemLinkAMaterialesTooltip: '',
            lineItemTrackingValid: true,
            lineItemTrackingTooltip: '',
            lineItemUrlDestinoValid: true,
            lineItemUrlDestinoTooltip: ''
        });

        document.getElementById("fechainicio-" + this.state.deal.deal_id).setCustomValidity("");
        document.getElementById("fechafin-" + this.state.deal.deal_id).setCustomValidity("");
        document.getElementById("investment-" + this.state.deal.deal_id).setCustomValidity("");
        $(".selectcountry, .selectdevices, .selectcategories, .btnGender").removeClass("invalidfield");

        // modelo de compra
        if (this.state.lineItemModeloDeCompra === null || this.state.lineItemModeloDeCompra === "") {
            isValid = false;
            this.setState({ lineItemModeloDeCompraValid: false, lineItemModeloDeCompraTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // sell rate
        if (this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
            isValid = false;
            this.setState({ lineItemSellRateValid: false, lineItemSellRateTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // bonificacion
        if (this.state.lineItemBonificacion === null || this.state.lineItemBonificacion === "") {
            this.setState({ lineItemBonificacion: 0 });
        }

        // inversion
        if (this.state.lineItemInversion === null || this.state.lineItemInversion === "") {
            isValid = false;
            this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        } else {
            if (this.state.lineItemModeloDeCompra === null || this.state.lineItemModeloDeCompra === "" || this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
                isValid = false;
                this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_investment_invalid') });
                document.getElementById("investment-" + this.state.deal.deal_id).setCustomValidity(TextResourceHelper.get('errormessage_investment_invalid'));
            } else {
                // Si �modelo de compra� es �CPM� entonces �Inversi�n� debe ser igual a((�Goal� * �sell rate�) / 1000).
                if (this.state.lineItemModeloDeCompra === "CPM" && this.state.lineItemInversion !== ((this.state.lineItemGoal * this.state.lineItemSellRate) / 1000)) {
                    isValid = false;
                    this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_investment_invalid') });
                    document.getElementById("investment-" + this.state.deal.deal_id).setCustomValidity(TextResourceHelper.get('errormessage_investment_invalid'));
                }
                // Si �modelo de compra� es �CPV� o �CPCV� entonces �Inversi�n� debe ser igual a (�Goal� * �sell rate�)
                if ((this.state.lineItemModeloDeCompra === "CPV" || this.state.lineItemModeloDeCompra === "CPCV") && this.state.lineItemInversion !== (this.state.lineItemGoal * this.state.lineItemSellRate)) {
                    isValid = false;
                    this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_investment_invalid') });
                    document.getElementById("investment-" + this.state.deal.deal_id).setCustomValidity(TextResourceHelper.get('errormessage_investment_invalid'));
                }
            }
        }

        // fecha inicio
        if (this.state.lineItemFechaInicio === null || this.state.lineItemFechaInicio === "") {
            isValid = false;
            this.setState({
                lineItemFechaInicioValid: false,
                lineItemFechaInicioTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty')
            });
        } else if (this.state.lineItemFechaInicio < today) {
            isValid = false;
            this.setState({
                lineItemFechaInicioValid: false,
                lineItemFechaInicioTooltip: TextResourceHelper.get('errormessage_fechainicio_cannot_be_lower_now')
            });
            document.getElementById("fechainicio-" + this.state.deal.deal_id).setCustomValidity(TextResourceHelper.get('errormessage_fechainicio_cannot_be_lower_now'));
        }

        // fecha finalizacion
        if (this.state.lineItemFechaFin === null || this.state.lineItemFechaFin === "") {
            isValid = false;
            this.setState({
                lineItemFechaFinValid: false,
                lineItemFechaFinTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty')
            });
        } else if (this.state.lineItemFechaInicioValid && this.state.lineItemFechaFin < this.state.lineItemFechaInicio) {
            isValid = false;
            this.setState({
                lineItemFechaFinValid: false,
                lineItemFechaFinTooltip: TextResourceHelper.get('errormessage_fechafin_cannot_be_lower_fechainicio')
            });
            document.getElementById("fechafin-" + this.state.deal.deal_id).setCustomValidity(TextResourceHelper.get('errormessage_fechafin_cannot_be_lower_fechainicio'));
        }

        // country
        if (this.state.lineItemCountry === null || this.state.lineItemCountry === "" || this.state.lineItemCountry === "Seleccionar...") {
            isValid = false;
            $(".selectcountry").addClass("invalidfield");
            this.setState({ lineItemCountryValid: false, lineItemCountryTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // devices
        if (this.state.lineItemDevices === null || this.state.lineItemDevices === "") {
            isValid = false;
            $(".selectdevices").addClass("invalidfield");
            this.setState({ lineItemDevicesValid: false, lineItemDevicesTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // gender
        if (this.state.lineItemGenderBoys === false && this.state.lineItemGenderGirls === false) {
            isValid = false;
            $(".btnGender").addClass("invalidfield");
            this.setState({ lineItemGenderValid: false, lineItemGenderTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // category
        if (this.state.lineItemCategories === null || this.state.lineItemCategories === "") {
            isValid = false;
            $(".selectcategories").addClass("invalidfield");
            this.setState({ lineItemCategoriesValid: false, lineItemCategoriesTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // frecuency
        if (this.state.lineItemFrecuency === null || this.state.lineItemFrecuency === "") {
            isValid = false;
            this.setState({ lineItemFrecuencyValid: false, lineItemFrecuencyTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // format
        if (this.state.lineItemFormat === null || this.state.lineItemFormat === "") {
            isValid = false;
            this.setState({ lineItemFormatValid: false, lineItemFormatTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        // creative provider
        if (this.state.lineItemCreativeProvider === null || this.state.lineItemCreativeProvider === "") {
            isValid = false;
            this.setState({ lineItemCreativeProviderValid: false, lineItemCreativeProviderTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        // auditoria moat
        if (this.state.lineItemAuditoriaMoat === null || this.state.lineItemAuditoriaMoat === "") {
            isValid = false;
            this.setState({ lineItemAuditoriaMoatValid: false, lineItemAuditoriaMoatTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        if (userData.group === SessionHelper.group_ms) {
            // link to materials
            if (this.state.lineItemLinkAMateriales === null || this.state.lineItemLinkAMateriales === "") {
                isValid = false;
                this.setState({ lineItemLinkAMaterialesValid: false, lineItemLinkAMaterialesTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
            }

            // tracking
            if (this.state.lineItemTracking === null || this.state.lineItemTracking === "") {
                isValid = false;
                this.setState({ lineItemTrackingValid: false, lineItemTrackingTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
            }

            // url destino
            if (this.state.lineItemUrlDestino === null || this.state.lineItemUrlDestino === "") {
                isValid = false;
                this.setState({ lineItemUrlDestinoValid: false, lineItemUrlDestinoTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
            }
        }

        var form = document.getElementById('newLiAdsDirectForm-' + this.state.deal.id);
        form.checkValidity();
        form.classList.add('was-validated');
    }

    forceSaveLineItemAdsDeal(e) {
        e.preventDefault();
        $('#closeConfirmAdsDealModal-' + this.state.deal.id).modal('hide');

        // force form submit
        var form = document.getElementById('newLiAdsDealForm-' + this.state.deal.id);
        form.dispatchEvent(new Event('submit'));
    }

    saveLineItemAdsDeal(e) {
        e.preventDefault();

        var paisdef = this.state.lineItemCountry;
        if (paisdef == "Seleccionar...") {
            paisdef = "";
        }
        const data = {
            deal_id: this.state.deal.deal_id,
            label: this.state.lineItemLabel,
            sell_rate: this.state.lineItemSellRate,
            investment: this.state.lineItemInversion,
            pais: paisdef,
            creative_provider: this.state.lineItemCreativeProvider,
            line_item_type: LineItemHelper.awesome_ads_deal
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/lineitem/save', data)
            .then((response) => {

                this.setState({
                    readOnly: true,
                    lineItemLabel: '',
                    lineItemModeloDeCompra: null,
                    lineItemSellRate: '',
                    lineItemNetRate: '',
                    lineItemGoal: '',
                    lineItemTotalGoal: '',
                    lineItemBonificacion: '',
                    lineItemInversion: '',
                    lineItemFechaInicio: null,
                    lineItemFechaFin: null,
                    lineItemCountryOptions: Helpers.getLineItemCountries(),
                    lineItemDevicesOptions: Helpers.getLineItemDevices(''),
                    lineItemGenderBoys: false,
                    lineItemGenderGirls: false,
                    lineItemAudiences: { min: 0, max: 17 },
                    lineItemCategoriesOptions: Helpers.getLineItemCategories(''),
                    lineItemBlUrl: null,
                    lineItemWlUrl: null,
                    lineItemFormatOptions: [],
                    lineItemLinkAMateriales: null,
                    lineItemUrlDestino: null,
                    lineItemFrecuency: '',
                    lineItemFormat: '',
                    lineItemCreativeProvider: '',
                    lineItemAuditoriaMoat: '',
                    lineItemTracking: '',
                }, () => {
                    this.refreshDeal();
                });

                let form = document.getElementById('newLiAdsDealForm-' + this.state.deal.id);
                form.classList.remove("was-validated");
                this.closeFormModals();

                let msg = TextResourceHelper.get('successmessage_data_saved');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    validateLineItemAdsDeal(e) {
        let isValid = true;
        this.setState({
            lineItemSellRateValid: true,
            lineItemSellRateTooltip: '',
            lineItemInversionValid: true,
            lineItemInversionTooltip: '',
            lineItemCountryValid: true,
            lineItemCountryTooltip: '',
            lineItemCreativeProviderValid: true,
            lineItemCreativeProviderTooltip: '',
        });

        $(".selectcountry").removeClass("invalidfield");

        // sell rate
        if (this.state.lineItemSellRate === null || this.state.lineItemSellRate === "") {
            isValid = false;
            this.setState({ lineItemSellRateValid: false, lineItemSellRateTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // inversion
        if (this.state.lineItemInversion === null || this.state.lineItemInversion === "") {
            isValid = false;
            this.setState({ lineItemInversionValid: false, lineItemInversionTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // country
        if (this.state.lineItemCountry === null || this.state.lineItemCountry === "" || this.state.lineItemCountry === "Seleccionar...") {
            isValid = false;
            $(".selectcountry").addClass("invalidfield");
            this.setState({ lineItemCountryValid: false, lineItemCountryTooltip: TextResourceHelper.get('errormessage_field_cannot_be_empty') });
        }

        // creative provider
        if (this.state.lineItemCreativeProvider === null || this.state.lineItemCreativeProvider === "") {
            isValid = false;
            this.setState({ lineItemCreativeProviderValid: false, lineItemCreativeProviderTooltip: TextResourceHelper.get('errormessage_cannot_be_empty') });
        }

        var form = document.getElementById('newLiAdsDealForm-' + this.state.deal.id);
        form.checkValidity();
        form.classList.add('was-validated');
    }

    pauseLineItems(e) {
        e.preventDefault();

        var form = document.getElementById('pauseLiReasonForm-' + this.state.deal.id);
        if (!form.checkValidity())
            return;

        const data = {
            line_item_ids: this.state.pauseLineItemIds,
            pause_reason: this.state.lineItemPauseReason
        };

        axios.post(window.config.REACT_APP_API_URL + '/deal/' + this.state.deal.deal_id + '/lineitems/pause', data)
            .then((response) => {

                this.setState({
                    pauseLineItemIds: [],
                    pauseAllLineItems: false,
                    lineItemPauseReason: ''
                }, () => {
                    this.refreshDeal();
                });

                form.classList.remove("was-validated");
                $('.modal').modal('hide'); // closes all active pop ups.
                $('.modal-backdrop').remove(); // removes the grey overlay.

                let msg = TextResourceHelper.get('successmessage_lineitems_paused');
                Helpers.notifySuccess(TextResourceHelper.get('label_message'), msg);
            })
            .catch((error) => {
                console.log(error);
                let msg = TextResourceHelper.get('errormessage_unknown_error');
                Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
            });
    }

    getMsOwners() {
        var owners = localStorage.getItem("OwnersMs");
        var result = JSON.parse(owners);
        this.setState({
            msOwners: result
        });
    }

    getAdopsOwners() {
        var owners = localStorage.getItem("OwnersAdops");
        var result = JSON.parse(owners);
        this.setState({
            adopsOwners: result
        });

    }

    getSalesOwners() {
        var owners = localStorage.getItem("OwnersSales");
        var result = JSON.parse(owners);
        this.setState({
            salesOwners: result
        });

    }

    havePermission(namePermission) {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == namePermission)
                result = true;
        });

        return result;
    }
    isOwner() {
        let userData = SessionHelper.getUserData();
        let result = false;

        Object.entries(userData.Permissions).forEach(([key, value]) => {
            if (value == "allRead" || value == "allWrite")
                result = true;
        });

        return result;
    }
    changeStatusDeal = (dealId, status) => {
        this.setState({
            changeStatusDealId: dealId,
            changeStatusStatus: status
        });
    }
    onClickChangeStatusAccept(e) {

        axios.post(window.config.REACT_APP_API_URL + '/deal/ChangeStatusDeal/' + this.state.changeStatusDealId, this.state.changeStatusStatus)
            .then(() => {

                $('#changeStatusDealModal-' + this.state.changeStatusDealId).modal('hide');

                this.setState({
                    changeStatusDealId: 0,
                    changeStatusStatus: 0
                });

                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    renderComponentMS(canDelegateMediaPlanToMs, msOwner, msOwnerFullName, userData) {
        if (canDelegateMediaPlanToMs || userData.allWrite || this.havePermission("asignarMediaMS")) {
            return (
                <div class="dropdown">
                    <a className="tooltip-alert-trigger" id={'dropdownMenuButtonMs-' + this.state.deal.deal_id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{msOwnerFullName}</Tooltip>}>
                            <div className="mediaplan-avatar dropdown-toggle ml-1 cursor-pointer">
                                <h6>
                                    {msOwner === '' && <i className="fa fa-user"></i>}
                                    {msOwner !== '' && msOwner}
                                </h6>
                            </div>
                        </OverlayTrigger>
                    </a>
                    <div class="dropdown-menu" aria-labelledby={'dropdownMenuButtonMs-' + this.state.deal.deal_id}>
                        {this.state.msOwners.map(msOwner =>
                            <a key={msOwner.id} onClick={this.delegateMediaPlanToMs(msOwner.email)} class="dropdown-item cursor-pointer">{msOwner.first_name + ' ' + msOwner.last_name}</a>
                        )}
                    </div>
                </div>
            );
        } else if (userData.allRead && msOwner !== '') {
            return (
                <div>
                    <a className="tooltip-alert-trigger">
                        <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{msOwnerFullName}</Tooltip>}>
                            <div className="mediaplan-avatar ml-1 cursor-pointer">
                                <h6>
                                    {msOwner === '' && <i className="fa fa-user"></i>}
                                    {msOwner !== '' && msOwner}
                                </h6>
                            </div>
                        </OverlayTrigger>
                    </a>
                </div>
            );
        }
    }
    renderComponentAdops(canDelegateMediaPlanToAdops, adopsOwner, adopsOwnerFullName, userData) {

        if (canDelegateMediaPlanToAdops || userData.allWrite || this.havePermission("asignarMediaAdops")) {
            return (
                <div class="dropdown">
                    <a className="tooltip-alert-trigger" id={'dropdownMenuButtonAdops-' + this.state.deal.deal_id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{adopsOwnerFullName}</Tooltip>}>
                            <div className="mediaplan-avatar dropdown-toggle ml-1 cursor-pointer">
                                <h6>
                                    {adopsOwner === '' && <i className="fa fa-user"></i>}
                                    {adopsOwner !== '' && adopsOwner}
                                </h6>
                            </div>
                        </OverlayTrigger>
                    </a>
                    <div class="dropdown-menu" aria-labelledby={'dropdownMenuButtonAdops-' + this.state.deal.deal_id}>
                        {this.state.adopsOwners.map(adopsOwner =>
                            <a key={adopsOwner.id} onClick={this.delegateMediaPlanToAdops(adopsOwner.email)} class="dropdown-item cursor-pointer">{adopsOwner.first_name + ' ' + adopsOwner.last_name}</a>
                        )}
                    </div>
                </div>
            );
        } else if (userData.allRead && adopsOwner !== '') {
            return (
                <div>
                    <a className="tooltip-alert-trigger">
                        <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{adopsOwnerFullName}</Tooltip>}>
                            <div className="mediaplan-avatar ml-1 cursor-pointer">
                                <h6>
                                    {adopsOwner === '' && <i className="fa fa-user"></i>}
                                    {adopsOwner !== '' && adopsOwner}
                                </h6>
                            </div>
                        </OverlayTrigger>
                    </a>
                </div>
            );
        }
    }
    renderComponentSales(canDelegateMediaPlanToSales, salesOwner, salesOwnerFullName, userData) {

        if ((canDelegateMediaPlanToSales || userData.allWrite || this.havePermission("asignarMediaSales"))) {
            return (
                <div class="dropdown">
                    <a className="tooltip-alert-trigger" id={'dropdownMenuButtonSales-' + this.state.deal.deal_id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{salesOwnerFullName}</Tooltip>}>
                            <div className="mediaplan-avatar dropdown-toggle ml-1 cursor-pointer">
                                <h6>
                                    {salesOwner === '' && <i className="fa fa-user"></i>}
                                    {salesOwner !== '' && salesOwner}
                                </h6>
                            </div>
                        </OverlayTrigger>
                    </a>
                    <div class="dropdown-menu" aria-labelledby={'dropdownMenuButtonSales-' + this.state.deal.deal_id}>
                        {this.state.salesOwners.map(salesOwner =>
                            <a key={salesOwner.id} onClick={this.delegateMediaPlanToSales(salesOwner.email)} class="dropdown-item cursor-pointer">{salesOwner.first_name + ' ' + salesOwner.last_name}</a>
                        )}
                    </div>

                </div>);
        } else if (userData.allRead && salesOwner !== '') {
            return (
                <div>
                    <a className="tooltip-alert-trigger">
                        <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{salesOwnerFullName}</Tooltip>}>
                            <div className="mediaplan-avatar ml-1 cursor-pointer">
                                <h6>
                                    {salesOwner === '' && <i className="fa fa-user"></i>}
                                    {salesOwner !== '' && salesOwner}
                                </h6>
                            </div>
                        </OverlayTrigger>
                    </a>
                </div>
            );
        }

    }
    render() {
        if (this.state.sentToValidation === true)
            return <Redirect to="/deal-senttoms" />;

        if (this.state.sentToOT === true)
            return <Redirect to="/deal-senttoot" />;

        let userData = SessionHelper.getUserData();

        let canSendToPreparation = false;
        if (this.state.deal.items.length > 0) {
            for (var i = 0; i < this.state.deal.items.length; i++) {
                if (this.state.deal.items[i].status === LineItemStatusHelper.confirmed) {
                    canSendToPreparation = true;
                    break;
                }
            }
        }

        let canSendToAdops = false;
        if (this.state.deal.items.length > 0) {
            for (var i = 0; i < this.state.deal.items.length; i++) {
                if (this.state.deal.items[i].status === LineItemStatusHelper.to_implement) {
                    canSendToAdops = true;
                    break;
                }
            }
        }

        let showSendToAdopsButton = canSendToAdops
            && userData.group === SessionConfig.group_ms
            && this.state.deal.status === DealHelper.status_preparation
            && this.state.deal.items.some(x => x.status === LineItemStatusHelper.to_implement);

        // status
        let statusCssClass = Helpers.getDealStatusCssClass(this.state.deal.status);

        // ms owner
        let msOwner = '';
        let msOwnerFullName = '';
        if (this.state.deal.cs_owner_last_name !== null
            && this.state.deal.cs_owner_last_name !== ''
            && this.state.deal.cs_owner_first_name !== null
            && this.state.deal.cs_owner_first_name !== '') {
            msOwner = this.state.deal.cs_owner_first_name[0] + this.state.deal.cs_owner_last_name[0];
            msOwnerFullName = this.state.deal.cs_owner_first_name + " " + this.state.deal.cs_owner_last_name;
        }

        // sales owner
        let salesOwner = '';
        let salesOwnerFullName = '';
        if (this.state.deal.sales_owner_last_name !== null
            && this.state.deal.sales_owner_last_name !== ''
            && this.state.deal.sales_owner_first_name !== null
            && this.state.deal.sales_owner_first_name !== '') {
            salesOwner = this.state.deal.sales_owner_first_name[0] + this.state.deal.sales_owner_last_name[0];
            salesOwnerFullName = this.state.deal.sales_owner_first_name + " " + this.state.deal.sales_owner_last_name;
        }

        // adops owner
        let adopsOwner = '';
        let adopsOwnerFullName = '';
        if (this.state.deal.adops_owner_last_name !== null
            && this.state.deal.adops_owner_last_name !== ''
            && this.state.deal.adops_owner_first_name !== null
            && this.state.deal.adops_owner_first_name !== '') {
            adopsOwner = this.state.deal.adops_owner_first_name[0] + this.state.deal.adops_owner_last_name[0];
            adopsOwnerFullName = this.state.deal.adops_owner_first_name + " " + this.state.deal.adops_owner_last_name;
        }

        let canDelegateMediaPlanToMs = msOwner !== ''
            && userData.group === SessionConfig.group_ms;

        let canDelegateMediaPlanToAdops = userData.group === SessionConfig.group_adops;

        let canDelegateMediaPlanToSales = userData.group === SessionConfig.group_sales;

        let showActivateMediaPlanButton = userData.group === SessionConfig.group_adops
            && this.state.deal.status === DealHelper.status_implementation
            && this.state.deal.items !== null
            && this.state.deal.items.length > 0
            && this.state.deal.items.some(x => x.status === LineItemStatusHelper.active);

        let showPauseLineItemsButton = (this.state.deal.status === DealHelper.status_implementation || this.state.deal.status === DealHelper.status_active) && (this.havePermission("pausarLineItem") || userData.allWrite) ;
        //if (userData.group === SessionConfig.group_sales && (this.state.deal.status === DealHelper.status_preparation || this.state.deal.status === DealHelper.status_implementation || this.state.deal.status === DealHelper.status_active)) {
        //    showPauseLineItemsButton = true;
        //} else if (userData.group === SessionConfig.group_ms && (this.state.deal.status === DealHelper.status_implementation || this.state.deal.status === DealHelper.status_active)) {
        //    showPauseLineItemsButton = true;
        //}

        // alerts
        let advertiserAlert = '';
        let campaignAlert = '';
        let fechaInicioAlert = '';
        let fechaFinAlert = '';
        let presupuestoAlert = '';
        let monedaAlert = '';
        let linkToProposalAlert = '';
        let linkOpAlert = '';

        if (this.state.deal.alerts.length > 0) {
            // empty_fields
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('nombre_anunciante') !== -1) !== undefined)
                advertiserAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('campaign') !== -1) !== undefined)
                campaignAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('fecha_inicio') !== -1) !== undefined)
                fechaInicioAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('fecha_fin') !== -1) !== undefined)
                fechaFinAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('monto_de_la_orden') !== -1) !== undefined)
                presupuestoAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('moneda_de_la_orden') !== -1) !== undefined)
                monedaAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('link_a_propuesta_pdf') !== -1) !== undefined)
                linkToProposalAlert = TextResourceHelper.get('errormessage_cannot_be_empty');
            if (this.state.deal.alerts.find(x => x.rule_type == 11 && x.description.indexOf('link_orden_de_publicidad_op') !== -1) !== undefined)
                linkOpAlert = TextResourceHelper.get('errormessage_cannot_be_empty');

            // fecha_de_finalizacion_fecha_de_inicio_formato
            if (this.state.deal.alerts.find(x => x.rule_type == 7) !== undefined)
                fechaFinAlert = TextResourceHelper.get('errormessage_cannot_be_lower_startdate');

            // amount_investment
            if (this.state.deal.alerts.find(x => x.rule_type == 3) !== undefined)
                presupuestoAlert = TextResourceHelper.get('errormessage_amount_investment');

            // country_currency
            if (this.state.deal.alerts.find(x => x.rule_type == 4) !== undefined)
                monedaAlert = TextResourceHelper.get('errormessage_country_currency');
        }

        let IsUserOwner =  this.state.deal.adops_owner_email == userData.email
            || this.state.deal.sales_owner_email == userData.email
            || this.state.deal.cs_owner_email == userData.email;

        return (

            <div key={this.state.deal.id} className="table-responsive">
                <table className="table campaigns-table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="text-right">
                                    {
                                        this.renderComponentMS(canDelegateMediaPlanToMs, msOwner, msOwnerFullName, userData)
                                    }
                                    {
                                        this.renderComponentAdops(canDelegateMediaPlanToAdops, adopsOwner, adopsOwnerFullName, userData)
                                    }
                                    {
                                        this.renderComponentSales(canDelegateMediaPlanToSales, salesOwner, salesOwnerFullName, userData)
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '0' }}>
                                {/*ROW HEADER*/}
                                <table>
                                    <tr>
                                        <th className="fit">{TextResourceHelper.get('label_hs_deal_id')}</th>
                                        <th className="data fit">{this.state.deal.deal_id}</th>
                                        <th className="fit">
                                            {advertiserAlert === '' && TextResourceHelper.get('label_advertiser')}
                                            {
                                                advertiserAlert !== '' &&
                                                <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{advertiserAlert}</Tooltip>}>
                                                    <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_advertiser')}</a>
                                                </OverlayTrigger>
                                            }
                                        </th>
                                        <th className="data fit">{this.state.deal.nombre_anunciante == null ? '---' : this.state.deal.nombre_anunciante}</th>
                                        <th className="fit">
                                            {campaignAlert === '' && TextResourceHelper.get('label_campaign_name')}
                                            {
                                                campaignAlert !== '' &&
                                                <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{campaignAlert}</Tooltip>}>
                                                    <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_campaign_name')}</a>
                                                </OverlayTrigger>
                                            }
                                        </th>
                                        <th className="data fit">{this.state.deal.campaign == null ? '---' : this.state.deal.campaign}</th>
                                        <th className="fit">{TextResourceHelper.get('label_mediaplan_id')}</th>
                                        <th className="data">{this.state.deal.id}</th>
                                        <th className="data fit">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_dealid_created_date')}</Tooltip>}>
                                                <a><div className="icon calendar align-middle" style={{ backgroundImage: 'url(' + iconCalendar + ')' }}></div> <span className="ml-1">{this.state.deal.createdate === null ? '---' : Helpers.getDisplayDate(this.state.deal.createdate)}</span></a>
                                            </OverlayTrigger>
                                        </th>
                                        <th className="data fit">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_idle_time')}</Tooltip>}>
                                                <a><div className="icon time align-middle" style={{ backgroundImage: 'url(' + iconTime + ')' }}></div> <span className="ml-1"><ReactTimeAgo date={Helpers.getDateTimeFromUtc(this.state.deal.last_update)} locale="en-US" /></span></a>
                                            </OverlayTrigger>
                                        </th>
                                    </tr>
                                </table>

                                {/*MEDIA PLAN NAME*/}
                                <table className="w-100">
                                    <tr className={statusCssClass}>
                                        <td style={{ width: '100%' }}>
                                            <Link to={"/deal-detail/" + this.state.deal.deal_id} className="mediaplanname">{this.state.deal.media_plan_name}</Link>
                                            <div onClick={this.copyMediaPlan2} class="btn btn-default" style={{}}><i class="far fa-clone"></i></div>
                                        </td>
                                        {
                                            (
                                                userData.role == 1 || IsUserOwner
                                            ) &&
                                            <td className="fit">
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id={'dropdownMenuButton-' + this.state.deal.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {TextResourceHelper.get('change_status')}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby={'dropdownMenuButton-' + this.state.deal.id}>
                                                        <a className="dropdown-item status-kickoff" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 1)}>{TextResourceHelper.get('label_status_kickoff')}</a>
                                                        <a className="dropdown-item status-preparation" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 2)}>{TextResourceHelper.get('label_status_preparation')}</a>
                                                        <a className="dropdown-item status-implementation" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 3)}>{TextResourceHelper.get('label_status_implementation')}</a>
                                                        {/*<a className="dropdown-item status-active" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 4)}>{TextResourceHelper.get('lineitemstatus_pause')}</a>*/}
                                                        <a className="dropdown-item status-active" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 5)}>{TextResourceHelper.get('label_status_active')}</a>
                                                        {/*<a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 6)}>{TextResourceHelper.get('label_status_paused')}</a>*/}
                                                        <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 7)}>{TextResourceHelper.get('label_status_paused_finished')}</a>
                                                        <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 8)}>{TextResourceHelper.get('lineitemstatus_to_bill')}</a>
                                                        <a className="dropdown-item status-paused-finished" data-toggle="modal" data-target={'#changeStatusDealModal-' + this.state.deal.id} onClick={() => this.changeStatusDeal(this.state.deal.id, 9)}>{TextResourceHelper.get('lineitemstatus_billed')}</a>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        {
                                            this.state.deal.status === DealHelper.status_kick_off && (this.havePermission("importarLineItem") || userData.allWrite) &&
                                            <td className="fit">
                                                <button className="btn btn-light btn-sm" type="button" data-toggle="modal" data-target={'#importLiModal-' + this.state.deal.deal_id}>{TextResourceHelper.get('buttonlabel_import_lineitems')}</button>
                                            </td>
                                        }
                                        {
                                            this.state.deal.status === DealHelper.status_preparation && (this.havePermission("exportarMediaPlans") || userData.allWrite) &&
                                            <td className="fit">
                                                <a target="_blank" href={window.config.REACT_APP_API_URL + '/deal/' + this.state.deal.deal_id + '/export/pdf/' + TextResourceHelper.getUiLanguage()} className="btn btn-light btn-sm" type="button">{TextResourceHelper.get('buttonlabel_export_mediaplan')}</a>
                                            </td>
                                        }
                                        {
                                            canSendToPreparation && (this.havePermission("prepararLineItem") || userData.allWrite) &&
                                            <td className="fit">
                                                <button data-toggle="modal" data-target={'#prepareConfirmModal-' + this.state.deal.id} className="btn btn-light btn-sm" type="button">{TextResourceHelper.get('buttonlabel_prepare_mediaplan')}</button>
                                            </td>
                                        }
                                        {
                                            showSendToAdopsButton &&
                                            <td className="fit">
                                                <button onClick={this.sendToAdops} className="btn btn-light btn-sm" type="button">{TextResourceHelper.get('buttonlabel_send_to_adops')}</button>
                                            </td>
                                        }
                                        {
                                            showPauseLineItemsButton &&
                                            <td className="fit">
                                                <button className="btn btn-light btn-sm" type="button" data-toggle="modal" data-target={'#pauseLiModal-' + this.state.deal.id}>{TextResourceHelper.get('buttonlabel_pause_lineitems')}</button>
                                            </td>
                                        }
                                        {
                                            this.state.deal.status === DealHelper.status_paused &&
                                            <td className="fit">
                                                <button className="btn btn-light btn-sm" type="button">{TextResourceHelper.get('label_status_paused')}</button>
                                            </td>
                                        }
                                        {
                                            showActivateMediaPlanButton &&
                                            <td className="fit">
                                                <button onClick={this.activateMediaPlan} className="btn btn-light btn-sm" type="button">{TextResourceHelper.get('buttonlabel_activate_mediaplan')}</button>
                                            </td>
                                        }
                                        {
                                            (this.state.deal.status === DealHelper.status_kick_off || this.state.deal.status === DealHelper.status_active || this.state.deal.status === DealHelper.status_implementation || this.state.deal.status === DealHelper.status_preparation) && (this.havePermission("crearNuevoLineItem") || userData.allWrite) &&
                                            <td className="fit">
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id={'dropdownMenuButton-' + this.state.deal.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {TextResourceHelper.get('buttonlabel_add_lineitem')}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby={'dropdownMenuButton-' + this.state.deal.id}>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDirectModal-' + this.state.deal.id}>{TextResourceHelper.get('label_awesome_ads_direct')}</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDealModal-' + this.state.deal.id}>{TextResourceHelper.get('label_awesome_ads_deal')}</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDirectModal-' + this.state.deal.id}>{TextResourceHelper.get('label_ksv')}</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDirectModal-' + this.state.deal.id}>{TextResourceHelper.get('label_gaming_roblox')}</a>
                                                    </div>
                                                </div>
                                                    
                                            </td>
                                        }
                                    </tr>
                                </table>

                                {/*ROW DATA*/}
                                <table>
                                    <tr>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {fechaInicioAlert === '' && TextResourceHelper.get('label_start')}
                                                {
                                                    fechaInicioAlert !== '' &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{fechaInicioAlert}</Tooltip>}>
                                                        <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_start')}</a>
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <span>{this.state.deal.fecha_inicio === null ? '---' : Helpers.getDisplayDate(this.state.deal.fecha_inicio)}</span>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {fechaFinAlert === '' && TextResourceHelper.get('label_end')}
                                                {
                                                    fechaFinAlert !== '' &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{fechaFinAlert}</Tooltip>}>
                                                        <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_end')}</a>
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <span>{this.state.deal.fecha_fin === null ? '---' : Helpers.getDisplayDate(this.state.deal.fecha_fin)}</span>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {presupuestoAlert === '' && TextResourceHelper.get('label_budget')}
                                                {
                                                    presupuestoAlert !== '' &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{presupuestoAlert}</Tooltip>}>
                                                        <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_budget')}</a>
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <span id={'amount-' + this.state.deal.deal_id} className="">{this.state.deal.monto_de_la_orden === null ? '---' : Helpers.thousandSeparator(this.state.deal.monto_de_la_orden)}</span>
                                            <span id={'amountusd-' + this.state.deal.deal_id} className="d-none">{this.state.deal.amount_usd === null ? '---' : Helpers.thousandSeparator(this.state.deal.amount_usd)}</span>
                                            <a className="ml-1 align-text-top cursor-pointer" onClick={this.toggleAmountUsd(this.state.deal.deal_id)}><div className="icon info-sm"></div></a>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">{TextResourceHelper.get('label_purchase_models')}</label>
                                            <span>{this.state.deal.modelo_de_compra === null ? '---' : this.state.deal.modelo_de_compra}</span>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {monedaAlert === '' && TextResourceHelper.get('label_currency')}
                                                {
                                                    monedaAlert !== '' &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{monedaAlert}</Tooltip>}>
                                                        <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_currency')}</a>
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            <span>{this.state.deal.moneda_de_la_orden === null ? '---' : this.state.deal.moneda_de_la_orden}</span>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">{TextResourceHelper.get('label_market')}</label>
                                            <span>{this.state.deal.mercado === null ? '---' : this.state.deal.mercado}</span>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">{TextResourceHelper.get('label_kickback')}</label>
                                            <span>{this.state.deal.kick_back === null ? '---' : this.state.deal.kick_back}</span>
                                        </td>
                                        <td className="text-center">
                                            <label className="font-weight-bold mb-1 d-block">{TextResourceHelper.get('label_formats')}</label>
                                            <span>{this.state.deal.formatos === null ? '---' : this.state.deal.formatos}</span>
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {linkToProposalAlert === '' && TextResourceHelper.get('label_link_to_proposal')}
                                                {
                                                    linkToProposalAlert !== '' &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{linkToProposalAlert}</Tooltip>}>
                                                        <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_link_to_proposal')}</a>
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            {this.state.deal.link_a_propuesta_pdf !== null && this.state.deal.link_a_propuesta_pdf !== '' && <span><a target="_blank" href={this.state.deal.link_a_propuesta_pdf}>{TextResourceHelper.get('label_link')}</a></span>}
                                            {(this.state.deal.link_a_propuesta_pdf === null || this.state.deal.link_a_propuesta_pdf === '') && <span>---</span>}
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {linkOpAlert === '' && TextResourceHelper.get('label_link_to_op')}
                                                {
                                                    linkOpAlert !== '' &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{linkOpAlert}</Tooltip>}>
                                                        <a className="tooltip-alert-trigger">{TextResourceHelper.get('label_link_to_op')}</a>
                                                    </OverlayTrigger>
                                                }
                                            </label>
                                            {this.state.deal.link_orden_de_publicidad_op !== null && this.state.deal.link_orden_de_publicidad_op !== '' && <span><a target="_blank" href={this.state.deal.link_orden_de_publicidad_op}>{TextResourceHelper.get('label_link')}</a></span>}
                                            {(this.state.deal.link_orden_de_publicidad_op === null || this.state.deal.link_orden_de_publicidad_op === '') && <span>---</span>}
                                        </td>
                                        <td className="text-center fit">
                                            <label className="font-weight-bold mb-1 d-block">
                                                {TextResourceHelper.get('label_link_to_report')}
                                            </label>
                                            {this.state.deal.url_reporte !== null && this.state.deal.url_reporte !== '' && <span><a target="_blank" href={this.state.deal.url_reporte}>{TextResourceHelper.get('label_link')}</a></span>}
                                            {(this.state.deal.url_reporte === null || this.state.deal.url_reporte === '') && <span>---</span>}
                                        </td>
                                    </tr>
                                </table>

                                {this.state.deal.items.length > 0 && (this.havePermission("verLineItems") || userData.allRead) &&
                                    <div class="accordion" id={'lineItemsAccordion-' + this.state.deal.deal_id}>
                                        {
                                            this.state.deal.items.map(item => {
                                                if (IsUserOwner == false && !userData.allRead && !userData.allWrite) {
                                                    if (userData.group === SessionConfig.group_ms
                                                        && this.state.deal.status === DealHelper.status_preparation
                                                        && !(item.status === LineItemStatusHelper.preparation || item.status === LineItemStatusHelper.saved || item.status === LineItemStatusHelper.to_implement))
                                                        return;

                                                    if (userData.group === SessionConfig.group_adops
                                                        && this.state.deal.status === DealHelper.status_implementation
                                                        && !(item.status === LineItemStatusHelper.to_implement || item.status === LineItemStatusHelper.require_validation || item.status === LineItemStatusHelper.validated || item.status === LineItemStatusHelper.implemented))
                                                        return;

                                                    if (userData.group === SessionConfig.group_adops
                                                        && this.state.deal.status === DealHelper.status_implementation
                                                        && item.status === LineItemStatusHelper.require_validation
                                                        && item.adops_validator_user_email !== null
                                                        && item.adops_implementer_user_email !== null
                                                        && !(userData.email === item.adops_validator_user_email || userData.email === item.adops_implementer_user_email))
                                                        return;

                                                    if (userData.group === SessionConfig.group_adops
                                                        && this.state.deal.status === DealHelper.status_implementation
                                                        && item.status === LineItemStatusHelper.validated
                                                        && item.adops_implementer_user_email !== null
                                                        && userData.email !== item.adops_implementer_user_email)
                                                        return;
                                                }
                                                if (item.line_item_type == LineItemHelper.awesome_ads_direct)
                                                    return <LineItemAdsDirect lineItem={item} refreshDeal={this.refreshDeal} dealKickBack={this.state.deal.kick_back} mediaPlanOk={this.state.deal.mediaplan_ok} isUserOwner={IsUserOwner} />;
                                                else if (item.line_item_type == LineItemHelper.awesome_ads_deal)
                                                    return <LineItemAdsDeal lineItem={item} refreshDeal={this.refreshDeal} mediaPlanOk={this.state.deal.mediaplan_ok} isUserOwner={IsUserOwner}/>;
                                            })
                                        }

                                    </div>
                                }
                                {this.state.deal.items.length === 0 &&
                                    <div className="text-center mt-2">
                                        <p className="">{TextResourceHelper.get('label_deal_no_line_items')}</p>
                                        {
                                        this.state.deal.status === DealHelper.status_kick_off && 
                                        <div>
                                            {(this.havePermission("importarLineItem") || userData.allWrite)  &&
                                                <button className="btn btn-light btn-sm" type="button" data-toggle="modal" data-target={'#importLiModal-' + this.state.deal.deal_id}>{TextResourceHelper.get('buttonlabel_import_lineitems')}</button>
                                            }
                                            {(this.havePermission("crearNuevoLineItem") || userData.allWrite)  &&
                                                <div className="dropdown d-inline-block ml-4">
                                                    <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id={'dropdownMenuButton-' + this.state.deal.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {TextResourceHelper.get('buttonlabel_add_lineitem')}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby={'dropdownMenuButton-' + this.state.deal.id}>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDirectModal-' + this.state.deal.id}>{TextResourceHelper.get('label_awesome_ads_direct')}</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDealModal-' + this.state.deal.id}>{TextResourceHelper.get('label_awesome_ads_deal')}</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDirectModal-' + this.state.deal.id}>{TextResourceHelper.get('label_ksv')}</a>
                                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target={'#newLiAdsDirectModal-' + this.state.deal.id}>{TextResourceHelper.get('label_gaming_roblox')}</a>
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                        }
                                    </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/****************************************************************************************************/}
                {/*NEW LINE ITEM MODAL - awesome_ads_direct*/}
                {/****************************************************************************************************/}
                <div id={'newLiAdsDirectModal-' + this.state.deal.id} class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content small">
                            <div class="modal-header">
                                <h6 class="modal-title">{TextResourceHelper.get('pagetitle_newlineitem')}</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form id={'newLiAdsDirectForm-' + this.state.deal.id} className="needs-validation" onSubmit={this.saveLineItemAdsDirect} noValidate>
                                <div class="modal-body p-2">
                                    <div class="container p-0">
                                        <div class="form-group row mb-0">
                                            <label class="col-sm-3 col-form-label">
                                                {TextResourceHelper.get('label_mediaplan_name')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_mediaplan_name')}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <div class="col-sm-9 p-1">
                                                <input id={'mediaplanname-' + this.state.deal.id} type="text" readonly="" class="form-control-plaintext form-control-sm d-inline-block" value={this.state.deal.media_plan_name} style={{ width: '90%' }} />
                                                <a onClick={this.copyMediaPlanName} className="cursor-pointer ml-2"><i className="far fa-clone"></i></a>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">
                                                {TextResourceHelper.get('label_lineitem_label')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <div class="col-sm-9 p-1">
                                                <input id={'lineitemlabel-' + this.state.deal.id} type="text" class="form-control form-control-sm border-radius d-inline-block" value={this.state.lineItemLabel} onChange={this.onChangeLineItemLabel} onBlur={this.validateLineItemAdsDirect} style={{ width: '90%' }} required />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm">
                                                <div className="text-center">
                                                    <label className="font-weight-bold">{TextResourceHelper.get('label_investment')}</label>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_purchase_model')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemModeloDeCompraTooltip !== '' ? this.state.lineItemModeloDeCompraTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemModeloDeCompra} onChange={this.onChangeLineItemModeloDeCompra} onBlur={this.validateLineItemAdsDirect} required>
                                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        <option value="CPM">CPM</option>
                                                        <option value="CPV">CPV</option>
                                                        <option value="CPCV">CPCV</option>
                                                    </select>
                                                </div>

                                                <div class="form-row mb-2">
                                                    <div class="col-sm-6">
                                                        <div class="form-group mb-2">
                                                            <label className="w-100">
                                                                {TextResourceHelper.get('label_sell_rate')}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemSellRateTooltip !== '' ? this.state.lineItemSellRateTooltip : ''}</Tooltip>}>
                                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                                </OverlayTrigger>
                                                            </label>
                                                            <input type="number" step=".001" class="form-control form-control-sm border-radius" value={this.state.lineItemSellRate} onChange={this.onChangeLineItemSellRate} onBlur={this.calculateGoal} required />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group mb-2">
                                                            <label className="w-100">
                                                                {TextResourceHelper.get('label_net_rate')}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_net_rate')}</Tooltip>}>
                                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                                </OverlayTrigger>
                                                            </label>
                                                            <input readonly="" type="number" step=".0001" class="form-control form-control-sm border-radius" value={this.state.lineItemNetRate} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_goal_imp_views')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input readonly="" step=".001" type="number" class="form-control form-control-sm border-radius" value={this.state.lineItemGoal} />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_bonificacion_imp_views')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_bonificacion')}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input type="number" step="1" class="form-control form-control-sm border-radius" value={this.state.lineItemBonificacion} onChange={this.onChangeLineItemBonificacion} onBlur={this.calculateGoal} required />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_total_goal')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input readonly="" step=".001" type="number" class="form-control form-control-sm border-radius" value={this.state.lineItemTotalGoal} />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_investment')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemInversionTooltip !== '' ? this.state.lineItemInversionTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input id={"investment-" + this.state.deal.deal_id} type="number" step=".01" class="form-control form-control-sm border-radius" value={this.state.lineItemInversion} onChange={this.onChangeLineItemInversion} onBlur={this.calculateGoal} required />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <div class="form-check">
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_facturacion_mensual')}</Tooltip>}>
                                                            <input class="form-check-input" type="checkbox" value={this.state.lineItemFacturacionMensual} onChange={this.onChangeLineItemFacturacionMensual} checked={this.state.deal.facturacion_mensual} />
                                                        </OverlayTrigger>
                                                        <label class="form-check-label" for="gridCheck">
                                                            {TextResourceHelper.get('label_facturacion_mensual')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm">
                                                <div className="text-center">
                                                    <label className="font-weight-bold">{TextResourceHelper.get('label_targeting')}</label>
                                                </div>

                                                <div class="form-row mb-2">
                                                    <div class="col-sm-6">
                                                        <div class="form-group mb-2">
                                                            <label className="w-100">
                                                                {TextResourceHelper.get('label_start_date')}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFechaInicioTooltip !== '' ? this.state.lineItemFechaInicioTooltip : ''}</Tooltip>}>
                                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                                </OverlayTrigger>
                                                            </label>
                                                            <input id={"fechainicio-" + this.state.deal.deal_id} type="date" className="form-control form-control-sm border-radius" placeholder="" value={this.state.lineItemFechaInicio} onChange={this.onChangeLineItemFechaInicio} onBlur={this.validateLineItemAdsDirect} required />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group mb-2">
                                                            <label className="w-100">
                                                                {TextResourceHelper.get('label_end_date')}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFechaFinTooltip !== '' ? this.state.lineItemFechaFinTooltip : ''}</Tooltip>}>
                                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                                </OverlayTrigger>
                                                            </label>
                                                            <input id={"fechafin-" + this.state.deal.deal_id} type="date" className="form-control form-control-sm border-radius" placeholder="" value={this.state.lineItemFechaFin} onChange={this.onChangeLineItemFechaFin} onBlur={this.validateLineItemAdsDirect} required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_country')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCountryTooltip !== '' ? this.state.lineItemCountryTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <BootstrapSelect
                                                        showSearch={true}
                                                        isClearable={true}
                                                        showTick={true}
                                                        showCountOn={1}
                                                        placeholder={TextResourceHelper.get('label_select')}
                                                        className="form-control form-control-sm border-radius border-1"
                                                        selectStyle="btn-default selectcountry"
                                                        options={this.state.lineItemCountryOptions}
                                                        onChange={this.onChangeLineItemCountry} required />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_devices')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemDevicesTooltip !== '' ? this.state.lineItemDevicesTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <BootstrapSelect
                                                        isMultiSelect={true}
                                                        showTick={true}
                                                        showCountOn={1}
                                                        placeholder={TextResourceHelper.get('label_select')}
                                                        className="form-control form-control-sm border-radius border-1"
                                                        selectStyle="btn-default selectdevices"
                                                        options={this.state.lineItemDevicesOptions}
                                                        onChange={this.onChangeLineItemDevices} required />
                                                </div>

                                                <div class="form-row mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_gender')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemGenderTooltip !== '' ? this.state.lineItemGenderTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <button type="button" className={'btn btn-sm w-100 btnGender ' + (this.state.lineItemGenderBoys ? 'btn-primary' : 'btn-outline-primary')} onClick={this.onChangeLineItemGenderBoys} onBlur={this.validateLineItemAdsDirect}>{TextResourceHelper.get('label_gender_boys')}</button>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <button type="button" className={'btn btn-sm w-100 btnGender ' + (this.state.lineItemGenderGirls ? 'btn-primary' : 'btn-outline-primary')} onClick={this.onChangeLineItemGenderGirls} onBlur={this.validateLineItemAdsDirect}>{TextResourceHelper.get('label_gender_girls')}</button>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_age')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <InputRange
                                                        minValue={0}
                                                        maxValue={17}
                                                        formatLabel={value => `${value} ` + TextResourceHelper.get('label_years').toLowerCase()}
                                                        value={this.state.lineItemAudiences}
                                                        onChange={this.onChangeLineItemAudiences} />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_categories')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCategoriesTooltip !== '' ? this.state.lineItemCategoriesTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <BootstrapSelect
                                                        isMultiSelect={true}
                                                        showTick={true}
                                                        showCountOn={1}
                                                        placeholder={TextResourceHelper.get('label_select')}
                                                        className="form-control form-control-sm border-radius border-1"
                                                        selectStyle="btn-default selectcategories"
                                                        options={this.state.lineItemCategoriesOptions}
                                                        onChange={this.onChangeLineItemCategories} required />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_frequency')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFrecuencyTooltip !== '' ? this.state.lineItemFrecuencyTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemFrecuency} onChange={this.onChangeLineItemFrecuency} onBlur={this.validateLineItemAdsDirect} required>
                                                        <option value="" value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_bl_url')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemBlUrl} onChange={this.onChangeLineItemBlUrl} />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_wl_url')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemWlUrl} onChange={this.onChangeLineItemWlUrl} />
                                                </div>

                                            </div>

                                            <div class="col-sm">
                                                <div className="text-center">
                                                    <label className="font-weight-bold">{TextResourceHelper.get('label_creativities')}</label>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_format')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemFormatTooltip !== '' ? this.state.lineItemFormatTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemFormat} onChange={this.onChangeLineItemFormat} onBlur={this.validateLineItemAdsDirect} required>
                                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        {this.state.lineItemFormatOptions.map(format =>
                                                            <option key={format.formato} value={format.formato}>{format.formato}</option>
                                                        )}
                                                    </select>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_provider')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCreativeProviderTooltip !== '' ? this.state.lineItemCreativeProviderTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemCreativeProvider} onChange={this.onChangeLineItemCreativeProvider} onBlur={this.validateLineItemAdsDirect} required>
                                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        <option value="Kidscorp">Kidscorp</option>
                                                        <option value="Anunciante">Anunciante</option>
                                                    </select>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_moat')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemAuditoriaMoatTooltip !== '' ? this.state.lineItemAuditoriaMoatTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemAuditoriaMoat} onChange={this.onChangeLineItemAuditoriaMoat} onBlur={this.validateLineItemAdsDirect} required>
                                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        <option value="10% (Standard)">10% (Standard)</option>
                                                        <option value="100%">100%</option>
                                                        <option value="No aplica">No aplica</option>
                                                    </select>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_link_to_materials')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemLinkAMaterialesTooltip !== '' ? this.state.lineItemLinkAMaterialesTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemLinkAMateriales} onChange={this.onChangeLineItemLinkAMateriales} onBlur={this.validateLineItemAdsDirect} required={userData.group === SessionConfig.group_ms} />
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_tracking')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemTrackingTooltip !== '' ? this.state.lineItemTrackingTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemTracking} onChange={this.onChangeLineItemTracking} onBlur={this.validateLineItemAdsDirect} required={userData.group === SessionConfig.group_ms}>
                                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        <option value="No posee">No posee</option>
                                                        <option value="Via UTM">Via UTM</option>
                                                        <option value="Via Tags">Via Tags</option>
                                                        <option value="Via Trackers">Via Trackers</option>
                                                    </select>
                                                </div>

                                                <div class="form-group mb-2">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_destination_url')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemUrlDestinoTooltip !== '' ? this.state.lineItemUrlDestinoTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <input type="text" class="form-control form-control-sm border-radius" value={this.state.lineItemUrlDestino} onChange={this.onChangeLineItemUrlDestino} onBlur={this.validateLineItemAdsDirect} required={userData.group === SessionConfig.group_ms} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*CLOSE CONFIRM NEW LINE ITEM MODAL - awesome_ads_direct*/}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'closeConfirmAdsDirectModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                {TextResourceHelper.get('label_confirm_save_changes')}
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={this.closeFormModals} class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                <button type="button" onClick={this.forceSaveLineItemAdsDirect} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*MODAL CHANGE STATUS DEAL - Cosa de locos*/}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'changeStatusDealModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                ¿Confirma el cambio de estado al deal {this.state.deal.id}?
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={this.closeFormModals} class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                <button type="button" onClick={this.onClickChangeStatusAccept} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*NEW LINE ITEM MODAL - awesome_ads_deal*/}
                {/****************************************************************************************************/}
                <div id={'newLiAdsDealModal-' + this.state.deal.id} class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content small">
                            <div class="modal-header">
                                <h6 class="modal-title">{TextResourceHelper.get('pagetitle_newlineitem')}</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form id={'newLiAdsDealForm-' + this.state.deal.id} className="needs-validation" onSubmit={this.saveLineItemAdsDeal} noValidate>
                                <div class="modal-body p-2">
                                    <div class="container p-0">
                                        <div class="form-group row mb-0">
                                            <label class="col-sm-3 col-form-label">
                                                {TextResourceHelper.get('label_mediaplan_name')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip>{TextResourceHelper.get('label_tooltip_mediaplan_name')}</Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <div class="col-sm-9 p-1">
                                                <input id={'mediaplanname-' + this.state.deal.id} type="text" readonly="" class="form-control-plaintext form-control-sm d-inline-block" value={this.state.deal.media_plan_name} style={{ width: '90%' }} />
                                                <a onClick={this.copyMediaPlanName} className="cursor-pointer ml-2"><i className="far fa-clone"></i></a>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">
                                                {TextResourceHelper.get('label_lineitem_label')}
                                                <OverlayTrigger placement="top" overlay={<Tooltip></Tooltip>}>
                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                </OverlayTrigger>
                                            </label>
                                            <div class="col-sm-9 p-1">
                                                <input id={'lineitemlabel-' + this.state.deal.id} type="text" class="form-control form-control-sm border-radius d-inline-block" value={this.state.lineItemLabel} onChange={this.onChangeLineItemLabel} style={{ width: '90%' }} onBlur={this.validateLineItemAdsDeal} required />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm">
                                                <div className="text-center mb-2">
                                                    <label className="font-weight-bold">{TextResourceHelper.get('label_programatic_deal')}</label>
                                                </div>

                                                <div class="form-row mb-4">
                                                    <div class="col-sm-6">
                                                        <div class="form-group mb-2">
                                                            <label className="w-100">
                                                                {TextResourceHelper.get('label_sell_rate')}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemSellRateTooltip !== '' ? this.state.lineItemSellRateTooltip : ''}</Tooltip>}>
                                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                                </OverlayTrigger>
                                                            </label>
                                                            <input type="number" step=".001" class="form-control form-control-sm border-radius" value={this.state.lineItemSellRate} onChange={this.onChangeLineItemSellRate} onBlur={this.validateLineItemAdsDeal} required />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group mb-2">
                                                            <label className="w-100">
                                                                {TextResourceHelper.get('label_investment')}
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemInversionTooltip !== '' ? this.state.lineItemInversionTooltip : ''}</Tooltip>}>
                                                                    <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                                </OverlayTrigger>
                                                            </label>
                                                            <input type="number" step=".01" class="form-control form-control-sm border-radius" value={this.state.lineItemInversion} onChange={this.onChangeLineItemInversion} onBlur={this.validateLineItemAdsDeal} required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group mb-4">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_provider')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCreativeProviderTooltip !== '' ? this.state.lineItemCreativeProviderTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <select className="form-control form-control-sm border-radius" value={this.state.lineItemCreativeProvider} onChange={this.onChangeLineItemCreativeProvider} onBlur={this.validateLineItemAdsDeal} required>
                                                        <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                                        <option value="Kidscorp">Kidscorp</option>
                                                        <option value="Anunciante">Anunciante</option>
                                                    </select>
                                                </div>

                                                <div class="form-group mb-4">
                                                    <label className="w-100">
                                                        {TextResourceHelper.get('label_country')}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.lineItemCountryTooltip !== '' ? this.state.lineItemCountryTooltip : ''}</Tooltip>}>
                                                            <span class="float-right"><i className="fa fa-info-circle"></i></span>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <BootstrapSelect
                                                        showSearch={true}
                                                        showTick={true}
                                                        showCountOn={1}
                                                        placeholder={TextResourceHelper.get('label_select')}
                                                        className="form-control form-control-sm border-radius border-1"
                                                        selectStyle="btn-default selectcountry"
                                                        options={this.state.lineItemCountryOptions}
                                                        onChange={this.onChangeLineItemCountry} required />
                                                </div>

                                                <div class="form-group mb-4">
                                                    <button type="submit" class="btn btn-primary w-100">{TextResourceHelper.get('buttonlabel_save')}</button>
                                                </div>

                                            </div>

                                            <div class="col-sm">
                                            </div>

                                            <div class="col-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*CLOSE CONFIRM NEW LINE ITEM MODAL - awesome_ads_deal*/}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'closeConfirmAdsDealModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                {TextResourceHelper.get('label_confirm_save_changes')}
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={this.closeFormModals} class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                <button type="button" onClick={this.forceSaveLineItemAdsDeal} class="btn btn-primary">{TextResourceHelper.get('buttonlabel_save')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*PAUSE LINE ITEMS MODAL */}
                {/****************************************************************************************************/}
                <div id={'pauseLiModal-' + this.state.deal.id} class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content small">
                            <div class="modal-header">
                                <h6 class="modal-title">{TextResourceHelper.get('pagetitle_select_lineitems_pause')}</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body p-2">
                                <div class="container p-0">
                                    <div class="form-check mb-2">
                                        <input id={'pauseall-' + this.state.deal.deal_id} class="form-check-input" type="checkbox" value="pauseall" onChange={this.togglePauseSelectAll} />
                                        <label htmlFor={'pauseall-' + this.state.deal.deal_id} class="form-check-label">{TextResourceHelper.get('label_all')}</label>
                                    </div>
                                    <div class="table-responsive">
                                        <table className="table campaigns-table mb-0">
                                            <tbody>
                                                <tr>
                                                    <th className="fit">{TextResourceHelper.get('label_hs_deal_id')}</th>
                                                    <th className="data fit">{this.state.deal.deal_id}</th>
                                                    <th className="fit">{TextResourceHelper.get('label_advertiser')}</th>
                                                    <th className="data fit">{this.state.deal.nombre_anunciante == null ? '---' : this.state.deal.nombre_anunciante}</th>
                                                    <th className="fit">{TextResourceHelper.get('label_campaign_name')}</th>
                                                    <th className="data fit">{this.state.deal.campaign == null ? '---' : this.state.deal.campaign}</th>
                                                    <th className="fit">{TextResourceHelper.get('label_mediaplan_id')}</th>
                                                    <th className="data">{this.state.deal.id}</th>
                                                </tr>
                                                <tr className={statusCssClass}>
                                                    <td colSpan="8" style={{ width: '100%' }}>
                                                        {this.state.deal.media_plan_name}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.deal.items.length > 0 && (this.havePermission("verLineItems") || userData.allRead || userData.allWrite) &&
                                            <div class="accordion" id={'lineItemsAccordion-' + this.state.deal.deal_id}>
                                                {
                                                    this.state.deal.items.map(item => {
                                                        if (this.state.deal.status === DealHelper.status_implementation && item.status !== LineItemStatusHelper.to_implement)
                                                            return;

                                                        if (item.line_item_type == LineItemHelper.awesome_ads_direct)
                                                            return <LineItemAdsDirect lineItem={item} refreshDeal={this.refreshDeal} selectable={true} setSelected={this.setPauseLineItemSelected} selectAll={this.state.pauseAllLineItems} />;
                                                        else if (item.line_item_type == LineItemHelper.awesome_ads_deal)
                                                            return <LineItemAdsDeal lineItem={item} refreshDeal={this.refreshDeal} selectable={true} setSelected={this.setPauseLineItemSelected} selectAll={this.state.pauseAllLineItems} />;
                                                    })
                                                }
                                            </div>
                                        }
                                        <div className="text-right mt-2">
                                            <button type="button" data-toggle="modal" data-target={'#pauseLiConfirmModal-' + this.state.deal.id} className="btn btn-danger btn-xl" disabled={this.state.pauseLineItemIds.length === 0}>{TextResourceHelper.get('buttonlabel_pause')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*PAUSE LINE ITEMS CONFIRM MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'pauseLiConfirmModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                {TextResourceHelper.get('label_confirm_pause_lineitem')}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                <button type="button" data-toggle="modal" data-target={'#pauseLiReasonModal-' + this.state.deal.id} class="btn btn-danger">{TextResourceHelper.get('buttonlabel_pause')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*PAUSE LINE ITEMS REASON MODAL */}
                {/****************************************************************************************************/}
                <div class="modal fade" id={'pauseLiReasonModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form id={'pauseLiReasonForm-' + this.state.deal.id} className="needs-validation" onSubmit={this.pauseLineItems} noValidate>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label className="w-100">{TextResourceHelper.get('label_select_lineitems_pause_reason')}</label>
                                        <select className="form-control form-control-sm border-radius" value={this.state.lineItemPauseReason} onChange={this.onChangeLineItemPauseReason} required>
                                            <option value="" selected>{TextResourceHelper.get('label_select')}</option>
                                            <option value="budget_change">{TextResourceHelper.get('lineitem_pausereason_budget_change')}</option>
                                            <option value="stock_problems">{TextResourceHelper.get('lineitem_pausereason_stock_problems')}</option>
                                            <option value="bad_campaign_results">{TextResourceHelper.get('lineitem_pausereason_bad_campaign_results')}</option>
                                            <option value="budget_redistribution">{TextResourceHelper.get('lineitem_pausereason_budget_redistribution')}</option>
                                            <option value="client_order">{TextResourceHelper.get('lineitem_pausereason_client_order')}</option>
                                            <option value="other">{TextResourceHelper.get('lineitem_pausereason_other')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button type="submit" class="btn btn-danger">{TextResourceHelper.get('buttonlabel_pause')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/****************************************************************************************************/}
                {/*IMPORT LINE ITEMS MODAL */}
                {/****************************************************************************************************/}
                <ImportLineItemsModal refreshDeal={this.refreshDeal} targetDealId={this.state.deal.deal_id} targetDealAdvertiser={this.state.deal.nombre_anunciante} />

                {/****************************************************************************************************/}
                {/*PREPARE MEDIA PLAN CONFIRM MODAL */}
                {/****************************************************************************************************/}
                {(userData.group === SessionConfig.group_sales || userData.group === SessionConfig.group_ms)  && canSendToPreparation &&
                    <div class="modal fade" id={'prepareConfirmModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    {TextResourceHelper.get('label_confirm_prepare_mediaplan')}
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">{TextResourceHelper.get('buttonlabel_close')}</button>
                                    <button onClick={this.sendToPreparation} type="button" class="btn btn-success">{TextResourceHelper.get('buttonlabel_confirm')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/****************************************************************************************************/}
                {/*PREPARE MEDIA PLAN STATUS MODAL */}
                {/****************************************************************************************************/}
                {userData.group === SessionConfig.group_sales &&
                    <div class="modal fade" id={'prepareStatusModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h6 class="modal-title"></h6>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body text-center p-5 mt-4 mb-4">
                                    <p>{TextResourceHelper.get('label_mediaplan_prepare_status')}</p>
                                    <Link class="btn btn-secondary" to={"/deal-detail/" + this.state.deal.deal_id}>{TextResourceHelper.get('buttonlabel_view_mediaplan')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/****************************************************************************************************/}
                {/*PREPARE MEDIA PLAN STATUS MODAL */}
                {/****************************************************************************************************/}
                {userData.group === SessionConfig.group_ms &&
                    <div class="modal fade" id={'implementStatusModal-' + this.state.deal.id} tabindex="-1" role="dialog" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h6 class="modal-title"></h6>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body text-center p-5 mt-4 mb-4">
                                    <p>{TextResourceHelper.get('label_mediaplan_implement_status')}</p>
                                    <Link class="btn btn-secondary" to={"/deal-detail/" + this.state.deal.deal_id}>{TextResourceHelper.get('buttonlabel_view_mediaplan')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default MediaPlan;