import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../Helpers'
import iconCalendar from '../../icn_calendar.png';
import SessionHelper from '../helpers/SessionHelper';
import TextResourceHelper from '../helpers/TextResourceHelper';
import axios from 'axios';
import $ from 'jquery';
import NotificationHelper from '../helpers/NotificationHelper';

export class NotificationsList extends Component {
    static displayName = NotificationsList.name;

    constructor(props) {
        super(props);

        this.onChangeFilterDateFrom = this.onChangeFilterDateFrom.bind(this);
        this.onChangeFilterDateTo = this.onChangeFilterDateTo.bind(this);

        // default date from/to
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        this.state = {
            items: [],
            filterDateFrom: firstDay.toISOString().split('T')[0],
            filterDateTo: lastDay.toISOString().split('T')[0],
            loading: true
        };
    }

    componentDidMount() {
        Helpers.setNavActive("#nav-notifications");
        Helpers.setMainTitle(TextResourceHelper.get('pagetitle_notifications'));
        this.saveNotificationReads();
        this.populateData();
    }

    onChangeFilterDateFrom(e) {
        this.setState({ filterDateFrom: e.target.value }, () => {
            this.populateData();
        });
    }

    onChangeFilterDateTo(e) {
        this.setState({ filterDateTo: e.target.value }, () => {
            this.populateData();
        });
    }

  render () {
    return (
        <div>
            <div className="container mt-4">
                <div className="row">
                    <form className="form-inline mt-4">
                        <div className="input-group mb-2">
                            <label className="col-form-label mr-4">{TextResourceHelper.get('label_notification_center')}</label>
                            <label>{TextResourceHelper.get('label_from')}</label>
                            <input type="date" className="form-control-sm border-radius ml-2 mr-2" placeholder="" defaultValue={this.state.filterDateFrom} onChange={this.onChangeFilterDateFrom} />
                            <label>{TextResourceHelper.get('label_to')}</label>
                            <input type="date" className="form-control-sm border-radius ml-2" placeholder="" defaultValue={this.state.filterDateTo} onChange={this.onChangeFilterDateTo} />
                        </div>
                    </form>
                </div>

                <div className="table-responsive mt-4">
                    <table className="table notifications-table">
                        <tbody>
                            {
                                this.state.items.length > 0 && this.state.items.map(item =>
                                    <tr key={item.id}>
                                        <td style={{ width: '70%' }}>
                                            {item.text}
                                            {item.event_type === NotificationHelper.sales_deal_sent_ot && <a className="ml-1" target="_blank" href={window.config.REACT_APP_API_URL + '/deal/' + item.deal_id + '/download/' + TextResourceHelper.getUiLanguage()}>{TextResourceHelper.get('label_download')}</a>}
                                        </td>
                                        <td style={{ width: '20%' }}>{TextResourceHelper.get('label_deal_id')} {item.deal_id}</td>
                                        <td className="text-right" style={{ width: '10%' }}><Link to={"/deal-detail/" + item.deal_id}><div className="icon plus-circle"></div></Link></td>
                                    </tr>
                                )
                            }
                            {
                                this.state.items.length == 0 && <p className="text-center">{TextResourceHelper.get('label_no_results_found')}</p>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
    );
    }

    saveNotificationReads() {
        let userData = SessionHelper.getUserData();
        axios.post(window.config.REACT_APP_API_URL + '/notification/' + userData.id + '/reads/save')
            .then((response) => {
                $("#notificationBadge").remove();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    populateData() {
        let userData = SessionHelper.getUserData();
        let url = window.config.REACT_APP_API_URL + '/notification/' + userData.id + '/get';

        if (this.state.filterDateFrom !== '')
            url += '?dateFrom=' + this.state.filterDateFrom;

        if (this.state.filterDateTo !== '')
            url += '&dateTo=' + this.state.filterDateTo;

        axios.get(url)
            .then(response => {
                const data = response.data.data;
                this.setState({ items: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
