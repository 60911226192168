import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import logo from '../../kite_logo.png';
import Helpers from '../../Helpers';
import SessionHelper from '../helpers/SessionHelper';
import TextResourceHelper from '../helpers/TextResourceHelper';
import $ from 'jquery';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            message: undefined,
            username: '',
            password: '',
            loading: false
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    componentDidMount() {
        this.setState({ loading: true });

        $('.input100').each(function () {
            $(this).on('blur', function () {
                if ($(this).val().trim() != "") {
                    $(this).addClass('has-val');
                }
                else {
                    $(this).removeClass('has-val');
                }
            })
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        this.login(this.getReturnUrl());
    }

    render() {
        return (
            <div id="layoutAuthentication">
                <div class="limiter">
                    <div class="container-login100">
                        <div class="wrap-login100">
                            <form onSubmit={this.onSubmit} class="login100-form validate-form needs-validation">
                                <div className="text-center mb-4">
                                    <img src={logo} style={{ width: '60%' }} />
                                </div>

                                <div class="wrap-input100 validate-input">
                                    <input class="input100" type="text" name="username" onChange={this.onChangeUsername} required />
                                    <span class="focus-input100" data-placeholder={TextResourceHelper.get('label_username') }></span>
					            </div>

                                <div class="wrap-input100 validate-input">
                                    <span class="btn-show-pass">
                                        <i class="zmdi zmdi-eye"></i>
                                    </span>
                                    <input class="input100" type="password" name="password" onChange={this.onChangePassword} />
                                    <span class="focus-input100" data-placeholder={TextResourceHelper.get('label_password')}></span>
					            </div>

                                <div class="container-login100-form-btn">
                                    <div class="wrap-login100-form-btn">
                                        <div class="login100-form-bgbtn"></div>
                                        <button class="login100-form-btn">{TextResourceHelper.get('buttonlabel_login')}</button>
                                    </div>
                                </div>

                                <div class="text-center mt-5">
                                    <a className="small" href="/forgot-password">{TextResourceHelper.get('label_forgot_password')}</a>
                                </div>
				            </form>
                        </div>
		            </div>
                </div>
            </div>
        );
    }

    async login(returnUrl) {
        const state = { returnUrl };
        const result = await authService.signIn(this.state.username, this.state.password);

        if (result.success === true) {
            SessionHelper.doLogin(result.data);
            TextResourceHelper.setUiLanguage(result.data.language_key);

            await this.navigateToReturnUrl(returnUrl);
        } else {
            let msg = TextResourceHelper.get('errormessage_unknown_error');

            if (result.error_code == window.errorCodes.invalid_login) {
                msg = TextResourceHelper.get('errormessage_invalid_login');
            } else if (result.error_code == window.errorCodes.locked_user) {
                msg = TextResourceHelper.get('errormessage_locked_user');
            }

            Helpers.notifyError(TextResourceHelper.get('label_error'), msg);
        }
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/media-planner`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}

export default Login;