import React, { Component } from 'react';
import { Route } from 'react-router';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './custom.css'
import { SalesDealSent } from './components/deals/SalesDealSent';
import { NotificationsList } from './components/notifications/NotificationsList';
import { MsDealSent } from './components/deals/MsDealSent';
import { UsersList } from './components/users/UsersList';
import { UserCreate } from './components/users/UserCreate';
import { UserEdit } from './components/users/UserEdit';
import SessionConfig from './components/session/SessionConfig'
import { TextResourcesList } from './components/textresources/TextResourcesList';
import { TextResourceEdit } from './components/textresources/TextResourceEdit';
import { DealDetail } from './components/deals/DealDetail';
import { MediaPlanner } from './components/deals/MediaPlanner';
import ForgotPassword from './components/account/ForgotPassword';
import TextResourceHelper from './components/helpers/TextResourceHelper'
import PasswordRecovery from './components/account/PasswordRecovery';
import Helpers from '../src/Helpers';

export default class App extends Component {
  static displayName = App.name;

    async componentDidMount() {
        // load text resources
        await TextResourceHelper.loadTextResources();
    }

    render() {
        if (window.location.href.indexOf('forgot-password') !== -1)
            return <div><ReactNotification /><Route exact path='/forgot-password' component={ForgotPassword} /></div>;

        if (window.location.href.indexOf('passwordrecovery') !== -1)
            return <div><ReactNotification /><Route exact path='/passwordrecovery/:token' component={PasswordRecovery} /></div>;

        return (
          <div>
              <ReactNotification />
              <Layout>
                  <AuthorizeRoute exact path='/' component={Home} roles={[]} groups={[]} />
                  <AuthorizeRoute path='/media-planner' component={MediaPlanner} roles={[]} groups={[]} />
                  <AuthorizeRoute path='/deal-senttoms' component={SalesDealSent} roles={[]} groups={[SessionConfig.group_sales]} />
                  <AuthorizeRoute path='/deal-senttoot' component={MsDealSent} roles={[]} groups={[SessionConfig.group_ms]} />
                  <AuthorizeRoute path='/notifications' component={NotificationsList} roles={[]} groups={[]} />
                  <AuthorizeRoute path='/users' component={UsersList} roles={[SessionConfig.role_superuser]} groups={[]} />
                  <AuthorizeRoute path='/user-create' component={UserCreate} roles={[SessionConfig.role_superuser]} groups={[]} />
                  <AuthorizeRoute path='/user-edit/:id' component={UserEdit} roles={[SessionConfig.role_superuser]} groups={[]} />
                  <AuthorizeRoute path='/textresources' component={TextResourcesList} roles={[SessionConfig.role_superuser]} groups={[]} />
                  <AuthorizeRoute path='/textresource-edit/:id' component={TextResourceEdit} roles={[SessionConfig.role_superuser]} groups={[]} />
                  <AuthorizeRoute path='/deal-detail/:id' component={DealDetail} roles={[]} groups={[]} />
                <AuthorizeRoute path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
              </Layout>
            </div>
    );
  }
}
